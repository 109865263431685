import React from "react";

const UsersComponent = (props) => (
  <section className="tables">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-close">
              <div className="dropdown">
                <button
                  type="button"
                  id="closeCard4"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  aria-labelledby="closeCard4"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <a href="#profile" className="dropdown-item remove">
                    <i className="fa fa-times"></i>Close
                  </a>
                  <a href="#profile" className="dropdown-item edit">
                    <i className="fa fa-gear"></i>Edit
                  </a>
                </div>
              </div>
            </div>
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Customers</h3>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Customer Name</th>
                      <th>Phone Number</th>
                      <th>Mail</th>
                      <th>Active From</th>
                      <th>Last Ordered</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data
                      ? props.data.map((p, index) => (
                          <tr key={index}>
                            <th>{p.name}</th>
                            <td>{p.phone}</td>
                            <td>{p.mail}</td>
                            {/* <td>&nbsp;</td> */}
                            <td>{p.created_date.split(" ")[0]}</td>
                            <td>{p.created_date.split(" ")[0]}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
                <div className="input-group-prepend">
                  Page:<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {/* {props.data.map((p, index) => (
                    <span
                      className="input-group-text"
                      onClick={() => props.next(index)}
                    >
                      {index}
                    </span>
                  ))} */}
                  <span
                    className="input-group-text"
                    onClick={() => props.next(0)}
                  >
                    1
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(10)}
                  >
                    2
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(20)}
                  >
                    3
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(30)}
                  >
                    4
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(40)}
                  >
                    5
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(50)}
                  >
                    6
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(60)}
                  >
                    7
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(70)}
                  >
                    8
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(80)}
                  >
                    9
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(90)}
                  >
                    10
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default UsersComponent;
