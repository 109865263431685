import React from "react";
import HeaderComponent from "./HeaderComponent";

class HeaderContainer extends React.Component {
  constructor(props) {
    super();
  }
  logout = () => {
    localStorage.removeItem("access");
    this.props.expireAccess();
    localStorage.removeItem("clientAccess");
  };

  render() {
    return (
      <HeaderComponent
        logout={this.logout}
        nameOfStore={this.props.nameOfStore}
      />
    );
  }
}
export default HeaderContainer;
