import React from "react";
import Header from "./Components/Header";
import Login from "./Components/Login";
import SuperAdminDash from "./Components/SuperAdminDash";
import AdminDash from "./Components/AdminDash";
import ShopDash from "./Components/ShopDash";
class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      freshAccess: false,
      nameOfStore: "",
    };
  }
  freshAccess = (e) => {
    this.setState({
      freshAccess: true,
    });
  };
  expireAccess = () => {
    this.setState({
      freshAccess: false,
    });
  };
  nameOfStore = (nameOfStore) => {
    this.setState({ nameOfStore });
  };
  
  render() {
    const userCheck = window.localStorage.getItem("access");
    let storeDet = JSON.parse(userCheck);
    let storeUserRole;
    if (storeDet !== null) {
      storeUserRole = storeDet.role;
    } else {
      storeUserRole = "";
    }

    return (
      <div>
        {userCheck !== null || this.state.freshAccess === true ? (
          <div className="page">
            <Header
              expireAccess={this.expireAccess}
              nameOfStore={this.state.nameOfStore}
            />
            {storeUserRole === "SUPER_ADMIN" ? (
              <SuperAdminDash nameOfStore={this.nameOfStore} />
            ) : null}
            {storeUserRole === "ADMIN" ? <AdminDash /> : null}
            {storeUserRole === "SHOP" ? <ShopDash /> : null}
          </div>
        ) : (
          <Login freshAccess={this.freshAccess} />
        )}
      </div>
    );
  }
}
export default App;
