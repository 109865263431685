import React from "react";

const DeliveryComponent = (props) => (
  <section className="updates no-padding-top">
    <div className="container-fluid">
      <br />
      <div className="row">
        <div className="col-lg-12">
          <div className="daily-feeds card">
            <div className="card-close">
              <div className="dropdown">
                <button
                  type="button"
                  id="closeCard7"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  aria-labelledby="closeCard7"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <a href="#profile" className="dropdown-item remove">
                    <i className="fa fa-times"></i>Close
                  </a>
                  <a href="#profile" className="dropdown-item edit">
                    <i className="fa fa-gear"></i>Edit
                  </a>
                </div>
              </div>
            </div>
            <div className="card-header">
              <h3 className="h4">Delivery Boys</h3>
            </div>
            {props.data
              ? props.data.map((p, index) => (
                  <div key={index} className="card-body no-padding">
                    <div className="item">
                      <div className="feed d-flex justify-content-between">
                        <div className="feed-body d-flex justify-content-between">
                          <a href="#profile" className="feed-profile">
                            <img
                              src="img/avatar-5.jpg"
                              alt="person"
                              className="img-fluid rounded-circle"
                            />
                          </a>
                          <div className="content">
                            <h5>{p.name}</h5>
                            <span>{p.mail}</span>
                            <div className="full-date">
                              <small>{p.phone}</small>
                            </div>
                          </div>
                        </div>
                        <div className="date text-right">
                          <small>{p.status}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DeliveryComponent;
