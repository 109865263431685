import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../Card/Card";
// import { thArray, tdArray } fsrom "variables/Variables.jsx";
// import Button from "components/CustomButton/CustomButton.jsx";
import {
  //  HOST,
  // CATEGORY_LIST,
  // MENU_LIST,
  OFFERS_LIST,
  NEW_OFFER,
} from "../../Network/ApiPaths";
import Uri from "../../Network/Uri";

import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";

// const offerError = (offerFeildName) => {
//   if (
//     offerFeildName === null ||
//     offerFeildName === "" ||
//     offerFeildName === undefined
//   ) {
//     return true;
//   } else {
//     return false;
//   }
// };
let clientHost;
let storeFresh;
let storeIdValue;
// import getResponse from "../../Network/GetDataResponse";
// import postDataResponse from "../../Network/PostDataResponse";
// import Modal from "react-modal";
// import Zoom from "react-reveal/Zoom";
// const userCheck = window.localStorage.getItem("access");
// let storeDet = JSON.parse(userCheck);
// let storeIdValue;
// if (storeDet !== null) {
//   storeIdValue = storeDet.storeId;
// } else {
   storeIdValue = "";
// }
class Offers extends Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      newOffer: false,
      offers: [],
      // orders: [],
      // onOrder: false,
      // offer_type: "",
      // catsSelected: [],
      // itemsSelected: [],
      title: "",
      couponCode: "",
      description: "",
      type: "",
      limit: "",
      limitCount: "",
      cashBack: "",
      percentageValue: "",
      percentage: "",
      duration: "",
      food: "",
      menu: "",
      measure: "",
      maxPrice: "",
      date: "",
      sDate: "",
      eDate: "",
      newOfferName: "",
      newOfferCouponCode: "",
      newOfferDescription: "",
      newOfferStartDate: "",
      newOfferEndDate: "",
      newOfferDuration: "",
      newOfferType: "",
      newOfferLimit: "",
      newOfferLimitCount: "",
      newOfferCashBack: "",
      newOfferPercentageValue: "",
      deletedOfferId: "",
      offerDefaultDetails: "",
      // categories: "",
      // getItems: "",
      // items: "",
      newOfferNameError: false,
      newOfferCouponCodeError: false,
      newOfferDescError: false,
      newOfferDurationError: false,
      newOfferTypeError: false,
      newOfferLimitError: false,
      offerDeleted: false,
    };
  }
  onFoodChange = (e) => {
    this.setState({
      food: e.target.value,
    });
  };
  onMenuChange = (e) => {
    this.setState({
      menu: e.target.value,
    });
  };
  onMeasureChange = (e) => {
    this.setState({
      measure: e.target.value,
    });
  };
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: false,
    });
  };
  // onCat = (e) => {
  //   e.preventDefault();
  //   var apiPath = new Uri(clientHost +CATEGORY_LIST).addQueryParam("storeid", storeIdValue);
  //   getResponseClient(
  //     apiPath,
  //     storeFresh.key,
  // (onSuccessResponse) => {
  //       this.setState({
  //         categories: onSuccessResponse.result,
  //       });
  //     },
  //     (onError) => {
  //       alert(onError.message);
  //     }
  //   );
  // };
  // onItem = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     items: "required",
  //   });
  // };
  // getItems = (e) => {
  //   this.setState({
  //     getItems: [],
  //   });
  //   // e.preventDefault();
  //   var apiPath = new Uri(clientHost +MENU_LIST).addQueryParam("category_id", e);
  //   getResponseClient(
  //     apiPath,
  //     storeFresh.key,
  // (onSuccessResponse) => {
  //       this.setState({
  //         getItems: onSuccessResponse.result,
  //       });
  //     },
  //     (onError) => {
  //       alert(onError.message);
  //     }
  //   );
  // };
  onOfferTriggerDelete = (e) => {
    this.setState({ deletedOfferId: e });
  };
  onOfferDelete = () => {
    this.setState({
      offerDeleted: true,
    });
    var apiPath = new Uri(
      clientHost + `Offer/deleteOffer?id=${this.state.deletedOfferId}`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getOffers();
      },
      (onError) => {
        // console.log(onError.message);
      }
    );
  };
  onOfferTriggerEdit = (e) => {
    // alert("offerTriggered");
    this.setState({
      offerDefaultDetails: e,
    });
  };
  onUpdateOffer = (e) => {
    e.preventDefault();

    let updatedTitle;
    let updatedcouponCode;
    let updatedDescription;
    let updatedDuration;
    let updatedSDate;
    let updatedEDate;
    let updatedOfferType;
    let updatedPercentageValue;
    let updatedLimit;
    let updatedLimitCount;

    if (this.state.title === "") {
      updatedTitle = this.state.offerDefaultDetails.title;
    } else {
      updatedTitle = this.state.title;
    }
    if (this.state.couponCode === "") {
      updatedcouponCode = this.state.offerDefaultDetails.couponCode;
    } else {
      updatedcouponCode = this.state.couponCode;
    }
    if (this.state.description === "") {
      updatedDescription = this.state.offerDefaultDetails.description;
    } else {
      updatedDescription = this.state.description;
    }
    if (this.state.duration === "") {
      updatedDuration = this.state.offerDefaultDetails.duration;
    } else {
      updatedDuration = this.state.duration;
    }
    if (this.state.sDate === "") {
      updatedSDate = this.state.offerDefaultDetails.start_date;
    } else {
      updatedSDate = this.state.sDate;
    }
    if (this.state.eDate === "") {
      updatedEDate = this.state.offerDefaultDetails.end_date;
    } else {
      updatedEDate = this.state.eDate;
    }
    if (this.state.type === "") {
      updatedOfferType = this.state.offerDefaultDetails.offer_type;
    } else {
      updatedOfferType = this.state.type;
    }
    if (this.state.percentageValue === "") {
      updatedPercentageValue = this.state.offerDefaultDetails.offer_percentage;
    } else {
      updatedPercentageValue = this.state.offer_percentage;
    }
    if (this.state.limit === "") {
      updatedLimit = this.state.offerDefaultDetails.limit;
    } else {
      updatedLimit = this.state.limit;
    }
    if (this.state.limitCount === "") {
      updatedLimitCount = this.state.offerDefaultDetails.limit_count;
    } else {
      updatedLimitCount = this.state.limitCount;
    }
    if (
      (this.state.title === "" ||
        this.state.title === this.state.offerDefaultDetails.title) &&
      (this.state.couponCode === "" ||
        this.state.couponCode === this.state.offerDefaultDetails.couponCode) &&
      (this.state.description === "" ||
        this.state.description ===
          this.state.offerDefaultDetails.description) &&
      (this.state.duration === "" ||
        this.state.duration === this.state.offerDefaultDetails.duration) &&
      (this.state.sDate === "" ||
        this.state.sDate === this.state.offerDefaultDetails.start_date) &&
      (this.state.eDate === "" ||
        this.state.eDate === this.state.offerDefaultDetails.end_date) &&
      (this.state.type === "" ||
        this.state.type === this.state.offerDefaultDetails.offer_type) &&
      (this.state.percentageValue === "" ||
        this.state.percentageValue ===
          this.state.offerDefaultDetails.offer_percentage) &&
      (this.state.limit === "" ||
        this.state.limit === this.state.offerDefaultDetails.limit) &&
      (this.state.limitCount === "" ||
        this.state.limitCount === this.state.offerDefaultDetails.limit_count)
    ) {
      alert("no details are changed");
    }
    const requestBody = {
      id: this.state.offerDefaultDetails.id,
      title: updatedTitle,
      description: updatedDescription,
      offer_code: updatedcouponCode,
      start_date: updatedSDate,
      end_date: updatedEDate,
      duration: updatedDuration,
      offer_type: updatedOfferType,
      offer_percentage: updatedPercentageValue,
      // cashBack_amount: this.state.newOfferCashBack,
      // unique_user: this.state.NewOfferLimit === "YES" ? "YES" : "NO",
      // limit_apply: this.state.NewOfferLimit === "NO" ? "YES" : "NO",
      limit_apply: updatedLimit,
      limit_count: updatedLimitCount,
      created_date: this.state.date,
      store_id: storeFresh.storeId,
    };

    // catsSelected: JSON.stringify(this.state.catsSelected.map((p) => p.id)),
    // itemsSelected: JSON.stringify(
    //   this.state.itemsSelected.map((p) => p.info.id)
    // ),
    // food: this.state.food,
    // menu: this.state.menu,
    // measure: this.state.measure,
    // maxPrice: this.state.maxPrice,
    // console.log(requestBody);
    postClientDataResponse(
      clientHost + NEW_OFFER,
      storeFresh.key,
      requestBody,

      (onSuccessResponse) => {
        this.setState({
          title: "",
          description: "",
          offer_code: "",
          start_date: "",
          end_date: "",
          duration: "",
          offer_type: "",
          offer_percentage: "",
          unique_user: "",
          limit_apply: "",
          limitCount: "",
          created_date: this.state.date,
          store_id: storeFresh.storeId,
          // categories: "",
          // items: "",
        });
        this.myFormRef.reset();
      },
      (onError) => {
        alert(onError);
      }
    );
  };
  handlePercentageChange = (e) => {
    if (e.target.value <= 100) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  getDate = () => {
    var date = new Date().toISOString().slice(0, 10);
    this.setState({ date });
  };
  onAddOffer = (e) => {
    e.preventDefault();
    // this.setState({
    //   title: this.state.title,
    //   description: this.state.description,
    //   type: this.state.type,
    //   // catsSelected: this.state.catsSelected,
    //   // itemsSelected: this.state.itemsSelected,
    //   food: this.state.food,
    //   menu: this.state.menu,
    //   measure: this.state.measure,
    //   maxPrice: this.state.maxPrice,
    // });
    //  let k = 0;
    //  if(offerError(this.state.newOfferName)){
    //    k++;
    //    this.setState({
    //      newOfferNameError: true,
    //    })
    //  }
    //  if(offerError(this.state.newOfferCouponCode)){
    //   k++;
    //   this.setState({
    //     newOfferCouponCodeError: true,
    //   })
    // }
    // if(offerError(this.state.newOfferDesc)){
    //   k++;
    //   this.setState({
    //     newOfferDescError: true,
    //   })
    // }
    // if(offerError(this.state.newOfferDuration)){
    //   k++;
    //   this.setState({
    //     newOfferDurationError: true,
    //   })
    // }
    // if(offerError(this.state.newOfferType)){
    //   k++;
    //   this.setState({
    //     newOfferTypeError: true,
    //   })
    // }
    // if(offerError(this.state.newOfferLimit)){
    //   k++;
    //   this.setState({
    //     newOfferLimitError: true,
    //   })
    // }
    // if (k === 0){

    const requestBody = {
      title: this.state.newOfferName,
      description: this.state.newOfferDescription,
      offer_code: this.state.newOfferCouponCode,
      start_date: this.state.newOfferStartDate,
      end_date: this.state.newOfferEndDate,
      duration: this.state.newOfferDuration,
      offer_type: this.state.newOfferType,
      offer_percentage: this.state.newOfferPercentageValue,
      // cashBack_amount: this.state.newOfferCashBack,
      unique_user: this.state.NewOfferLimit === "YES" ? "YES" : "NO",
      limit_apply: this.state.NewOfferLimit === "NO" ? "YES" : "NO",
      limit_count: this.state.newOfferLimitCount,
      created_date: this.state.date,
      store_id: storeFresh.storeId,
    };

    // catsSelected: JSON.stringify(this.state.catsSelected.map((p) => p.id)),
    // itemsSelected: JSON.stringify(
    //   this.state.itemsSelected.map((p) => p.info.id)
    // ),
    // food: this.state.food,
    // menu: this.state.menu,
    // measure: this.state.measure,
    // maxPrice: this.state.maxPrice,
    // console.log(requestBody);
    postClientDataResponse(
      clientHost + 'Offer/addOffer',
      storeFresh.key,
      requestBody,

      (onSuccessResponse) => {
        this.setState({
          title: "",
          description: "",
          offer_code: "",
          start_date: "",
          end_date: "",
          duration: "",
          offer_type: "",
          offer_percentage: "",
          unique_user: "",
          limit_apply: "",
          limitCount: "",
          created_date: this.state.date,
          store_id: storeFresh.storeId,
          // categories: "",
          // items: "",
        });
        this.myFormRef.reset();
      },
      (onError) => {
        alert(onError);
      }
    );

    // }
  };
  // validate = () => {
  //   if (
  //     this.state.title === null ||
  //     this.state.title === "" ||
  //     this.state.title === undefined
  //   ) {
  //     alert("title Error");
  //   } else if (
  //     this.state.description === null ||
  //     this.state.description === "" ||
  //     this.state.description === undefined
  //   ) {
  //     alert("description Error");
  //   } else if (
  //     this.state.type === null ||
  //     this.state.type === "" ||
  //     this.state.type === undefined
  //   ) {
  //     alert("type Error");
  //   }
  // else if (
  //   this.state.catsSelected === null ||
  //   this.state.catsSelected === [] ||
  //   this.state.catsSelected.length === 0 ||
  //   this.state.catsSelected === "" ||
  //   this.state.catsSelected === undefined
  // ) {
  //   alert("catsSelected Error");
  // } else if (
  //   this.state.itemsSelected === null ||
  //   this.state.itemsSelected === [] ||
  //   this.state.itemsSelected.length === 0 ||
  //   this.state.itemsSelected === "" ||
  //   this.state.itemsSelected === undefined
  // ) {
  //   alert("itemsSelected Error");
  // } else if (
  //   this.state.food === null ||
  //   this.state.food === "" ||
  //   this.state.food === undefined
  // ) {
  //   alert("food Error");
  // } else if (
  //   this.state.menu === null ||
  //   this.state.menu === "" ||
  //   this.state.menu === undefined
  // ) {
  //   alert("menu Error");
  // } else if (
  //   this.state.measure === null ||
  //   this.state.measure === "" ||
  //   this.state.measure === undefined
  // ) {
  //   alert("measure error");
  // } else if (
  //   this.state.maxPrice === null ||
  //   this.state.maxPrice === "" ||
  //   this.state.maxPrice === undefined
  // ) {
  //   alert("maxPrice error");
  // }
  //   else {
  //     alert(
  //       this.state.title +
  //         this.state.description +
  //         this.state.type +
  //         // this.state.catsSelected.map((p) => p.name) +
  //         // this.state.itemsSelected.map((p) => p.name) +
  //         this.state.food +
  //         this.state.menu +
  //         this.state.measure +
  //         this.state.maxPrice
  //     );
  //     this.postNewOffer();
  //   }
  // };
  postNewOffer = () => {
    const requestBody = {
      title: this.state.title,
      description: this.state.description,
      type: this.state.type,
      // catsSelected: JSON.stringify(this.state.catsSelected.map((p) => p.id)),
      // itemsSelected: JSON.stringify(
      //   this.state.itemsSelected.map((p) => p.info.id)
      // ),
      food: this.state.food,
      menu: this.state.menu,
      measure: this.state.measure,
      maxPrice: this.state.maxPrice,
    };
    postClientDataResponse(
      clientHost + NEW_OFFER,
      requestBody,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          title: "",
          description: "",
          type: "",
          // catsSelected: [],
          // itemsSelected: [],
          food: "",
          menu: "",
          measure: "",
          maxPrice: "",
          // categories: "",
          // items: "",
        });
        this.myFormRef.reset();
      },
      (onError) => {
        alert(onError);
      }
    );
  };
  // closeModal = () => {
  //   this.setState({ categories: null, items: null, getItems: "" });
  // };
  // handleAddedCat = (p) => {
  //   this.setState({
  //     catsSelected: [...this.state.catsSelected, p],
  //   });
  // };
  // handleAddedItem = (p) => {
  //   this.setState({
  //     itemsSelected: [...this.state.itemsSelected, p],
  //   });
  // };
  // setAddedCats = () => {
  //   this.setState({
  //     catsSelected: this.state.catsSelected,
  //   });
  // };
  onNewOffer = () => {
    this.setState({
      newOffer: true,
    });
  };
  goBack = () => {
    this.setState({
      newOffer: false,
    });
  };
  getOffers = () => {
    var apiPath = new Uri(clientHost + OFFERS_LIST).addQueryParam(
      "store_id",
      storeIdValue
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          offers: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  render() {
    // console.log(JSON.parse(storeDet));
    // const { categories } = this.state;
    // const { items } = this.state;
    const { offers } = this.state;
    const { newOffer } = this.state;
    return (
      <div>
        {newOffer !== true ? (
          <div className="content">
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    title="Striped Table with Hover"
                    category="Here is a subtitle for this table"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <>
                        <div className="header">
                          <h4
                            className="title"
                            style={{ position: "absolute", top: "1%", }}
                          >
                            Offers List
                          </h4>
                        </div>
                        <div>
                          <button
                            style={{ float: "right" ,
                               font:"50px"
                          }}
                            className="btn btn-primary"
                            onClick={this.onNewOffer}
                          >
                            New Offer
                          </button>
                        </div>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>Status</th>
                              {/* <th>uniqueU</th> */}
                              <th>title</th>
                              {/* <th>SID</th> */}
                              {/* <th>descrip</th> */}
                              <th>type</th>
                              <th>%</th>
                              <th>₹</th>
                              {/* <th>lmtNo.</th> */}
                              <th>lmtApply</th>
                              <th>duration</th>
                              {/* <th>start</th> */}
                              {/* <th>end</th> */}
                              <th>usedNo.</th>
                              <th></th>
                              {/* <th>image</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {offers
                              ? offers !== []
                                ? offers.map((p, index) => {
                                    return (
                                      <tr key={index} className="col-lg-3">
                                        {/* <div className="client card"> */}
                                        <td>{p.status}</td>
                                        {/* <td>{p.unique_user}</td> */}
                                        {p.title.length > 10 ? (
                                          <td>{p.title.substring(0, 9)}...</td>
                                        ) : (
                                          <td>{p.title}</td>
                                        )}
                                        {/* <td>{p.store_id}</td> */}
                                        {/* {p.description.length > 12 ? (
                                          <td>
                                            {p.description.substring(0, 11)}...
                                          </td>
                                        ) : (
                                          <td>{p.description}</td>
                                        )} */}
                                        {p.offer_type.length > 10 ? (
                                          <td>
                                            {p.offer_type.substring(0, 9)}...
                                          </td>
                                        ) : (
                                          <td>{p.offer_type}</td>
                                        )}
                                        <td>{p.offer_percentage}%</td>
                                        <td>₹{p.offer_amount}</td>
                                        {/* <td>{p.limit_count}</td> */}
                                        <td>{p.limit_apply}</td>
                                        <td>{p.duration}</td>
                                        {/* <td>{p.start_date}</td> */}
                                        {/* <td>{p.end_date}</td> */}
                                        <td>{p.used_count}</td>
                                        {/* <td>{p.image_path}</td> */}
                                        <div className="right-col col-lg-3 d-flex align-items-center">
                                          <div className="card-close">
                                            <div className="dropdown">
                                              <button
                                                type="button"
                                                id="closeCard2"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                className="dropdown-toggle"
                                              >
                                                <i className="fa fa-ellipsis-v"></i>
                                              </button>
                                              <div
                                                aria-labelledby="closeCard2"
                                                className="dropdown-menu dropdown`-menu-right has-shadow"
                                              >
                                                <a
                                                  href="/#"
                                                  className="dropdown-item remove"
                                                  data-toggle="modal"
                                                  data-target="#myModal5"
                                                  //  className="fa fa-trash-o fa-lg"
                                                  //  style={{
                                                  //    color: "red",
                                                  //    position: "absolute",
                                                  //    right: 0,
                                                  //  }}
                                                  onClick={() =>
                                                    this.onOfferTriggerDelete(
                                                      p.id
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-times"></i>
                                                  Delete
                                                </a>
                                                <a
                                                  href="/#"
                                                  className="dropdown-item edit"
                                                  data-toggle="modal"
                                                  data-target="#myModal6"
                                                  onClick={() =>
                                                    this.onOfferTriggerEdit(p)
                                                  }
                                                >
                                                  <i className="fa fa-gear"></i>
                                                  Edit
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* </div> */}
                                      </tr>
                                    );
                                  })
                                : null
                              : null}
                          </tbody>
                        </Table>
                      </>
                    }
                  />
                </Col>
                <div className="card-body text-center">
                  <div
                    id="myModal5"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    className="modal fade text-left"
                  >
                    <div role="document" className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 id="exampleModalLabel" className="modal-title">
                            Do you Want to Delete This Offer
                          </h4>
                          <button
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                            className="close"
                          >
                            <span aria-hidden="true">x</span>
                          </button>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss={
                              this.props.offerDeleted === true ? "modal" : null
                            }
                            onClick={this.onOfferDelete}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-secondary"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Col md={12}>
                  <Card
                    plain
                    title="Striped Table with Hover"
                    category="Here is a subtitle for this table"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <Table hover>
                        <thead>
                          <tr>
                            {thArray.map((prop, key) => {
                              return <th key={key}>{prop}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {tdArray.map((prop, key) => {
                            return (
                              <tr key={key}>
                                {prop.map((prop, key) => {
                                  return <td key={key}>{prop}</td>;
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    }
                  />
                </Col> */}
              </Row>
            </Grid>
            <div className="card-body text-center">
              <div
                id="myModal6"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Edit offer
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">x</span>
                      </button>
                    </div>
                    <div className="card-body">
                      {this.props.offerDefaultDetails !== "" ? (
                        <form
                          className="form-horizontal"
                          ref={(el) => (this.myFormRef = el)}
                        >
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Title
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="title"
                                name="title"
                                onChange={this.onFieldChange}
                                placeholder={
                                  this.state.offerDefaultDetails.title
                                }
                              />
                              {/* <small className="help-block-none">provide title.</small> */}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Coupon Code
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  this.state.offerDefaultDetails.offer_code
                                }
                                name="couponCode"
                                onChange={this.onFieldChange}
                              />
                              {/* <small className="help-block-none">provide title.</small> */}
                            </div>
                          </div>
                          <div className="line"></div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Description
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  this.state.offerDefaultDetails.description
                                }
                                onChange={this.onFieldChange}
                                name="description"
                              />
                              {/* <small className="help-block-none">
                        provide description.
                      </small> */}
                            </div>
                          </div>
                          <div className="line"></div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Duration
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control mb-3"
                                name="duration"
                                onChange={this.onFieldChange}
                                // defaultValue={
                                //   this.state.offerDefaultDetails.duration
                                //   // ""
                                // }
                                value={this.state.offerDefaultDetails.duration}
                              >
                                <option value="" hidden>
                                  select duration
                                </option>
                                <option value="none">NONE</option>
                                <option value="limited">LIMITED</option>
                              </select>
                            </div>
                          </div>
                          <div className="line"></div>
                          {this.state.offerDefaultDetails.duration ===
                          "limited" ? (
                            <>
                              <div className="form-group row">
                                <label className="col-sm-3 form-control-label">
                                  Start Date
                                </label>
                                <div className="col-sm-3">
                                  <input
                                    type="date"
                                    className="form-control form-control-lg"
                                    placeholder="provide Start Date"
                                    value={
                                      this.state.offerDefaultDetails.start_date
                                    }
                                    onChange={this.onFieldChange}
                                    name="sDate"
                                    min={this.state.date}
                                  />
                                </div>
                              </div>
                              <div className="line"></div>
                              <div className="form-group row">
                                <label className="col-sm-3 form-control-label">
                                  End Date
                                </label>
                                <div className="col-sm-3">
                                  <input
                                    type="date"
                                    className="form-control form-control-lg"
                                    placeholder="provide End Date"
                                    
                                    value={
                                      this.state.offerDefaultDetails.end_date
                                    }
                                    onChange={this.onFieldChange}
                                    name="eDate"
                                    min={
                                      this.state.sDate === ""
                                        ? this.state.date
                                        : this.state.sDate
                                    }
                                  />
                                </div>
                              </div>
                              <div className="line"></div>
                            </>
                          ) : null}
                          <div className="line"></div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Type
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control mb-3"
                                name="type"
                                onChange={this.onFieldChange}
                                value={
                                  this.state.offerDefaultDetails.offer_type
                                }
                              >
                                <option value="none" disabled hidden>
                                  select offer type
                                </option>
                                {/* <option value="cashback">CashBack</option> */}
                                <option value="percentage">Percentage</option>
                              </select>
                            </div>
                          </div>
                          <div className="line"></div>
                          {this.state.offerDefaultDetails.offer_type ===
                          "percentage" ? (
                            <>
                              <div className="form-group row">
                                <label className="col-sm-3 form-control-label">
                                  Offer Percentage
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={
                                      this.state.offerDefaultDetails
                                        .offer_percentage
                                    }
                                    onChange={this.handlePercentageChange}
                                    name="percentageValue"
                                    maxLength="3"
                                    minValue="1"
                                    // value={this.state.newOfferPercentageValue}
                                    maxValue="100"
                                  />
                                  {/* <small className="help-block-none">percentage</small> */}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {/* {this.state.newOfferType === "cashback" ? (
                    <>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Cashback
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            placeholder=" cashback"
                            onChange={this.onFieldChange}
                            name="newOfferCashBack"
                          />
                         
                        </div>
                      </div>
                    </>
                  ) : null} */}
                          <div className="line"></div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Offer Limit
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control mb-3"
                                name="limit"
                                onChange={this.onFieldChange}
                                value={
                                  this.state.offerDefaultDetails.limit_apply
                                }
                              >
                                <option value="none" disabled hidden>
                                  select offer limit
                                </option>
                                <option value="yes">Unique User</option>
                                <option value="no">Limited Users</option>
                              </select>
                            </div>
                          </div>
                          <div className="line"></div>
                          {this.state.offerDefaultDetails.limit_apply ===
                          "no" ? (
                            <>
                              <div className="form-group row">
                                <label className="col-sm-3 form-control-label">
                                  limit Applied to
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={
                                      this.state.offerDefaultDetails.limit_count
                                    }
                                    onChange={this.onFieldChange}
                                    name="limitCount"
                                  />
                                  {/* <small className="help-block-none">percentage</small> */}
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="form-group row">
                            <div className="col-sm-4 offset-sm-3">
                              <button
                                type="submit"
                                className="btn btn-secondary"
                              >
                                Cancel
                              </button>
                              <span> </span>
                              <button
                                // type="submit"
                                className="btn btn-primary"
                                onClick={this.onUpdateOffer}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <button
                    type="button"
                    id="closeCard5"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                    <span onClick={this.goBack}>Go Back</span>
                  </button>
                  <div
                    aria-labelledby="closeCard5"
                    className="dropdown-menu dropdown-menu-right has-shadow"
                  >
                    <a href="#profile" className="dropdown-item remove">
                      <i className="fa fa-times"></i>Close
                    </a>
                    <a href="#profile" className="dropdown-item edit">
                      <i className="fa fa-gear"></i>Edit
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">All form elements</h3>
              </div>
              <div className="card-body">
                <form
                  className="form-horizontal"
                  ref={(el) => (this.myFormRef = el)}
                >
                  <div className="form-group row">
                    <label
                      className="col-sm-3 form-control-label"
                      style={
                        this.state.newOfferNameError === true
                          ? { color: "red" }
                          : null
                      }
                    >
                      Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        style={
                          this.state.newOfferNameError === true
                            ? { color: "red" }
                            : null
                        }
                        type="text"
                        className="form-control"
                        placeholder="title"
                        name="newOfferName"
                        onChange={this.onFieldChange}
                      />
                      {/* <small className="help-block-none">provide title.</small> */}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 form-control-label"
                      style={
                        this.state.newOfferCouponCodeError === true
                          ? { color: "red" }
                          : null
                      }
                    >
                      Coupon Code
                    </label>
                    <div className="col-sm-9">
                      <input
                        style={
                          this.state.newOfferCouponCodeError === true
                            ? { color: "red" }
                            : null
                        }
                        type="text"
                        className="form-control"
                        placeholder="Coupon Code"
                        name="newOfferCouponCode"
                        onChange={this.onFieldChange}
                      />
                      {/* <small className="help-block-none">provide title.</small> */}
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 form-control-label"
                      style={
                        this.state.newOfferDescError === true
                          ? { color: "red" }
                          : null
                      }
                    >
                      Description
                    </label>
                    <div className="col-sm-9">
                      <input
                        style={
                          this.state.newOfferDescError === true
                            ? { color: "red" }
                            : null
                        }
                        type="text"
                        className="form-control"
                        placeholder=" description"
                        onChange={this.onFieldChange}
                        name="newOfferDescription"
                      />
                      {/* <small className="help-block-none">
                        provide description.
                      </small> */}
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 form-control-label"
                      style={
                        this.state.newOfferDurationError === true
                          ? { color: "red" }
                          : null
                      }
                    >
                      Duration
                    </label>
                    <div className="col-sm-9">
                      <select
                        style={
                          this.state.newOfferDurationError === true
                            ? { color: "red" }
                            : null
                        }
                        className="form-control mb-3"
                        name="newOfferDuration"
                        onChange={this.onFieldChange}
                        defaultValue="none"
                      >
                        <option value="none" disabled hidden>
                          select duration
                        </option>
                        <option value="NONE">NONE</option>
                        <option value="LIMITED">LIMITED</option>
                      </select>
                    </div>
                  </div>
                  <div className="line"></div>
                  {this.state.newOfferDuration === "LIMITED" ? (
                    <>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Start Date
                        </label>
                        <div className="col-sm-3">
                          <input
                            type="date"
                            className="form-control form-control-lg"
                            placeholder="provide Start Date"
                            defaultValue={this.state.date}
                            onChange={this.onFieldChange}
                            name="newOfferStartDate"
                            min={this.state.date}
                          />
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          End Date
                        </label>
                        <div className="col-sm-3">
                          <input
                            type="date"
                            className="form-control form-control-lg"
                            placeholder="provide End Date"
                            // defaultValue={
                            //   // this.state.sDate === ""
                            //   //   ? this.state.date
                            //   //   : this.state.sDate
                            //   //////
                            //   this.state.sDate === ""
                            //     ? this.state.date
                            //     : this.state.eDate === ""
                            //     ? this.state.sDate
                            //     : this.state.eDate
                            // }
                            value={
                              this.state.sDate === ""
                                ? this.state.date
                                : this.state.eDate === ""
                                ? this.state.sDate
                                : // this.state.sDate !== this.state.date ? null :
                                new Date(this.state.eDate) <=
                                  new Date(this.state.sDate)
                                ? this.state.sDate
                                : this.state.eDate
                            }
                            onChange={this.onFieldChange}
                            name="newOfferEndDate"
                            min={
                              this.state.sDate === ""
                                ? this.state.date
                                : this.state.sDate
                            }
                          />
                        </div>
                      </div>
                      <div className="line"></div>
                    </>
                  ) : null}
                  <div className="line"></div>
                  {/* <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                      Start Date
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="provide Start Date"
                        defaultValue={this.state.date}
                        onChange={this.onFieldChange}
                        name="sDate"
                        min={this.state.date}
                      />
                      <small className="help-block-none">Start Date</small>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                      End Date
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="provide End Date"
                        value={
                          this.state.sDate === ""
                            ? this.state.date
                            : this.state.eDate === ""
                            ? this.state.sDate
                            : // this.state.sDate !== this.state.date ? null :
                            new Date(this.state.eDate) <=
                              new Date(this.state.sDate)
                            ? this.state.sDate
                            : this.state.eDate
                        }
                        onChange={this.onFieldChange}
                        name="eDate"
                        min={
                          this.state.sDate === ""
                            ? this.state.date
                            : this.state.sDate
                        }
                      />
                      <small className="help-block-none">End Date</small>
                    </div>
                  </div> */}
                  <div className="line"></div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 form-control-label"
                      style={
                        this.state.newOfferTypeError === true
                          ? { color: "red" }
                          : null
                      }
                    >
                      Type
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control mb-3"
                        style={
                          this.state.newOfferTypeError === true
                            ? { color: "red" }
                            : null
                        }
                        name="newOfferType"
                        onChange={this.onFieldChange}
                        defaultValue="none"
                      >
                        <option value="none" disabled hidden>
                          select offer type
                        </option>
                        {/* <option value="cashback">CashBack</option> */}
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                  </div>
                  <div className="line"></div>
                  {this.state.newOfferType === "percentage" ? (
                    <>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Offer Percentage
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            placeholder=" percentage"
                            onChange={this.handlePercentageChange}
                            name="newOfferPercentageValue"
                            maxLength="3"
                            minValue="1"
                            value={this.state.newOfferPercentageValue}
                            maxValue="100"
                          />
                          {/* <small className="help-block-none">percentage</small> */}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* {this.state.newOfferType === "cashback" ? (
                    <>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Cashback
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            placeholder=" cashback"
                            onChange={this.onFieldChange}
                            name="newOfferCashBack"
                          />
                         
                        </div>
                      </div>
                    </>
                  ) : null} */}
                  <div className="line"></div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 form-control-label"
                      style={
                        this.state.newOfferLimitError === true
                          ? { color: "red" }
                          : null
                      }
                    >
                      Offer Limit
                    </label>
                    <div className="col-sm-9">
                      <select
                        style={
                          this.state.newOfferLimitError === true
                            ? { color: "red" }
                            : null
                        }
                        className="form-control mb-3"
                        name="newOfferLimit"
                        onChange={this.onFieldChange}
                        defaultValue="none"
                      >
                        <option value="none" disabled hidden>
                          select offer limit
                        </option>
                        <option value="YES">Unique User</option>
                        <option value="NO">Limited Users</option>
                      </select>
                    </div>
                  </div>
                  <div className="line"></div>
                  {this.state.newOfferLimit === "NO" ? (
                    <>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          limit Applied to
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Offer limited to"
                            onChange={this.onFieldChange}
                            name="newOfferLimitCount"
                          />
                          {/* <small className="help-block-none">percentage</small> */}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                      Categories
                    </label>
                    <div className="col-sm-6">
                      <button
                        onClick={this.onCat}
                        // className="btn btn-primary"
                      >
                        Select Cats
                      </button>
                      <span> </span>
                      {this.state.catsSelected !== []
                        ? this.state.catsSelected.map((p, index) => (
                            <span key={index}>
                              {p.name}, <span> </span>
                            </span>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 form-control-label">Items</label>
                    <div className="col-sm-6">
                      <button
                        onClick={this.onItem}
                        // className="btn btn-primary"
                      >
                        Select Items
                      </button>
                      <span> </span>
                      {this.state.itemsSelected !== []
                        ? this.state.itemsSelected.map((p, index) => (
                            <span key={index}>
                              {p.info.name}, <span> </span>
                            </span>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                       <small className="text-primary">Food Type</small>
                    </label>
                    <div className="col-sm-9">
                      <input
                        id="veg"
                        type="radio"
                        value="veg"
                        name="food"
                        onChange={this.onFoodChange}
                      />
                      <label htmlFor="veg">Veg</label>
                      <span> </span>
                      <input
                        id="nonveg"
                        type="radio"
                        value="nonveg"
                        name="food"
                        onChange={this.onFoodChange}
                      />
                      <label htmlFor="nonveg">Non-Veg</label>
                      <span> </span>
                      <input
                        id="any"
                        type="radio"
                        value="any"
                        name="food"
                        onChange={this.onFoodChange}
                      />
                      <label htmlFor="any">Any</label>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                       <small className="text-primary">Menu Type</small>
                    </label>
                    <div className="col-sm-9">
                      <div>
                        <input
                          id="classic"
                          type="radio"
                          value="classic"
                          name="menu"
                          onChange={this.onMenuChange}
                        />
                        <label htmlFor="optionsRadios">Classic</label>
                        <span> </span>
                        <input
                          id="italian"
                          type="radio"
                          value="italian"
                          name="menu"
                          onChange={this.onMenuChange}
                        />
                        <label htmlFor="italian">Italian</label>
                        <span> </span>
                        <input
                          id="desi"
                          type="radio"
                          value="desi"
                          name="menu"
                          onChange={this.onMenuChange}
                        />
                        <label htmlFor="desi">Desi</label>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div> */}
                  {/* <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                      <small className="text-primary">Measure</small>
                    </label>
                    <div className="col-sm-9">
                      <div>
                        <input
                          id="regular"
                          type="radio"
                          value="regular"
                          name="measure"
                          onChange={this.onMeasureChange}
                        />
                        <label htmlFor="regular">Regular</label>
                        <span> </span>
                        <input
                          id="medium"
                          type="radio"
                          value="medium"
                          name="measure"
                          onChange={this.onMeasureChange}
                        />
                        <label htmlFor="medium">Medium</label>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                      MaxPrice
                    </label>
                    <div className="col-sm-9">
                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            placeholder=".col-md-4"
                            className="form-control"
                            onChange={this.onFieldChange}
                            name="maxPrice"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="line"> </div>
                  <div className="form-group row">
                    <div className="col-sm-4 offset-sm-3">
                      <button type="submit" className="btn btn-secondary">
                        Cancel
                      </button>
                      <span> </span>
                      <button
                        // type="submit"
                        className="btn btn-primary"
                        onClick={this.onAddOffer}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>


            <Offers
          data={this.state.offers}
          onFoodChange={this.onFoodChange}
          onMenuChange={this.onMenuChange}
          onMeasureChange={this.onMeasureChange}
          onFieldChange={this.onFieldChange}
          onOfferTriggerDelete={this.onOfferTriggerDelete}
          onOfferDelete={this.onOfferDelete}
          onOfferTriggerEdit={this.onOfferTriggerEdit}
          onUpdateOffer={this.onUpdateOffer}
          handlePercentageChange={this.handlePercentageChange}
          getDate={this.getDate}
        />
            {/* <div className="content">
              {categories && (
                <Modal
                  isOpen={true}
                  onRequestClose={this.closeModal}
                  ariaHideApp={false}
                  className="Modal"
                  // scrollable={true}
                >
                  <Zoom scrollable={true}>
                    <Card
                      content={
                        <div className="table-full-width">
                          <table className="table">
                            <tbody>
                              {categories
                                ? categories.map((p, index) => (
                                    <tr key={index}>
                                       <td>
                                        <div className="checkbox checkbox-inline">
                                          <input
                                            id={p.id}
                                            type="checkbox"
                                            onChange={() =>
                                              this.handleAddedCat(p)
                                            }
                                          />
                                          <label htmlFor={p.id}>{p.name}</label>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>
                          <table className="table">
                            <tbody>
                              <tr></tr>
                            </tbody>
                          </table>
                          <span> </span>
                          <span> </span>
                        </div>
                      }
                    />
                  </Zoom>
                </Modal>
              )}
            </div>
            <div className="content">
              {items && (
                <Modal
                  isOpen={true}
                  onRequestClose={this.closeModal}
                  ariaHideApp={false}
                  className="Modal"
                  // scrollable={true}
                >
                  <Zoom scrollable={true}>
                    <Card
                      content={
                        <div className="table-full-width">
                          <table className="table">
                            <thead>
                              <tr>
                                {this.state.catsSelected.map((p, index) => (
                                  <th
                                    key={index}
                                    onClick={() => this.getItems(p.id)}
                                  >
                                    {p.name}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.getItems
                                ? this.state.getItems.map((p, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="checkbox checkbox-inline">
                                          <input
                                            id={p.info.id}
                                            type="checkbox"
                                            onChange={() =>
                                              this.handleAddedItem(p)
                                            }
                                          />
                                          <label htmlFor={p.info.id}>
                                            {p.info.name}
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>
                          <table className="table">
                            <tbody>
                              <tr></tr>
                            </tbody>
                          </table>
                          <span> </span>
                          <span> </span>
                        </div>
                      }
                    />
                  </Zoom>
                </Modal>
              )}
            </div> */}
          </div>
        )}
      </div>
    );
  }
  async componentDidMount() {
    this.getDate();
    this.getOffers();
  }
}
export default Offers;
