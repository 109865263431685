import React, { Component } from "react";
import {
  Grid,
  Row,
  Col, // Table
} from "react-bootstrap";
import Card from "../Card/Card";
import { Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Modal from "react-modal";
import Zoom from "react-reveal/Zoom";
import ReactToPrint from "react-to-print";
// import imgsrc from "../../images/cart-add-icon.png";
import deletecartIcon from "../../images/delete-cart1.png";
import deletecartAll from "../../images/delete.png";
import refreshImg from "../../images/refresh.png";
// import orderImg from "../../images/check.png";
// import printerImg from "../../images/printer.png";
import offerImg from "../../images/offers.png";
import {
  CATEGORY_LIST,
  // IMG_PATH_CATEGORY,
  MENU_LIST,
  DELETE_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  PLACE_ORDER,
  MISC,
  CURRENCY,
  OFFERS_LIST,
} from "../../Network/ApiPaths";
import Uri from "../../Network/Uri";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
let clientHost;
let storeFresh;
let storeName;
// let gst;
let storeUserId;
let selectedCatId;
let catSelected;
let storeIdValue;
let longitude;
let latitude;
let storeAddress;
let storeDet;
class TakeOrder0 extends Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeIdValue = storeDet.storeId;

      storeUserId = storeDet.userId;
      longitude = storeDet.longitude;
      latitude = storeDet.latitute;
      storeAddress = storeDet.address;
      // gst = storeDet.gst;
    } else {
      storeIdValue = "";
      storeUserId = "";
      longitude = "";
      latitude = "";
      storeAddress = "";
      // gst = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
      clientHost = storeFresh.host;
      storeName = storeFresh.storeName;
    }
    this.state = {
      offerId: "",
      catSelected: false,
      proSelected: false,
      product: null,
      customer: null,
      radio: "",
      selectedCatId: "",
      measure: "",
      check: [],
      categories: [],
      products: [],
      filteredProducts: [],
      selectedCatProducts: [],
      priceID: "",
      cart: [],
      addOns: [],
      selectedAddons: [],
      itemNumber: "1",
      cartPrice: {},
      pay: "",
      orderPlaced: false,
      date: "",
      finalId: "",
      orderedItems: [],
      mobile: "",
      amount: "",
      change: "",
      customer_phone: "",
      cash_received: "",
      cash_balance: "",
      search: true,
      searchValue: "",
      bill_no: [],
      storeIdSet: "",
      takeAwayType: "",
    };
  }

  selectedCatID = (sCatId, sCatName) => {
    this.setState({
      selectedCatId: sCatId,
      cName: sCatName,
      collapse: true,
    });
  };
  searchInitiate = (searchValue) => {
    this.setState({ searchValue });
    this.onSearch(searchValue);
  };
  onSearch = (itemName) => {
    let allProducts = this.state.products;
    const filteredData = allProducts.filter((product) => {
      let info = product.info;
      return (
        info.name.toLowerCase().includes(itemName.toLowerCase()) &&
        info.store_id === storeIdValue
      );
    });
    if (itemName === "" || (null && this.state.catId !== "")) {
      this.setState({ filteredProducts: this.state.selectedCatProducts });
    } else this.setState({ filteredProducts: filteredData });
  };
  onCat = (e) => {
    const products = this.state.products
      ? this.state.products !== [] || this.state.products.null
        ? this.state.products.filter((q) => q.info.category_id === e.id)
        : null
      : null;
    // console.log('onCat',e.id)
    this.setState({
      selectedCatProducts: products,
      filteredProducts: products,
      catSelected: true,
      catId: e.id,
      addonStatus: e.addon_status,
      search: true,
      searchValue: "",
      selectedCatId: e.id,
    });

    this.getAddOn(e.id);
  };
  onProduct = (p) => {
    if (p.prices) {
      if (p.prices.length > 1) {
        if (this.state.priceID !== "") {
          this.setState({ product: p });
        } else alert("select price");
      } else this.setState({ product: p });
    }
  };
  onOffer = () => {
    if (this.state.cart !== null) this.setState({ offer: "p" });
  };
  getDate = () => this.setState({ date: new Date().toLocaleString() });
  openModal = (product, offer) => this.setState({ product, offer });
  closeModal = () => this.setState({ product: null, offer: null });
  // onPaytype = (e) => this.setState({ pay: e.target.value });
  onPaytype = (e) => this.setState({ pay: e });
  checkChange = (e) => this.setState({ check: e.target.name });
  onOfferTrigger = (p) => this.setState({ offerId: p.id });
  onFieldChange = (e) => this.setState({ [e.target.name]: e.target.value });
  numberOfItems = (e) =>
    this.setState({ itemNumber: e.target.value.toString() });
  onRadioChanged = (e) => {
    this.setState({
      priceID: e.id,
      measure: e.measure,
    });
  };
  addToCart = (p) => {
    if (this.state.priceID === "" && p.prices.length > 1) {
      alert("select price");
    } else {
      this.setState({
        proSelected: true,
        product: null,
        orderPlaced: false,
        finalId: "",
        amount: "",
        change: "",
      });
      if (p.prices) {
        if (p.prices.length > 1) {
          const requestBody = {
            quantity: this.state.itemNumber,
            price_id: this.state.priceID,
            user_id: storeUserId,
            product_id: p.info.id,
            type: "item",
          };
          postClientDataResponse(
            clientHost + ADD_TO_CART,
            storeFresh.key,
            requestBody,
            (onSuccessResponse) => {
              this.setState({
                selectedAddons: [],
                itemNumber: "1",
                priceID: "",
                measure: "",
              });
              this.getCart();
            },
            (onError) => console.log(onError.message)
          );
        } else {
          let pid = p.prices.map((p) => p.id);
          const requestBody = {
            quantity: this.state.itemNumber,
            price_id: pid.toString(),
            user_id: storeUserId,
            product_id: p.info.id,
          };
          postClientDataResponse(
            clientHost + ADD_TO_CART,
            storeFresh.key,
            requestBody,
            (onSuccessResponse) => {
              this.setState({
                selectedAddons: [],
                itemNumber: "1",
                priceID: "",
                measure: "",
              });
              this.getCart();
            },
            (onError) => console.log(onError.message)
          );
        }
      }
    }
  };
  getCart = () => {
    var apiPath = new Uri(
      clientHost +
        `Cart/userCartItems?offset=0&user_id=${storeUserId}&limit=100`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ cart: onSuccessResponse.result, pay: "" });
      },
      (onError) => console.log(onError.message)
    );
    var apiPath2 = new Uri(clientHost + `Cart/cartPrice?userid=${storeUserId}`);
    getResponseClient(
      apiPath2,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ cartPrice: onSuccessResponse.result });
      },
      (onError) => console.log(onError.message)
    );
  };
  handleAddon = (e, p) => {
    let measureCheck = this.state.measure;
    if (measureCheck !== "") {
      if (measureCheck === "Regular") {
        let y = {
          name: e.name,
          id: e.id,
          price: e.price_regular,
          quantity: this.state.itemNumber,
        };
        // console.log(y)
        this.setState({ selectedAddons: [...this.state.selectedAddons, y] });
      } else {
        let y = {
          name: e.name,
          id: e.id,
          price: e.price_medium,
          quantity: this.state.itemNumber,
        };
        // console.log(y)
        this.setState({ selectedAddons: [...this.state.selectedAddons, y] });
      }
    } else {
      let y = {
        name: e.name,
        id: e.id,
        price: e.price_regular,
        quantity: this.state.itemNumber,
      };
      // console.log(y)
      this.setState({ selectedAddons: [...this.state.selectedAddons, y] });
    }

    // console.log(this.state.addOns)
  };
  onOrderVerify = () => {
    this.setState({
      mobile: this.state.mobile,
      amount: this.state.amount,
    });
    let payOption = this.state.pay;
    if (payOption === "") {
      alert("select payment");
    } else if (payOption === "online") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   alert("Phone number Error");
      // } else {
      this.placeOnlineOrder();
      // }
    } else if (payOption === "swipe") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   alert("Phone number Error");
      // } else {
      this.placeSwipeOrder();
      // }
    } else {
      if (
        //   this.state.mobile === null ||
        //   this.state.mobile === "" ||
        //   this.state.mobile === undefined
        //   // || this.state.mobile.length !== 10 ||
        //   // typeof this.state.mobile !== Number
        // ) {
        //   alert("Phone number Error");
        // } else if (
        this.state.amount === null ||
        this.state.amount === "" ||
        this.state.amount === undefined
        // || typeof this.state.amount !== Number
      ) {
        alert("Amount error");
      } else {
        this.onPlaceOrder();
      }
    }
  };
  onhandleOffer = () => {
    this.setState({ offer: null });
    var apiPath = new Uri(
      clientHost +
        `Cart/cartPrice?userid=${storeUserId}&offerId=${this.state.offerId}`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ cartPrice: onSuccessResponse.result });
      },
      (onError) => console.log(onError.message)
    );
  };
  onForward = () => {
    this.setState({
      mobile: this.state.mobile,
      amount: this.state.amount,
    });
    let payOption = this.state.pay;
    if (payOption === "online") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   // alert("Phone number Error");
      // } else {
      this.setState({ customer: "" });
      this.placeOnlineOrder();
      // }
    } else if (payOption === "swipe") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   // alert("Phone number Error");
      // } else {
      this.setState({ customer: "" });
      this.placeSwipeOrder();
      // }
    } else {
      this.validate();
    }
  };
  validate = () => {
    // let phoneStatus = parseInt(this.state.mobile);
    // if (
    //   this.state.mobile === null ||
    //   this.state.mobile === "" ||
    //   this.state.mobile === undefined
    //   // || this.state.mobile.length !== 10 ||
    //   // typeof this.state.mobile !== Number
    // ) {
    //   // alert("Phone number Error");
    // } else
    if (
      this.state.amount === null ||
      this.state.amount === "" ||
      this.state.amount === undefined
      // || typeof this.state.amount !== Number
    ) {
      // alert("Amount error");
    } else {
      this.setState({ customer: "" });
      this.onPlaceOrder();
    }
  };
  placeSwipeOrder = () => {
    const requestBody = {
      userId: storeUserId,
      storeId: storeIdValue,
      shipping_address: storeName,
      payment_mode: "CARD",
      payment_status: "PAID_BY_MACHINE",
      online_ref_id: "",
      platform: "POS",
      landmark: "PG",
      address_lat: latitude,
      address_lng: longitude,
      order_status: "4",
      customer_phone: this.state.mobile,
      cash_received: "0.0",
      cash_balance: "0.0",
      bill_no: this.state.bill_no + 1,
      offerId: this.state.offerId,
    };
    postClientDataResponse(
      clientHost + PLACE_ORDER,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.setState({
          orderPlaced: true,
          finalId: onSuccessResponse.data.result,
        });
        this.getItems();
        this.tillOrders();
      },
      (onError) => console.log(onError.message)
    );
  };
  placeOnlineOrder = () => {
    const requestBody = {
      userId: storeUserId,
      storeId: storeIdValue,
      shipping_address: storeName,
      payment_mode: "ONLINE",
      payment_status: "PAID_BY_PAYTM",
      platform: "POS",
      online_ref_id: "online_90",
      landmark: "PG",
      address_lat: latitude,
      address_lng: longitude,
      order_status: "4",
      customer_phone: this.state.mobile,
      cash_received: "",
      cash_balance: "0.0",
      items: JSON.stringify(this.state.cart),
      bill_no: this.state.bill_no + 1,
      offerId: this.state.offerId,
    };
    postClientDataResponse(
      clientHost + PLACE_ORDER,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.setState({
          orderPlaced: true,
          finalId: onSuccessResponse.data.result,
        });
        this.getItems();
        this.tillOrders();
      },
      (onError) => console.log(onError.message)
    );
  };
  onPlaceOrder = () => {
    const requestBody = {
      userId: storeUserId,
      storeId: storeIdValue,
      shipping_address: storeName,
      payment_mode: "COD",
      payment_status: "PAID_BY_COD",
      paytm_ref_id: "cod_90",
      platform: "POS",
      landmark: "PG",
      address_lat: latitude,
      address_lng: longitude,
      order_status: "4",
      customer_phone: this.state.mobile,
      cash_received: this.state.amount,
      cash_balance: this.state.cartPrice.is_offer_applied
        ? this.state.cartPrice.is_offer_applied === false
          ? Number(this.state.amount) - Number(this.state.cartPrice.cartTotal)
          : Number(this.state.amount) -
            Number(this.state.cartPrice.offer_amount)
        : Number(this.state.amount) - Number(this.state.cartPrice.cartTotal),
      // Number(this.state.amount) -
      // (Number(this.state.cartPrice.itemsTotal) +
      //   Number(this.state.cartPrice.addonTotal) +
      //   (5 / 100) * Number(this.state.cartPrice.itemsTotal) +
      //   Number(this.state.cartPrice.addonTotal)),
      bill_no: this.state.bill_no + 1,
      offerId: this.state.offerId,
    };
    if (requestBody.cash_balance >= 0) {
      postClientDataResponse(
        clientHost + PLACE_ORDER,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            orderPlaced: true,
            finalId: onSuccessResponse.data.result,
          });
          this.getItems();
          this.tillOrders();
        },
        (onError) => console.log(onError.message)
      );
    }
  };
  getItems = () => {
    var apiPath = new Uri(
      clientHost + `Order/getOrderItems?order_id=${this.state.finalId}`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ orderedItems: onSuccessResponse.result });
      },
      (onError) => console.log(onError.message)
    );
  };
  deleteCart = () => {
    let userid = storeUserId;
    var apiPath = new Uri(clientHost + DELETE_CART).addQueryParam(
      "userid",
      userid
    );

    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getCart();
      },
      (onError) => console.log(onError.message)
    );
  };
  removeFromCart = (e) => {
    let removeId = e.id;
    var apiPath = new Uri(clientHost + REMOVE_FROM_CART).addQueryParam(
      "id",
      removeId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getCart();
        this.setState({
          amount: "",
          change: "",
        });
      },
      (onError) => console.log(onError.message)
    );
  };
  checkAddons = (p) => {
    let x = this.state.selectedAddons;
    if (x.length === 0) {
      // alert("add addons");
    } else {
      this.addWithAddons(p);
    }
  };
  addWithAddons = (p) => {
    this.setState({
      proSelected: true,
      product: null,
    });
    if (p.prices) {
      if (p.prices.length > 1) {
        const requestBody = {
          quantity: this.state.itemNumber,
          price_id: this.state.priceID,
          user_id: storeUserId,
          product_id: p.info.id,
          addons: JSON.stringify(this.state.selectedAddons),
        };
        postClientDataResponse(
          clientHost + ADD_TO_CART,
          storeFresh.key,
          requestBody,
          (onSuccessResponse) => {
            this.setState({
              selectedAddons: [],
              itemNumber: "1",
              priceID: "",
            });
          },
          (onError) => console.log(onError.message)
        );
      } else {
        let pid = p.prices.map((p) => p.id);
        const requestBody = {
          quantity: this.state.itemNumber,
          price_id: pid.toString(),
          user_id: storeUserId,
          product_id: p.info.id,
          addons: JSON.stringify(this.state.selectedAddons),
        };
        postClientDataResponse(
          clientHost + ADD_TO_CART,
          storeFresh.key,
          requestBody,
          (onSuccessResponse) => {
            this.setState({ selectedAddons: [], itemNumber: "1", priceID: "" });
          },
          (onError) => console.log(onError.message)
        );
      }
    }
    this.getCart();
  };
  refreshPage = () => {
    this.setState({
      catId: "",
      catSelected: false,
      proSelected: false,
      product: null,
      customer: null,
      radio: "",
      measure: "",
      check: [],
      filteredProducts: this.state.products,
      priceID: "",
      cart: [],
      selectedAddons: [],
      itemNumber: "1",
      cartPrice: {},
      pay: "",
      orderPlaced: false,
      finalId: "",
      orderedItems: [],
      mobile: "",
      amount: "",
      change: "",
      customer_phone: "",
      cash_received: "",
      cash_balance: "",
      takeAwayType: "",
    });
    this.getOrderDetails();
  };
  getOrderDetails = () => {
    var apiPath = new Uri(
      clientHost + `Report/getTodaySalesCount`
    ).addQueryParam("storeId", storeIdValue);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        onSuccessResponse.response.map((p) =>
          this.setState({
            totalOrders: p.OrdersCount,
            totalSales: p.TotalAmount,
            netSales: p.NetSale,
            average: p.Average,
          })
        );
      },
      (onError) => console.log(onError.message)
    );
  };
  getOffers = () => {
    var apiPath = new Uri(clientHost + OFFERS_LIST).addQueryParam(
      "storeid",
      storeIdValue
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          offers: onSuccessResponse.result,
        });
      },
      (onError) => console.log(onError.message)
    );
  };
  takeAwayType = (takeAwayType) => this.setState({ takeAwayType });
  keypadValue = (keypadValue) => {
    if (this.state.pay === "cash") {
      this.setState({ amount: this.state.amount + keypadValue });
    } else this.setState({ amount: "" });
  };
  onClear = () => this.setState({ amount: "", change: "" });
  render() {
    const styles = {
      margin: { margin: "0 5px 0 5px", color: "#000000", display: "inline" },
      marginAll10: {
        margin: "10px 10px 10px 10px",
        width: "20px",
        height: "20px",
      },
      marginAll5: {
        margin: "5px 5px 5px 5px",
        width: "20px",
        height: "20px",
      },
      marginAll55: {
        margin: "2px 2px 2px 2px",
        width: "18px",
        height: "18px",
      },
      imgSmall: { margin: "0 10px 0 20px", width: "30px", height: "30px" },
      imageAdd: { margin: "0 0 0 8px", width: "50px", height: "50px" },
      imageMenu: { margin: "0 0 0 10px", width: "40px", height: "40px" },
      green: { color: "#008000" },
      tdCart: { width: "150px", padding: "5px" },
      tdCartLarge: { width: "200px", padding: "5px" },
      tdSmall: { width: "40px", padding: "5px" },
      red: { color: "#FF0000" },
    };
    let payOption = this.state.pay;
    let totalPrice;
    if (this.state.cartPrice) {
      if (
        this.state.cartPrice.itemsTotal !== null ||
        this.state.cartPrice.itemsTotal !== undefined ||
        this.state.cartPrice.addonTotal !== null ||
        this.state.cartPrice.addonTotal !== undefined
      ) {
        totalPrice =
          Number(this.state.cartPrice.itemsTotal) +
          Number(this.state.cartPrice.addonTotal) +
          Number(this.state.cartPrice.dealsPrice);
      }
    }
    // const a = [1, 2, 3, 4, 5, 6, 6, 8, 8];
    const addons = this.state.addOns;
    const { categories, product, cart, offer } = this.state;

    return (
      <div>
        <div className="main row-display">
          <div className="division">
            {/* <section> */}
            <br />
            <div className="container green mb-2">
              <div className="row">
                {categories ? (
                  categories !== [] || categories !== null ? (
                    <>
                      {categories.map((prop, index) =>
                        index < 5 ? (
                          <div
                            className="col"
                            key={index}
                            onClick={() => this.onCat(prop)}
                          >
                            <button>
                              {prop.id === this.state.catId ? (
                                <h4>
                                  {prop.name},&nbsp;
                                  {prop.min_price}
                                </h4>
                              ) : (
                                prop.name
                              )}
                            </button>
                          </div>
                        ) : null
                      )}
                    </>
                  ) : null
                ) : null}
              </div>
              <div className="row">
                {categories ? (
                  categories !== [] || categories !== null ? (
                    <>
                      {categories.map((prop, index) =>
                        index >= 5 && index < 9 ? (
                          <div className="col" onClick={() => this.onCat(prop)}>
                            <button>
                              {prop.id === this.state.catId ? (
                                <h4>
                                  {prop.name},&nbsp;
                                  {prop.min_price}
                                </h4>
                              ) : (
                                prop.name
                              )}
                            </button>
                          </div>
                        ) : null
                      )}
                    </>
                  ) : null
                ) : null}
              </div>
              <div className="row">
                {categories ? (
                  categories !== [] || categories !== null ? (
                    <>
                      {categories.map((prop, index) =>
                        index >= 9 ? (
                          <div className="col" onClick={() => this.onCat(prop)}>
                            <button>
                              {prop.id === this.state.catId ? (
                                <h4>
                                  {prop.name},&nbsp;
                                  {prop.min_price}
                                </h4>
                              ) : (
                                prop.name
                              )}
                            </button>
                          </div>
                        ) : null
                      )}
                    </>
                  ) : null
                ) : null}
              </div>
            </div>
            {/* </section>
            <section> */}
            {this.state.catSelected === true ||
            this.state.searchValue !== "" ? (
              <div className="container red mb-2">
                <div className="row">
                  {this.state.filteredProducts
                    .filter(
                      (p) =>
                        (p.info.is_deleted === "N" || p.info.status === "1") &&
                        p.prices !== null &&
                        this.state.storeIdSet === storeIdValue
                    )
                    .map((prop, key) =>
                      key < 5 ? (
                        <div
                          className="col"
                          onClick={() => {
                            console.log("----------------------");
                            this.onProduct(prop);
                          }}
                        >
                          <button>
                            {prop.info.name},&nbsp;{prop.info.min_price}
                          </button>
                        </div>
                      ) : null
                    )}
                </div>

                <div className="row">
                  {this.state.filteredProducts
                    .filter(
                      (p) =>
                        (p.info.is_deleted === "N" || p.info.status === "1") &&
                        p.prices !== null &&
                        this.state.storeIdSet === storeIdValue
                    )
                    .map((prop, key) =>
                      key >= 5 && key < 9 ? (
                        <div
                          className="col"
                          onClick={() => this.addToCart(prop)}
                        >
                          <button>
                            {prop.info.name},&nbsp;
                            {prop.info.min_price}
                          </button>
                        </div>
                      ) : null
                    )}
                </div>

                <div className="row">
                  {this.state.filteredProducts
                    .filter(
                      (p) =>
                        (p.info.is_deleted === "N" || p.info.status === "1") &&
                        p.prices !== null &&
                        this.state.storeIdSet === storeIdValue
                    )
                    .map((prop, key) =>
                      key >= 9 && key < 14 ? (
                        <div
                          className="col"
                          onClick={() => this.addToCart(prop)}
                        >
                          <button>
                            {prop.info.name},&nbsp;
                            {prop.info.min_price}
                          </button>
                        </div>
                      ) : null
                    )}
                </div>

                <div className="row">
                  {this.state.filteredProducts
                    .filter(
                      (p) =>
                        (p.info.is_deleted === "N" || p.info.status === "1") &&
                        p.prices !== null &&
                        this.state.storeIdSet === storeIdValue
                    )
                    .map((prop, key) =>
                      key >= 14 && key < 19 ? (
                        <div
                          className="col"
                          onClick={() => this.addToCart(prop)}
                        >
                          <button>
                            {prop.info.name},&nbsp;
                            {prop.info.min_price}
                          </button>
                        </div>
                      ) : null
                    )}
                </div>

                <div className="row">
                  {this.state.filteredProducts
                    .filter(
                      (p) =>
                        (p.info.is_deleted === "N" || p.info.status === "1") &&
                        p.prices !== null &&
                        this.state.storeIdSet === storeIdValue
                    )
                    .map((prop, key) =>
                      key >= 19 ? (
                        <div
                          className="col"
                          onClick={() => this.onProduct(prop)}
                        >
                          <button>
                            {prop.info.name},&nbsp;
                            {prop.info.min_price}
                          </button>
                        </div>
                      ) : null
                    )}
                </div>
              </div>
            ) : null}
            {/* </section>
            <section> */}
            {/* <div className="container orange">
              <div className="row">
                <div className="col">
                  <button>Error Correct</button>
                </div>
                <div className="col">
                  <button>Void</button>
                </div>
                <div className="col">
                  <button>Eat Out TO Help Out</button>
                </div>
                <div className="col">
                  <button>Layaway</button>
                </div>
                <div className="col">
                  <button>Open Drawer</button>
                </div>
                <div className="col">
                  <button>Print Receipt</button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button>Cancel Sale</button>
                </div>
                <div className="col">
                  <button>Wastage</button>
                </div>
                <div className="col">
                  <button>Nothing</button>
                </div>
                <div className="col">
                  <button>Kitchen Message</button>
                </div>
                <div className="col">
                  <button>Today's Review Sales</button>
                </div>
                <div className="col">
                  <button>Funtion Menu</button>
                </div>
              </div>
            </div> */}
            {/* </section> */}
          </div>
          <div className="division1">
            <br />
            {/* <section style={{ "backgroundColor": "blue" }}> */}
            <div className="bill" style={{ backgroundColor: "blue" }}>
              <div className="container">
                <div className="row table-head">
                  <div className="col-5 number-orange">Items</div>
                  <div className="col-2 number-orange">Qty</div>
                  <div className="col-4 number-orange">Price</div>
                  {/* <div className="col-1 number-orange"></div> */}
                </div>
                {/* </div> */}
                {cart
                  ? cart !== ([] || null || "" || " " || undefined)
                    ? cart.map((prop, key) => {
                        return (
                          // <div className="container">
                          <div
                            className="row table-head"
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            {/* <div
                              className="col-5 number-white"
                              style={{ color: "black" }}
                            > */}
                            <div
                              class="col-5 number-brown"
                              style={{ "font-weight": "bold" }}
                            >
                              {prop.type === "deal" ? prop.dealName : prop.name}
                              {prop.addons !== null
                                ? prop.addons
                                  ? prop.addons.map((p, index) => (
                                      <td key={index}>
                                        ,-{p.name}
                                        <br />
                                      </td>
                                    ))
                                  : null
                                : null}
                            </div>

                            <div
                              className="col-2 number-brown"
                              style={{ "font-weight": "bold" }}
                            >
                              {/* {prop.measure === "None" ||
                                prop.measure === "NONE"
                                  ? null
                                  : prop.measure}
                                &nbsp; */}
                              {prop.quantity}
                            </div>

                            <div
                              className="col-4 number-brown"
                              style={{ "font-weight": "bold" }}
                            >
                              <i className={CURRENCY} aria-hidden="true"></i>
                              {prop.type === "deal"
                                ? Number(prop.dealPrice).toFixed(2)
                                : Number(prop.cartTotal).toFixed(2)}
                            </div>

                            <div className="col-1 number-white">
                              {/* <span></span> */}
                              <Image
                                onClick={() => this.removeFromCart(prop)}
                                src={deletecartIcon}
                                style={styles.marginAll55}
                              ></Image>
                            </div>
                            {/* </div> */}
                          </div>
                        );
                      })
                    : null
                  : null}
              </div>
              {/* <br /> */}
              {/* <div className="white-space">White Space</div> */}
              {/* {isNaN(this.state.cartPrice) ? (
                
                <>
                  <div
                    className="row-display center"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="mr-4">
                      Total:{" "}
                       <span>
                        {this.state.cartPrice ?
                           Number(this.state.cartPrice.cartTotal).toFixed(2)
                          : 0}
                      </span> 
                    </div>
                     <div className="ml-4">
                      Tendered:
                      {this.state.cartPrice.is_offer_applied ? (
                        this.state.cartPrice.is_offer_applied === false ? (
                          <span>0.00</span>
                        ) : (
                          <span>
                            {(
                              Number(
                                this.state.cartPrice
                                  ? this.state.cartPrice.cartTotal
                                  : 0
                              ) -
                              Number(
                                this.state.cartPrice
                                  ? this.state.cartPrice.offer_amount
                                  : 0
                              )
                            ).toFixed(2)}
                          </span>
                        )
                      ) : (
                        <span>0.00</span>
                      )}
                    </div> 
                  </div>
                  <div>
                    <div
                      className="text-center pay"
                      style={{ backgroundColor: "white" }}
                      
                    >
                     
                      To Pay:&nbsp;&nbsp;
                      
                      <i className={CURRENCY} aria-hidden="true"></i>
                       <span>
                        {this.state.cartPrice
                          ? Number(this.state.cartPrice.cartTotal).toFixed(2)
                          : 0}
                      </span> 
                    </div>
                  </div>
                </>
              ) : null} */}
            </div>
            <div className="row-display m-2">
              {/* <div>
                <button className="cl">CL</button>
              </div>
              <div>
                <input type="text" size="20" />
              </div> */}
              <div>
                {payOption === "cash" ? (
                  <>
                    <input
                      id="amount"
                      size="4"
                      type="text"
                      name="amount"
                      required
                      onChange={this.onFieldChange}
                      placeholder="amount"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        height: "30px",
                      }}
                      value={this.state.amount}
                    />{" "}
                    <input
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        height: "30px",
                      }}
                      id="change"
                      size="4"
                      type="text"
                      name="change"
                      required
                      onChange={this.onFieldChange}
                      placeholder="change"
                      value={
                        this.state.amount === ""
                          ? 0
                          : this.state.cartPrice.is_offer_applied
                          ? this.state.cartPrice.is_offer_applied === false
                            ? (
                                Number(this.state.amount) -
                                Number(this.state.cartPrice.cartTotal)
                              ).toFixed(2)
                            : Number(this.state.amount) -
                              Number(this.state.cartPrice.offer_amount)
                          : (
                              Number(this.state.amount) -
                              Number(this.state.cartPrice.cartTotal)
                            ).toFixed(2)
                      }
                      readOnly
                    />
                  </>
                ) : null}
              </div>
              <div>
                <Image
                  onClick={this.deleteCart}
                  src={deletecartAll}
                  style={styles.marginAll5}
                ></Image>
                <Image
                  onClick={this.getCart}
                  src={refreshImg}
                  style={styles.marginAll5}
                ></Image>
                <Image
                  onClick={this.onOffer}
                  src={offerImg}
                  style={styles.marginAll5}
                ></Image>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {isNaN(this.state.cartPrice) ? (
                <>
                  <div className="row-display center">
                    <div className="mr-4">
                      Total:{" "}
                      <span>
                        {this.state.cartPrice
                          ? Number(this.state.cartPrice.cartTotal).toFixed(2)
                          : 0}
                      </span>
                    </div>
                    {/* <div className="ml-4">
                      Tendered:
                      {this.state.cartPrice.is_offer_applied ? (
                        this.state.cartPrice.is_offer_applied === false ? (
                          <span>0.00</span>
                        ) : (
                          <span>
                            {(
                              Number(
                                this.state.cartPrice
                                  ? this.state.cartPrice.cartTotal
                                  : 0
                              ) -
                              Number(
                                this.state.cartPrice
                                  ? this.state.cartPrice.offer_amount
                                  : 0
                              )
                            ).toFixed(2)}
                          </span>
                        )
                      ) : (
                        <span>0.00</span>
                      )}
                    </div> */}
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <div className="text-center pay" style={{}}>
                      {/* {console.log("currency showning",CURRENCY)}  */}
                      To Pay:&nbsp;&nbsp;
                      <i className={CURRENCY} aria-hidden="true"></i>
                      <span>
                        {this.state.cartPrice
                          ? Number(this.state.cartPrice.cartTotal).toFixed(2)
                          : 0}
                      </span>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {/* </section>
            <section> */}
            <div className="container num">
              <div className="row">
                <div className="col-3">
                  <button className="operations">Qty</button>
                </div>
                <div
                  className="col-3"
                  onClick={() => this.takeAwayType("Dine In")}
                >
                  <button className="number-orange">
                    {this.state.takeAwayType === "Dine In" ? (
                      <h4>Dine In</h4>
                    ) : (
                      <>Dine In</>
                    )}
                  </button>
                </div>
                <div
                  className="col-3"
                  onClick={() => this.takeAwayType("Take Away")}
                >
                  <button className="number-orange">
                    {this.state.takeAwayType === "Take Away" ? (
                      <h4>Take Away</h4>
                    ) : (
                      <>Take Away</>
                    )}
                  </button>
                </div>
                <div className="col-3" onClick={() => this.onClear("clear")}>
                  <button className="operations">Clear</button>
                </div>
              </div>
              <div className="row">
                <div className="col-3" onClick={() => this.keypadValue("7")}>
                  <button className="number-orange">7</button>
                </div>
                <div className="col-3" onClick={() => this.keypadValue("8")}>
                  <button className="number-orange">8</button>
                </div>
                <div className="col-3" onClick={() => this.keypadValue("9")}>
                  <button className="number-orange">9</button>
                </div>
                {this.state.pay === "" ||
                this.state.takeAwayType === "" ? null : this.state.pay ===
                    "cash" &&
                  (Number(this.state.amount) <= 0 ||
                    Number(this.state.amount) === "" ||
                    Number(this.state.amount) -
                      Math.round(
                        Number(totalPrice) + (5 / 100) * Number(totalPrice)
                      ) <
                      0) ? null : (
                  <ReactToPrint
                    onBeforeGetContent={this.onOrderVerify}
                    onAfterPrint={this.refreshPage}
                    trigger={() => (
                      <div className="col-3">
                        <button className="operations">Checkout</button>
                      </div>
                    )}
                    content={() => this.componentRef}
                  />
                )}

                {/* <div className="col-3">
                    <button className="operations">Enter</button>
                  </div> */}
              </div>
              <div className="row">
                <div className="col-3" onClick={() => this.keypadValue("4")}>
                  <button className="number-orange">4</button>
                </div>
                <div className="col-3" onClick={() => this.keypadValue("5")}>
                  <button className="number-orange">5</button>
                </div>
                <div className="col-3" onClick={() => this.keypadValue("6")}>
                  <button className="number-orange">6</button>
                </div>
                <div className="col-3" onClick={() => this.onPaytype("swipe")}>
                  <button className="operations">
                    {this.state.pay === "swipe" ? <h4>Card</h4> : <>Card</>}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-3" onClick={() => this.keypadValue("1")}>
                  <button className="number-orange">1</button>
                </div>
                <div className="col-3" onClick={() => this.keypadValue("2")}>
                  <button className="number-orange">2</button>
                </div>
                <div className="col-3" onClick={() => this.keypadValue("3")}>
                  <button className="number-orange">3</button>
                </div>
                <div className="col-3" onClick={() => this.state.totalprice}>
                  <button className="operations">Sub Total</button>
                </div>
              </div>
              <div className="row">
                <div className="col-3" onClick={() => this.keypadValue("0")}>
                  <button className="number-orange">0</button>
                </div>
                <div className="col-3" onClick={() => this.keypadValue(".")}>
                  <button className="number-orange">.</button>
                </div>
                <div className="col-6" onClick={() => this.onPaytype("cash")}>
                  <button className="operations">
                    {this.state.pay === "cash" ? <h4>Cash</h4> : <>Cash</>}
                  </button>
                </div>
              </div>
            </div>
            {/* </section> */}
          </div>
        </div>
        {/* <section className="dashboard-header">
          <div className="container-fluid">
            <div className="row">
              <div className="row col-8">
                {categories
                  ? categories !== [] || categories !== null
                    ? categories.map((prop, index) => (
                        <div className="chart col-lg-2 col-12">
                          <div
                            className={
                              prop.id === this.state.catId
                                ? "statistic d-flex align-items-center bg-violet has-shadow"
                                : "statistic d-flex align-items-center bg-blue has-shadow"
                            }
                            onClick={() => this.onCat(prop)}
                          >
                            <div className="text">
                               {prop.name}
                             </div>
                          </div>
                          <br />
                        </div>
                      ))
                    : null
                  : null}
                 {a.map((s) => (
                  <div className="chart col-lg-3 col-12">
                    <div
                      className={
                        "statistic d-flex align-items-center bg-white has-shadow"
                      }
                    >
                      <div className="text">
                        <h2>Categories name</h2>
                      </div>
                    </div>
                    <br />
                  </div>
                ))} 
                {this.state.catSelected === true ||
                this.state.searchValue !== "" ? (
                  <div className="chart col-lg-12 col-12">
                    <div className="row col-12">
                      {this.state.filteredProducts
                        .filter(
                          (p) =>
                            (p.info.is_deleted === "N" ||
                              p.info.status === "1") &&
                            p.prices !== null &&
                            this.state.storeIdSet === storeIdValue
                        )
                        .map((prop, key) => {
                          let cI = this.state.cart;
                          // // console.log(cI);
                          // if (cI) {
                          //   console.log(
                          //     cI.map((q) => q.product_id)
                          //     // .includes(prop.info.id)
                          //   );
                          //   console.log(prop.info.id);
                          //   console.log(
                          //     prop.info.id.includes(cI.map((q) => q.product_id))
                          //   );
                          // }
                          return (
                            <div
                              className="chart col-lg-3 col-12"
                              onClick={() => this.addToCart(prop)}
                            ><div className="statistic d-flex align-items-center bg-white has-shadow">
                                <div className="text">
                                  {cI &&
                                  cI
                                    .map((q) => q.product_id)
                                    .includes(prop.info.id) ? (
                                    <h2>{prop.info.name}</h2>
                                  ) : (
                                    prop.info.name
                                  )}
                                 </div>
                              </div>
                              <br />
                            </div>
                          );
                        })}
                       {a.map((s) => (
                      <div className="chart col-lg-3 col-12">
                        <div
                          className={
                            "statistic d-flex align-items-center bg-white has-shadow"
                          }
                        >
                          <div className="text">
                            <h2>Products name</h2>
                          </div>
                        </div>
                        <br />
                      </div>
                    ))} 
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="row col-4">
                <div className="chart col-lg-12 col-12">
                  <div
                    className={
                      "statistic d-flex align-items-center bg-white has-shadow"
                    }
                  >
                    <div className="text">
                      {cart
                        ? cart !== ([] || null || "" || " " || undefined)
                          ? cart.map((prop, key) => {
                              return (
                                <table>
                                  <tbody key={key}>
                                    <tr>
                                      <td style={styles.tdCartLarge}>
                                         {prop.food_type === "VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.green}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "NON-VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.red}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "BVG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={{ color: "blue" }}
                                          ></i>
                                        ) : null} 
                                        {prop.type === "deal"
                                          ? prop.dealName
                                          : prop.name}
                                      </td>
                                      <td style={styles.tdCart}>
                                        {prop.measure === "None" ||
                                        prop.measure === "NONE"
                                          ? null
                                          : prop.measure}{" "}
                                        {prop.quantity} Qty
                                        <br />
                                        <i
                                          className={CURRENCY}
                                          aria-hidden="true"
                                        ></i>
                                        {prop.type === "deal"
                                          ? prop.dealPrice
                                          : prop.cartTotal}{" "}
                                        {/* (including Tax) 
                                      </td>
                                      {prop.addons !== null
                                        ? prop.addons
                                          ? prop.addons.map((p, index) => (
                                              <td key={index}>
                                                -{p.name},
                                                <br />
                                              </td>
                                            ))
                                          : null
                                        : null}
                                      <td style={styles.tdSmall}>
                                         <Image
                                          key={key}
                                          onClick={() =>
                                            this.removeFromCart(prop)
                                          }
                                          src={deletecartIcon}
                                          style={styles.imgSmall}
                                        ></Image>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              );
                            })
                          : null
                        : null}
                    </div>
                  </div>
                </div>
                 <div className="chart col-lg-12 col-12">
                  <div
                    className={
                      "statistic d-flex align-items-center bg-blue has-shadow"
                    }
                  >
                    <div className="text">
                      {this.state.proSelected === true ? (
                        <Col md={12}>
                          <div>
                            <Image
                              onClick={this.getCart}
                              src={refreshImg}
                              style={styles.marginAll10}
                            ></Image>
                            <Image
                              onClick={this.deleteCart}
                              src={deletecartAll}
                              style={styles.marginAll10}
                            ></Image>
                            <label style={styles.margin}>
                              <input
                                type="radio"
                                id="cash"
                                name="pay"
                                value="cash"
                                onChange={this.onPaytype}
                              />
                              <span style={{ fontSize: "13px" }}>
                                &nbsp; Cash
                              </span>
                            </label>
                            <span> </span>
                            <label>
                              <input
                                type="radio"
                                id="online"
                                name="pay"
                                value="online"
                                onChange={this.onPaytype}
                              />
                              <span style={{ fontSize: "13px" }}>
                                &nbsp; Online&nbsp;
                              </span>
                            </label>
                            <span> </span>
                            <label>
                              <input
                                type="radio"
                                id="swipe"
                                name="pay"
                                value="swipe"
                                onChange={this.onPaytype}
                              />
                              <span style={{ fontSize: "13px" }}>
                                &nbsp; Swipe
                              </span>
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Image
                              onClick={this.onOffer}
                              src={offerImg}
                              style={styles.imgSmall}
                            ></Image>
                            {this.state.pay === "" ? null : this.state.pay ===
                                "cash" &&
                              (Number(this.state.amount) <= 0 ||
                                Number(this.state.amount) === "" ||
                                Number(this.state.amount) -
                                  Math.round(
                                    Number(totalPrice) +
                                      (5 / 100) * Number(totalPrice)
                                  ) <
                                  0) ? null : (
                              <ReactToPrint
                                onBeforeGetContent={this.onOrderVerify}
                                onAfterPrint={this.refreshPage}
                                trigger={() => (
                                  <Image
                                    src={
                                      this.state.orderPlaced === false
                                        ? orderImg
                                        : printerImg
                                    }
                                    style={styles.imgSmall}
                                  ></Image>
                                )}
                                content={() => this.componentRef}
                              />
                            )}
                          </div>
                          <div className="numbers">
                            {isNaN(this.state.cartPrice) ? (
                              <div className="form-group">
                                <span style={{ fontSize: "20px" }}>
                                  Total:
                                  {this.state.cartPrice.is_offer_applied ===
                                  true ? (
                                    <>
                                      {" "}
                                      <del style={{ fontSize: "10px" }}>
                                        {Math.round(
                                          this.state.cartPrice
                                            ? this.state.cartPrice.cartTotal
                                            : 0
                                        )}
                                      </del>{" "}
                                      {Math.round(
                                        this.state.cartPrice.offer_amount
                                      )}
                                      &nbsp;
                                      <small
                                        style={{
                                          color: "green",
                                          fontSize: "12px",
                                        }}
                                      >
                                        (
                                        <strong>
                                          {
                                            this.state.cartPrice
                                              .offer_percentage
                                          }
                                          % OFF
                                        </strong>
                                        )
                                      </small>
                                      &nbsp;&nbsp;
                                    </>
                                  ) : (
                                    Math.round(
                                      this.state.cartPrice
                                        ? this.state.cartPrice.cartTotal
                                        : 0
                                    )
                                  )}
                                </span>{" "}
                                <span> </span>{" "}
                                {payOption === "cash" ? (
                                  <>
                                    {" "}
                                    <input
                                      id="amount"
                                      size="5"
                                      type="text"
                                      name="amount"
                                      required
                                      onChange={this.onFieldChange}
                                      placeholder="amount"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                      }}
                                    />{" "}
                                    <input
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                      }}
                                      id="change"
                                      size="5"
                                      type="text"
                                      name="change"
                                      required
                                      onChange={this.onFieldChange}
                                      placeholder="change"
                                      value={
                                        this.state.amount === ""
                                          ? ""
                                          : this.state.cartPrice
                                              .is_offer_applied === false
                                          ? Number(this.state.amount) -
                                            Math.round(
                                              totalPrice +
                                                (5 / 100) * totalPrice
                                            )
                                          : Number(this.state.amount) -
                                            Math.round(
                                              this.state.cartPrice.offer_amount
                                            )
                                      }
                                      readOnly
                                    />
                                  </>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <div className="content">
          <Table>
            <tbody>
              <tr>
                {categories
                  ? categories !== [] || categories !== null
                    ? categories.map((prop, index) => {
                        return index <= 9 ? (
                          <td
                            className="font-icon-list"
                            key={index}
                            onClick={() => this.onCat(prop)}
                          >
                            {prop.image !== "" ? (
                              <Image
                                src={
                                  clientHost.split("/api")[0] +
                                  `/` +
                                  IMG_PATH_CATEGORY +
                                  `/` +
                                  prop.id +
                                  `/` +
                                  prop.image
                                }
                                alt={prop.name}
                                style={styles.imageAdd}
                              />
                            ) : null}
                            <br />
                            {prop.id === this.state.catId ? (
                              <strong>{prop.name}</strong>
                            ) : (
                              prop.name
                            )}
                          </td>
                        ) : null;
                      })
                    : null
                  : null}
              </tr>
              <tr>
                {categories
                  ? categories !== [] || categories !== null
                    ? categories.map((prop, index) => {
                        return index > 9 ? (
                          <td
                            className="font-icon-list"
                            key={index}
                            onClick={() => this.onCat(prop)}
                          >
                            {prop.image !== "" ? (
                              <Image
                                src={
                                  clientHost.split("/api")[0] +
                                  `/` +
                                  IMG_PATH_CATEGORY +
                                  `/` +
                                  prop.id +
                                  `/` +
                                  prop.image
                                }
                                alt={prop.name}
                                style={styles.imageAdd}
                              />
                            ) : null}
                            <br />
                            {prop.id === this.state.catId ? (
                              <strong>{prop.name}</strong>
                            ) : (
                              prop.name
                            )}
                          </td>
                        ) : null;
                      })
                    : null
                  : null}
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          <Grid fluid>
            <Row>
              {this.state.search === true ? (
                <Col md={12}>
                  <Card
                    title="Products"
                    ctTableResponsive
                    content={
                      <div className="search-box">
                        <input
                          onChange={(e) => this.searchInitiate(e.target.value)}
                          type="search"
                          placeholder="Enter Product Name..."
                          className="form-control"
                          name="searchValue"
                          value={this.state.searchValue}
                        />
                      </div>
                    }
                  />
                </Col>
              ) : null}
            </Row>
          </Grid>
        </div>
        <div>
          <Grid fluid>
            <Row>
              {this.state.catSelected === true ||
              this.state.searchValue !== "" ? (
                <Col md={6}>
                  <Card
                    title="Products"
                    ctTableResponsive
                    content={
                      <Table striped hover>
                        <tbody>
                          {this.state.filteredProducts
                            .filter(
                              (p) =>
                                (p.info.is_deleted === "N" ||
                                  p.info.status === "1") &&
                                p.prices !== null &&
                                this.state.storeIdSet === storeIdValue
                            )
                            .map((prop, key) => {
                              return (
                                <tr key={key}>
                                  <td>
                                    {prop.info.food_type === "VEG" ? (
                                      <i
                                        className="fa fa-dot-circle-o"
                                        style={styles.green}
                                      ></i>
                                    ) : null}
                                    {prop.info.food_type === "NON-VEG" ? (
                                      <i
                                        className="fa fa-dot-circle-o"
                                        style={styles.red}
                                      ></i>
                                    ) : null}
                                    {prop.info.food_type === "BVG" ? (
                                      <i
                                        className="fa fa-dot-circle-o"
                                        style={{ color: "blue" }}
                                      ></i>
                                    ) : null}
                                  </td>
                                  <td style={styles.tdCartLarge}>
                                    {prop.info.name}
                                    <br />
                                    {prop.prices
                                      ? prop.prices.length > 1
                                        ? prop.prices.map((p, index) => (
                                            <label
                                              key={index}
                                              style={styles.margin}
                                            >
                                              <input
                                                radio-inline="true"
                                                style={styles.margin}
                                                type="radio"
                                                id={p.measure}
                                                name={p.product_id}
                                                value={p.price_id}
                                                onChange={() =>
                                                  this.onRadioChanged(p)
                                                }
                                              />
                                              {p.quantity}&nbsp;{p.measure}{" "}
                                              <i
                                                className={CURRENCY}
                                                aria-hidden="true"
                                              ></i>
                                              {p.offer_price !== "0" ? (
                                                <strong>{p.offer_price}</strong>
                                              ) : (
                                                <strong>{p.price}</strong>
                                              )}
                                            </label>
                                          ))
                                        : prop.prices.map((p, index) => (
                                            <div key={index}>
                                              {p.measure === "None" ||
                                              "NONE" ||
                                              "none" ? (
                                                <>
                                                  {p.quantity}&nbsp;{p.measure}
                                                </>
                                              ) : null}{" "}
                                              <i
                                                className={CURRENCY}
                                                aria-hidden="true"
                                              ></i>
                                              {p.offer_price !== "0" ? (
                                                <strong>{p.offer_price}</strong>
                                              ) : (
                                                <strong>{p.price}</strong>
                                              )}
                                            </div>
                                          ))
                                      : null}
                                  </td>
                                  <td>
                                    <select
                                      onChange={this.numberOfItems}
                                      defaultValue={"1"}
                                    >
                                      <option value="1">01</option>
                                      <option value="2">02</option>
                                      <option value="3">03</option>
                                      <option value="4">04</option>
                                      <option value="5">05</option>
                                    </select>
                                  </td>
                                  <td>
                                    {this.state.addonStatus === "NO" ? (
                                      <Image
                                        key={key}
                                        roundedcircle="true"
                                        src={imgsrc}
                                        style={styles.imageMenu}
                                        onClick={() => this.onProduct(prop)}
                                      ></Image>
                                    ) : (
                                      <Image
                                        roundedcircle="true"
                                        src={imgsrc}
                                        style={styles.imageMenu}
                                        key={key}
                                        onClick={() => this.addToCart(prop)}
                                      ></Image>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    }
                  />
                </Col>
              ) : null}
              {this.state.proSelected === true ? (
                <Col md={6}>
                  <div>
                    <Image
                      onClick={this.getCart}
                      src={refreshImg}
                      style={styles.marginAll10}
                    ></Image>
                    <Image
                      onClick={this.deleteCart}
                      src={deletecartAll}
                      style={styles.marginAll10}
                    ></Image>
                    <label style={styles.margin}>
                      <input
                        type="radio"
                        id="cash"
                        name="pay"
                        value="cash"
                        onChange={this.onPaytype}
                      />
                      <span style={{ fontSize: "13px" }}>&nbsp; Cash</span>
                    </label>
                    <span> </span>
                    <label>
                      <input
                        type="radio"
                        id="online"
                        name="pay"
                        value="online"
                        onChange={this.onPaytype}
                      />
                      <span style={{ fontSize: "13px" }}>
                        &nbsp; Online&nbsp;
                      </span>
                    </label>
                    <span> </span>
                    <label>
                      <input
                        type="radio"
                        id="swipe"
                        name="pay"
                        value="swipe"
                        onChange={this.onPaytype}
                      />
                      <span style={{ fontSize: "13px" }}>&nbsp; Swipe</span>
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Image
                      onClick={this.onOffer}
                      src={offerImg}
                      style={styles.imgSmall}
                    ></Image>
                    {this.state.pay === "" ? null : this.state.pay === "cash" &&
                      (Number(this.state.amount) <= 0 ||
                        Number(this.state.amount) === "" ||
                        Number(this.state.amount) -
                          Math.round(
                            Number(totalPrice) + (5 / 100) * Number(totalPrice)
                          ) <
                          0) ? null : (
                      <ReactToPrint
                        onBeforeGetContent={this.onOrderVerify}
                        onAfterPrint={this.refreshPage}
                        trigger={() => (
                          <Image
                            src={
                              this.state.orderPlaced === false
                                ? orderImg
                                : printerImg
                            }
                            style={styles.imgSmall}
                          ></Image>
                        )}
                        content={() => this.componentRef}
                      />
                    )}
                  </div>
                  <div className="numbers">
                    {isNaN(this.state.cartPrice) ? (
                      <div className="form-group">
                        <span style={{ fontSize: "20px" }}>
                          Total:
                          {this.state.cartPrice.is_offer_applied === true ? (
                            <>
                              {" "}
                              <del style={{ fontSize: "10px" }}>
                                {Math.round(
                                  this.state.cartPrice
                                    ? this.state.cartPrice.cartTotal
                                    : 0
                                )}
                              </del>{" "}
                              {Math.round(this.state.cartPrice.offer_amount)}
                              &nbsp;
                              <small
                                style={{ color: "green", fontSize: "12px" }}
                              >
                                (
                                <strong>
                                  {this.state.cartPrice.offer_percentage}% OFF
                                </strong>
                                )
                              </small>
                              &nbsp;&nbsp;
                            </>
                          ) : (
                            Math.round(
                              this.state.cartPrice
                                ? this.state.cartPrice.cartTotal
                                : 0
                            )
                          )}
                        </span>{" "}
                        <span> </span>{" "}
                        {payOption === "cash" ? (
                          <>
                            {" "}
                            <input
                              id="amount"
                              size="5"
                              type="text"
                              name="amount"
                              required
                              onChange={this.onFieldChange}
                              placeholder="amount"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            />{" "}
                            <input
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                              id="change"
                              size="5"
                              type="text"
                              name="change"
                              required
                              onChange={this.onFieldChange}
                              placeholder="change"
                              value={
                                this.state.amount === ""
                                  ? ""
                                  : this.state.cartPrice.is_offer_applied ===
                                    false
                                  ? Number(this.state.amount) -
                                    Math.round(
                                      totalPrice + (5 / 100) * totalPrice
                                    )
                                  : Number(this.state.amount) -
                                    Math.round(
                                      this.state.cartPrice.offer_amount
                                    )
                              }
                              readOnly
                            />
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <Card
                    title="Cart"
                    ctTableResponsive
                    content={
                      <Table striped hover>
                        {cart
                          ? cart !== ([] || null || "" || " " || undefined)
                            ? cart.map((prop, key) => {
                                return (
                                  <tbody key={key}>
                                    <tr>
                                      <td style={styles.tdCartLarge}>
                                        {prop.food_type === "VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.green}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "NON-VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.red}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "BVG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={{ color: "blue" }}
                                          ></i>
                                        ) : null}
                                        {prop.type === "deal"
                                          ? prop.dealName
                                          : prop.name}
                                      </td>
                                      <td style={styles.tdCart}>
                                        {prop.measure === "None" ||
                                        prop.measure === "NONE"
                                          ? null
                                          : prop.measure}{" "}
                                        {prop.quantity} Qty
                                        <br />
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"
                                        ></i>
                                        {prop.type === "deal"
                                          ? prop.dealPrice
                                          : prop.cartTotal}{" "}
                                        (including Tax)
                                      </td>
                                      {prop.addons !== null
                                        ? prop.addons
                                          ? prop.addons.map((p, index) => (
                                              <td key={index}>
                                                -{p.name},
                                                <br />
                                              </td>
                                            ))
                                          : null
                                        : null}
                                      <td style={styles.tdSmall}>
                                        <Image
                                          key={key}
                                          onClick={() =>
                                            this.removeFromCart(prop)
                                          }
                                          src={deletecartIcon}
                                          style={styles.imgSmall}
                                        ></Image>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            : null
                          : null}
                      </Table>
                    }
                  />
                </Col>
              ) : null}
            </Row>
          </Grid>
        </div> */}
        <div style={{ display: "none" }}>
          <div ref={(el) => (this.componentRef = el)}>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>{storeName}</th>
                </tr>
                {storeAddress ? (
                  <>
                    <tr>
                      <th style={{ textAlign: "center" }}>
                        {storeAddress.split(",")[0]},{" "}
                        {storeAddress.split(",")[1]},
                      </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }}>
                        {storeAddress.split(",")[2]},{" "}
                        {storeAddress.split(",")[3]},
                      </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }}>
                        {storeAddress.split(",")[4]},{" "}
                        {storeAddress.split(",")[5]}
                      </th>
                    </tr>
                  </>
                ) : null}
                <tr>
                  <td>--------------------------</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Bill No:
                    {this.state.bill_no}
                  </td>
                </tr>
                <tr>
                  <td>
                    Order No:
                    {isNaN(this.state.totalOrders)
                      ? null
                      : Number(this.state.totalOrders) + 1}
                  </td>
                </tr>

                <tr>
                  <td>Date:{this.state.date}</td>
                </tr>
                <tr>
                  <td>--------------------------</td>
                </tr>
                <tr>
                  <th>
                    Menu&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Qty&nbsp;Rate&nbsp;Amt
                  </th>
                </tr>
                <tr>
                  <td>--------------------------</td>
                </tr>
                {this.state.cart
                  ? this.state.cart.map((p, index) => (
                      <>
                        <tr key={index}>
                          {p.type === "deal" ? (
                            p.dealName ? (
                              <td>
                                {p.dealName.length > 14 ? (
                                  <>{p.dealName.substring(0, 14)}</>
                                ) : null}{" "}
                                {p.dealName.length === 13 ? (
                                  <>{p.dealName}</>
                                ) : null}{" "}
                                {p.dealName.length === 12 ? (
                                  <>{p.name}&nbsp;</>
                                ) : null}{" "}
                                {p.dealName.length === 11 ? (
                                  <>
                                    {p.dealName}
                                    &nbsp;&nbsp;
                                  </>
                                ) : null}{" "}
                                {p.dealName.length === 10 ? (
                                  <>
                                    {p.dealName}
                                    &nbsp;&nbsp;&nbsp;
                                  </>
                                ) : null}{" "}
                                {p.dealName.length === 9 ? (
                                  <>
                                    {p.dealName}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                  </>
                                ) : null}{" "}
                                {p.dealName.length === 8 ? (
                                  <>
                                    {p.dealName}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </>
                                ) : null}{" "}
                                {p.dealName.length < 7 ? (
                                  <>
                                    {p.dealName}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </>
                                ) : null}{" "}
                                {p.quantity}&nbsp;
                                {Number(p.dealPrice).toFixed(2)}&nbsp;
                                {Number(p.quantity * p.dealPrice).toFixed(2)}
                              </td>
                            ) : null
                          ) : null}
                          {p.name ? (
                            <td>
                              {p.name.length > 15 ? (
                                <>{p.name.substring(0, 15)}</>
                              ) : null}
                              {p.name.length === 14 ? <>{p.name}</> : null}{" "}
                              {p.name.length === 13 ? <>{p.name}</> : null}{" "}
                              {p.name.length === 12 ? (
                                <>{p.name}&nbsp;</>
                              ) : null}{" "}
                              {p.name.length === 11 ? (
                                <>
                                  {p.name}
                                  &nbsp;&nbsp;
                                </>
                              ) : null}{" "}
                              {p.name.length === 10 ? (
                                <>
                                  {p.name}
                                  &nbsp;&nbsp;&nbsp;
                                </>
                              ) : null}{" "}
                              {p.name.length === 9 ? (
                                <>
                                  {p.name}
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                              ) : null}{" "}
                              {p.name.length === 8 ? (
                                <>
                                  {p.name}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                              ) : null}{" "}
                              {p.name.length < 7 ? (
                                <>
                                  {p.name}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                              ) : null}{" "}
                              {/* {p.name.length === 6 ? (
                                <>
                                  {p.name}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                              ) : null}{" "} */}
                              &nbsp;&nbsp;{p.quantity}&nbsp;
                              {p.type === "deal"
                                ? Number(p.dealPrice).toFixed(2)
                                : Number(p.cartTotal).toFixed(2)}
                              &nbsp;&nbsp;
                              {Number(p.quantity * p.itemPrice).toFixed(2)}
                            </td>
                          ) : null}
                        </tr>
                        <tr>
                          <td>
                            {p.measure === "None" || p.measure === "NONE"
                              ? null
                              : p.measure}{" "}
                          </td>
                        </tr>
                        {p.addons
                          ? p.addons !== [] || null
                            ? p.addons.map((r, index) => (
                                <tr key={index}>
                                  <td>
                                    &nbsp;-
                                    {r.name.length > 10 ? (
                                      <>{r.name.substring(0, 9)}...</>
                                    ) : (
                                      <>{r.name} </>
                                    )}
                                    {p.quantity}
                                  </td>
                                </tr>
                              ))
                            : null
                          : null}
                      </>
                    ))
                  : null}
                <tr>
                  <td>&nbsp;</td>
                </tr>

                <tr>
                  <td>
                    SubTotal:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {Number(totalPrice).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>--------------------------</td>
                </tr>
                <tr>
                  <td>
                    &nbsp;&nbsp;VAT 20%&nbsp;&nbsp;&nbsp;&nbsp;
                    {Math.round((20 / 100) * totalPrice * 100) / 100}
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    &nbsp;&nbsp;CGST 2.5%&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                    {Math.round((2.5 / 100) * totalPrice * 100) / 100}
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;&nbsp;SGST 2.5%&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                    {Math.round((2.5 / 100) * totalPrice * 100) / 100}
                  </td>
                </tr> */}
                {/* <tr>
                  <td>--------------------------</td>
                </tr>
                <tr>
                   <td>
                    Total Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className={CURRENCY} aria-hidden="true"></i>
                    {(
                      Number(this.state.cartPrice.cartTotal * 100).toFixed(2) /
                      100
                    ).toFixed(2)}
                  </td> 
                </tr> */}
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <strong>
                      PAY:
                      <i className={CURRENCY} aria-hidden="true"></i>
                      {this.state.cartPrice === true ? (
                        <>
                          {" "}
                          <del>
                            {" "}
                            {Number(
                              this.state.cartPrice
                                ? this.state.cartPrice.cartTotal
                                : 0
                            ).toFixed(2)}
                          </del>{" "}
                          {Number(this.state.cartPrice.offer_amount).toFixed(2)}
                          <small
                            style={{
                              color: "green",
                              fontSize: "12px",
                            }}
                          >
                            {this.state.cartPrice.offer_percentage} % off
                          </small>
                        </>
                      ) : (
                        Number(
                          this.state.cartPrice
                            ? (
                                Number(
                                  this.state.cartPrice.cartTotal * 100
                                ).toFixed(2) / 100
                              ).toFixed(2)
                            : 0
                        ).toFixed(2)
                      )}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>--------------------------</td>
                </tr>
                {/* <tr style={{ textAlign: "center" }}>
                  <td>GSTIN : {gst}</td>
                </tr> */}
                <tr style={{ textAlign: "center" }}>
                  <td>Thank you, visit again</td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>Please check your belongings</td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>before you leave</td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>Powered by - TreoSoft IT</td>
                </tr>
              </tbody>
            </table>
            <table id="kots">
              <thead>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    Bill No:
                    {this.state.bill_no}
                  </td>
                </tr>
                <tr>
                  <td>Date:{this.state.date}</td>
                </tr>
                <tr>
                  <td>--------------------------</td>
                </tr>
                <tr>
                  <th>
                    Kots.{" "}
                    {isNaN(this.state.totalOrders)
                      ? null
                      : Number(this.state.totalOrders) + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;{this.state.takeAwayType}
                  </th>
                </tr>
                <tr>
                  <td>--------------------------</td>
                </tr>
              </thead>
              <tbody>
                {this.state.cart
                  ? this.state.cart.map((p, index) => (
                      <>
                        <tr key={index}>
                          <td>
                            {p.type === "deal" ? p.dealName : p.name} -
                            {p.quantity}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {p.measure === "None" || p.measure === "NONE"
                              ? null
                              : p.measure}{" "}
                          </td>
                        </tr>
                        {p.addons
                          ? p.addons !== [] || null
                            ? p.addons.map((r, index) => (
                                <tr key={index}>
                                  <td>
                                    &nbsp;-
                                    {r.name} {p.quantity}&nbsp;&nbsp;
                                  </td>
                                </tr>
                              ))
                            : null
                          : null}
                      </>
                    ))
                  : null}
                <tr>
                  <td>--------------------------</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={6}>
                {product && (
                  <Modal
                    isOpen={true}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    className="Modal"
                    scrollable={true}
                  >
                    <Zoom scrollable={false}>
                      <Card
                        title={product.info.name}
                        content={
                          <>
                            <div className="modal-header ">
                              <Button
                                bsStyle="info"
                                type="submit"
                                onClick={() => this.addToCart(product)}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Skip
                                </span>
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                bsStyle="info"
                                type="submit"
                                onClick={() => this.checkAddons(product)}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Add n continue
                                </span>
                              </Button>
                            </div>

                            <div className="table-full-width" id="modal-body">
                              <table className="table">
                                <tbody>
                                  {addons
                                    ? addons.map((p, index) => (
                                        <tr key={index}>
                                          <td>
                                            <button
                                              className="btn btn-info"
                                              onClick={(e) => {
                                                const ele = e.target;
                                                const classList = ele.classList;
                                                const isClicked =
                                                  classList.contains(
                                                    "isClicked"
                                                  );
                                                if (isClicked) {
                                                  classList.remove("isClicked");
                                                } else {
                                                  classList.add("isClicked");
                                                }
                                                this.handleAddon(p, product);

                                                // console.log(typeof classList)
                                                // console.log(classList.contains('isClicked'))
                                              }}
                                            >
                                              {/* <input
                                                style={{
                                                  fontsize: "50px"
                                                }}
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleAddon(p, product)
                                                }
                                              /> */}
                                              {p.name}, {p.price_regular}
                                            </button>{" "}
                                          </td>
                                          {/* <td>-------------------------------------------------------------------------------</td> */}

                                          {/* </div> */}
                                          {/* <td>{p.food_type}</td> */}
                                          {/* {product.prices ? (
                                            product.prices.length > 1 ? (
                                              product.prices
                                                .filter(
                                                  (q) =>
                                                    q.id === this.state.priceID
                                                )
                                                .map((r, index) => (
                                                  <td key={index}
                                                  className="btn btn-info"
                                                  >
                                                    {r.measure === "Regular"
                                                      ? p.price_regular
                                                      : p.price_medium}
                                                  </td>
                                                ))
                                            ) : (
                                              <td>{p.price_regular}</td>
                                            )
                                          ) : null} */}
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </>
                        }
                      />
                    </Zoom>
                  </Modal>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={6}>
                {offer && (
                  <Modal
                    isOpen={true}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    className="Modal"
                    scrollable={true}
                  >
                    <Zoom>
                      <Card
                        content={
                          <>
                            <div className="modal-header">
                              <Button
                                bsStyle="info"
                                type="submit"
                                onClick={this.closeModal}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Skip
                                </span>
                              </Button>
                              &nbsp;&nbsp;
                              <Button bsStyle="info" type="submit">
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                  onClick={this.onhandleOffer}
                                >
                                  Apply and continue
                                </span>
                              </Button>
                            </div>
                            <div className="table-full-width" id="modal-body">
                              <table className="table">
                                <tbody>
                                  {this.state.offers
                                    ? this.state.offers.map((p, index) => (
                                        <tr key={index}>
                                          <td>
                                            <button
                                              className="btn btn-info"
                                              onClick={(e) => {
                                                const ele = e.target;
                                                const classList = ele.classList;
                                                const isClicked =
                                                  classList.contains(
                                                    "isClicked"
                                                  );
                                                if (isClicked) {
                                                  classList.remove("isClicked");
                                                } else {
                                                  classList.add("isClicked");
                                                }

                                                this.handleAddon(p, product);
                                                // console.log(typeof classList)
                                                // console.log(classList.contains('isClicked'))
                                              }}
                                            >
                                              {/* <input
                                                style={{
                                                  fontsize: "50px"
                                                }}
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleAddon(p, product)
                                                }
                                              /> */}
                                              {p.title}
                                            </button>{" "}
                                          </td>

                                          <td>{p.description}</td>
                                          <td>{p.offer_type}</td>
                                          <td>{p.offer_percentage}%</td>
                                          {offer.id}
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </>
                        }
                      />
                    </Zoom>
                  </Modal>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
  tillOrders = () => {
    var apiPath = new Uri(clientHost + MISC).addQueryParam(
      "store_id",
      storeFresh.storeId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          bill_no: Number(
            onSuccessResponse.response
              .filter(
                (q) => q.range === "Orders" && q.store_id === storeFresh.storeId
              )
              .map((p) => p.OrdersCount)
          ),
        });
      },
      (onError) => console.log(onError.message)
    );
  };

  getAddOn = (catId) => {
    var apiPath3 = new Uri(
      clientHost + `addon/addonsByStore?category_id=${catId}`
    );

    getResponseClient(
      apiPath3,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          addOns: onSuccessResponse.result,
          orderPlaced: false,
        });
      },
      (onError) => console.log(onError.message)
    );
    // console.log('getAddOn', this.state);
  };

  // let selectedCatId;
  async componentDidMount() {
    // this.getStoreDetails(storeIdValue, storeUserId);
    this.getDate();
    this.tillOrders();

    var apiPath = new Uri(clientHost + CATEGORY_LIST).addQueryParam(
      "store_id",
      storeFresh.storeId
    );

    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        let storeIdSet = onSuccessResponse.result.map((q) => q)[0].store_id;
        selectedCatId = onSuccessResponse.result.map((q) => q)[0].id;
        // console.log();
        this.setState({
          categories: onSuccessResponse.result.filter(
            (q) => q.store_id === storeFresh.storeId
          ),
          orderPlaced: false,
          storeIdSet: storeIdSet,
          selectedCatId: onSuccessResponse.result.map((q) => q)[0].id,
        });
        console.log(this.state);
        this.getAddOn(onSuccessResponse.result.map((q) => q)[0].id);
      },
      (onError) => console.log(onError.message)
    );

    var apiPath2 = new Uri(clientHost + MENU_LIST);
    getResponseClient(
      apiPath2,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          products: onSuccessResponse.result,
          orderPlaced: false,
          filteredProducts: onSuccessResponse.result,
        });
      },
      (onError) => console.log(onError.message)
    );

    // getAddOn

    // var apiPath3 = new Uri(
    //   clientHost + `addon/addonsByStore?category_id=${this.state.selectedCatId}`
    // );
    // getResponseClient(
    //   apiPath3,
    //   storeFresh.key,
    //   (onSuccessResponse) => {
    //     this.setState({
    //       addOns: onSuccessResponse.result,
    //       orderPlaced: false,
    //     });

    //   },
    //   (onError) => console.log(onError.message)
    // );
    this.getOrderDetails();
    this.getOffers();
  }
}
export default TakeOrder0;
