import API from "./API";

function getResponse(url, onSuccess, onError) {
  API.get(url)
    .then((res) => {
      if (onSuccess != null) {
        onSuccess(res.data);
      }
    })
    .catch((err) => {
      if (onError != null) {
        onError(err);
      }
    });
}

export default getResponse;
