import React from "react";
import AppVersionsComponent from "./AppVersionsComponent";

class AppVersionsContainer extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <AppVersionsComponent />
      </div>
    );
  }
}
export default AppVersionsContainer;
