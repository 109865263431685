import React from "react";
import { PINCODE_LIST } from "../../Network/ApiPaths";
import PincodesComponent from "./PincodesComponent";
import getResponse from "../../Network/GetDataResponse";
import Uri from "../../Network/Uri";
class PincodesContainer extends React.Component {
  constructor(props) {
    super();
    this.state = { pincodes: [] };
  }
  render() {
    return (
      <div>
        <br />
        <PincodesComponent data={this.state.pincodes} />
      </div>
    );
  }
  async componentDidMount() {
    var apiPath = new Uri(PINCODE_LIST);
    getResponse(
      apiPath,
      (onSuccessResponse) => {
        this.setState({
          pincodes: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}
export default PincodesContainer;
