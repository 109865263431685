import React from "react";
// import { Col, Row } from "react-bootstrap";
import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
// import Card from "../Card/Card";

const TodaysReportComponent = (props) => (
  <section className="feeds no-padding-top">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8">
          <div className="line-chart-example card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Today Orders</h3>
              <input
                //  style={{width:"30%",postion:"absolute;",float:"right"}}
                type="date"
                className="form-control"
                // defaultValue={props.date}
                value={props.HourlySalesDate}
                name="getSalesByDate"
                max={props.date}
                onChange={props.getHourlySalesByDate}
              />
            </div>
            <div className="card-body">
              <Chart2 chartData={props.HourlyReport} />
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="line-chart-example card">
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">Today Amount</h3>
              <input
                type="date"
                className="form-control"
                // defaultValue={props.date}
                name="getSalesByDate"
                value={props.HourlySalesDate}
                max={props.date}
                onChange={props.getHourlySalesByDate}
              />
            </div>
            <div className="card-body">
              <Chart1 chartData={props.HourlyReport} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default TodaysReportComponent;
