import React from "react";
import StoreDetailsComponent from "./StoreDetailsComponent";
// import {
//   STORE_LIST_DETAILS_13,
//   STORE_LIST_DETAILS_14,
//   STORE_LIST_DETAILS_15,
// } from "../../Network/ApiPaths";
// import getResponse from "../../Network/GetDataResponse";
// import Uri from "../../Network/Uri";
class StoreDetailsContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      SID: props.sID,
      StoreDetails: [],
    };
  }

  render() {
    return (
      <div>
        <StoreDetailsComponent
          goToStores={this.props.goToStores}
          SID={this.state.SID}
          StoreDetails={this.state.StoreDetails}
        />
      </div>
    );
  }
  async componentDidMount() {
    // let apiPath;
    // if (this.state.SID === 13) {
    //   apiPath = new Uri(STORE_LIST_DETAILS_13);
    // } else if (this.state.SID === 14) {
    //   apiPath = new Uri(STORE_LIST_DETAILS_14);
    // } else {
    //   apiPath = new Uri(STORE_LIST_DETAILS_15);
    // }
    // getResponse(
    //   apiPath,
    //   (onSuccessResponse) => {
    //     this.setState({
    //       StoreDetails: onSuccessResponse.result,
    //     });
    //   },
    //   (onError) => {
    //     console.log(onError.message);
    //   }
    // );
  }
}
export default StoreDetailsContainer;
