import React from "react";
import UsersComponent from "./UsersComponent";
import { USER_LIST } from "../../Network/ApiPaths";
import getResponse from "../../Network/GetDataResponse";
import Uri from "../../Network/Uri";
class UsersContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      appUsers: [],
    };
  }
  render() {
    return (
      <div>
        <UsersComponent data={this.state.appUsers} />
      </div>
    );
  }
  async componentDidMount() {
    var apiPath = new Uri(USER_LIST);
    getResponse(
      apiPath,
      (onSuccessResponse) => {
        console.log(onSuccessResponse.result);
        this.setState({
          appUsers: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}
export default UsersContainer;
