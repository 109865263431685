import React from "react";
import BannerComponent from "./BannerComponent";

class BannerContainer extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <BannerComponent />
      </div>
    );
  }
}
export default BannerContainer;
