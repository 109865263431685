import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../Card/Card";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CURRENCY } from "../../Network/ApiPaths";
let storeFresh;
//let storeUserId;
// let storeIdValue;
let clientHost;
let storeDet;
let storeAddress;
let storeName;
function seperateOrdersByDate(orders) {
  const datesArray = orders.map((order) =>
    order.OrderDate ? order.OrderDate.split(" ")[0] : null
  );
  const dateSet = new Set(datesArray);
  const uniqueDates = Array.from(dateSet);
  const seperatedOrder = [];
  uniqueDates.forEach((date) => {
    const item = {
      date,
      orders: orders.filter((order) => {
        return order.OrderDate ? order.OrderDate.split(" ")[0] === date : null;
      }),
    };
    seperatedOrder.push(item);
  });
  return seperatedOrder;
}
// function seperateItemsByCategory(categories) {
//   const categoryArray = categories.map((category) => category.CategoryName);
//   const categorySet = new Set(categoryArray);
//   const uniqueCategories = Array.from(categorySet);
//   const seperatedCategory = [];
//   uniqueCategories.forEach((CategoryName) => {
//     const items = {
//       CategoryName,
//       categories: categories.filter((category) => {
//         return category.CategoryName === CategoryName;
//       }),
//     };
//     seperatedCategory.push(items);
//   });
//   return seperatedCategory;
// }
class BillReportsComponent extends React.Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeAddress = storeDet.address;
    } else {
      storeAddress = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      // storeName = storeFresh.storeName;
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
  }
  pdfCheck = () => {
    // alert("hgfdfghj");
    if (this.props.salesReport) {
      const doc = new jsPDF();
      doc.autoTable({ html: "#my-table1" });
      doc.save(
        `${storeName}_billwiseReport_${
          this.props.reportsDate === ""
            ? this.props.date
            : this.props.reportsDate
        }to${
          this.props.reportsDate2 === ""
            ? this.props.date
            : this.props.reportsDate2
        }.pdf`
      );
    } else {
      alert("No data");
    }
  };
  render() {
    return (
      <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <div
                    id="closeCard4"
                    aria-haspopup="true"
                    className="dropdown-toggle"
                  >
                    {/* <i className="fa fa-ellipsis-v"></i> */}
                    <Col md={3}>
                      <Card
                        ctAllIcons
                        content={
                          <Row style={{ position: "absolute", right: "0px" }}>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getReportsByDate"
                              max={this.props.date}
                              onChange={this.props.getReportsByDate}
                              style={{
                                position: "relative",
                                right: "105%",
                                bottom: "5px",
                              }}
                            />
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getReportsByDate2"
                              max={this.props.date}
                              style={{ position: "relative", bottom: "42px" }}
                              onChange={this.props.getReportsByDate2}
                            />
                          </Row>
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Bill Wise Reports</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {/* <div style={{ display: "none" }}> */}
                  <div style={{ height: "400px", overflow: "auto" }}>
                    <table
                      className="table table-striped table-sm"
                      id="my-table1"
                    >
                      <thead>
                        <tr>
                          <th>Order No</th>
                          <th>Order Date</th>
                          <th style={{ textAlign: "center" }}>PayType</th>
                          <th style={{ textAlign: "center" }}>Amount</th>
                          <th style={{ textAlign: "center" }}>VAT</th>
                          {/* <th style={{ textAlign: "center" }}>SGST</th> */}
                          <th style={{ textAlign: "center" }}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.salesReport ? (
                          this.props.salesReport.map((p, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{p.Order_No}</td>
                                  {/* <td>{p.OrderDate.split(" ")[0]}</td> */}
                                  <td>
                                    {p.OrderDate
                                      ? p.OrderDate.split(" ")[0]
                                      : null}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.payment_mode}&nbsp;
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {p.My_Amount}&nbsp;
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {" "}
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {Number(p.CGST).toFixed(2)}
                                  </td>
                                  {/* <td style={{ textAlign: "center" }}>
                                    {" "}
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {Number(p.SGST).toFixed(2)}
                                  </td> */}
                                  <td style={{ textAlign: "center" }}>
                                    {" "}
                                    {p.Total_Amount}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                padding: "50px",
                                position: "relative",
                                paddingTop: "35%",
                                right: "-70%",
                              }}
                            >
                              <h1 style={{ fontSize: "35px" }}> No Data </h1>
                            </div>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                {this.props.salesReport ? (
                  <>
                    {clientHost ? (
                      <a
                        style={{
                          position: "absolute",
                          right: "55%",
                          top: "3%",
                          color: "black",
                        }}
                        href={
                          clientHost.split("/backend/api")[0] +
                          // `/reports/Report/dailySale`
                          `/reports/Report/getBillReport?store_id=${
                            storeFresh.storeId
                          }&startDate=${
                            this.props.reportsDate
                              ? this.props.reportsDate
                              : this.props.date
                          }&endDate=${
                            this.props.reportsDate2
                              ? this.props.reportsDate2
                              : this.props.date
                          }`
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    ) : null}
                  </>
                ) : null}
              </div>
              {/* <div ref={(el) => (this.componentRef = el)}>*/}
              <div style={{ display: "none" }}>
                <div ref={(el) => (this.componentRef1 = el)}>
                  <table
                    id="my-table1"
                    style={{ position: "relative", left: "12%" }}
                    // style={{ position: "relative", right: "-5%" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ position: "relative", left: "35%" }}>
                          {storeName}
                        </th>
                      </tr>
                    </thead>
                    {storeAddress ? (
                      <tbody>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[1]},{" "}
                            {storeAddress.split(",")[2]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[3]},{" "}
                            {storeAddress.split(",")[4]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[5]}
                          </th>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>
                  {/* <br/>  */}
                  <table style={{ position: "relative", left: "12%" }}>
                    <tbody style={{ float: "left" }}>
                      <tr>
                        <td>
                          <strong>
                            {this.props.reportsDate === ""
                              ? this.props.date
                              : this.props.reportsDate}
                          </strong>
                          {" to "}{" "}
                          <strong>
                            {this.props.reportsDate2 === ""
                              ? this.props.date
                              : this.props.reportsDate2}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  {/* <tr>
                                <td>--------------------</td>
                              </tr> */}
                  <table>
                    <tbody>
                      {/* <tr>
                                <td>--------------------</td>
                              </tr> */}
                      <tr>
                        {/* <th>
                                  Items&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Size&nbsp;Amt
                                </th> */}
                        <th>Order No</th>
                        <th style={{ textAlign: "center" }}>PayType</th>
                        <th style={{ textAlign: "center" }}>Amount</th>
                        <th style={{ textAlign: "center" }}>VAT</th>
                        {/* <th style={{ textAlign: "center" }}>SGST</th> */}
                        <th style={{ textAlign: "center" }}>Total</th>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {this.props.salesReport
                        ? seperateOrdersByDate(this.props.salesReport).map(
                            ({ date, orders }, index) => {
                              return (
                                <>
                                  <tr>
                                    {date ? (
                                      <th>
                                        {date.split("-")[0]}-
                                        {date.split("-")[1]}-
                                        {date.split("-")[2]}
                                      </th>
                                    ) : null}
                                  </tr>
                                  {orders.map((order) => (
                                    <tr>
                                      <td>{order.Order_No}</td>
                                      <td style={{ textAlign: "center" }}>
                                        {order.payment_mode}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {order.My_Amount}
                                      </td>
                                      {/* <td style={{ textAlign: "center" }}>
                                        {Number(order.CGST).toFixed(2)}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {Number(order.SGST).toFixed(2)}
                                      </td> */}
                                      <td style={{ textAlign: "center" }}>
                                        {order.Total_Amount}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              );
                            }
                          )
                        : null}
                    </tbody>

                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    {this.props.ordersData
                      ? this.props.ordersData.map((q, index) => (
                          <tbody>
                            <tr>
                              <th>OrdersCount</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {q.OrdersCount}
                              </th>
                            </tr>
                            <tr>
                              <th>Average</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {q.Average}
                              </th>
                            </tr>

                            <tr>
                              <th>Net</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {q.NetSale}
                              </th>
                            </tr>
                            <tr>
                              <th>VAT</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {Number(q.CGST).toFixed(2)}
                              </th>
                            </tr>
                            {/* <tr>
                              <th>SGST</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {Number(q.SGST).toFixed(2)}
                              </th>
                            </tr> */}
                            <tr>
                              <th>Gross</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {Number(q.GrossAmount).toFixed(2)}
                              </th>
                            </tr>

                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        ))
                      : null}
                    <tr>
                      <td>Printed on {new Date().toLocaleString()}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <a
                href="#/"
                style={{
                  position: "absolute",
                  right: "60%",
                  padding: "15px",
                  color: "red",
                }}
                onClick={this.pdfCheck}
              >
                <i className="fa fa-file-pdf-o"></i>
              </a>
              {/* </div>  */}
              <ReactToPrint
                // onAfterPrint={this.refreshPage}
                trigger={() => (
                  <a
                    style={{
                      position: "absolute",
                      right: "45%",
                      padding: "15px",
                    }}
                    //   type="button"
                    //   className="btn btn-primary"
                    href="#/"
                  >
                    Print
                  </a>
                )}
                content={() => this.componentRef1}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default BillReportsComponent;
