import React from "react";
import StoresComponent from "./StoresComponent";
import {
  STORE_LIST,
  // STORELIST,
  ADD_STORE,
  UPDATE_STORE,
  HOST,
  KEY,
} from "../../Network/ApiPaths";
// import getResponse from "../../Network/GetDataResponse";
// import postDataResponse from "../../Network/PostDataResponse";
import Uri from "../../Network/Uri";

import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
// let clientHost;

// let storeFresh;
// let storeDet;
// let storeIdValue;
// let storeName;
// let storeUserId;
// let longitude;
// let latitude;
// let storeAddress;
const storeError = (fieldName) => {
  if (fieldName === null || fieldName === "" || fieldName === undefined) {
    return true;
  } else {
    return false;
  }
};
class StoresContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      stores: [],
      sid: "",
      deleteStoreId: "",
      storeDetails: false,
      storeName: "",
      storeAddress: "",
      storeManagerName: "",
      storePhoneNumber: "",
      storePhoneNumber_One: "",
      storeEmail: "",
      storeRadius: "",
      storeTiming: "",
      storeLongitude: "",
      storeLatitude: "",
      service_status: "",
      status_reason: "",
      store_Gst: "",
      newStoreName: "",
      newStoreAddress: "",
      newStoreManagerName: "",
      newStorePhoneNumber: "",
      newStorePhoneNumber_One: "",
      newStoreEmail: "",
      newStoreRadius: "",
      newStoreTiming: "",
      newStoreLongitude: "",
      newStoreLatitude: "",
      newService_status: "",
      newStatus_reason: "",
      newStore_Gst: "",
      storeDefaultDetails: "",
      newStoreNameerror: false,
      newStoreAddresserror: false,
      newStoreManagerNameerror: false,
      newStorePhoneNumbererror: false,
      newStorePhoneNumber_Oneerror: false,
      newStoreEmailerror: false,
      newStoreRadiuserror: false,
      newStoreTimingerror: false,
      newStoreLongitudeerror: false,
      newStoreLatitudeerror: false,
      newService_statuserror: false,
      newStatus_reasonerror: false,
      newStore_Gsterror: false,
      addedStore: false,
      storeDeleted: false,
      updatedStore: false,
      reset: false,
    };
  }
  setSID = (sId) => {
    this.setState({ sid: sId, storeDetails: true });
  };
  goToStores = () => {
    this.setState({ storeDetails: false });
  };
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}error`]: false,
    });
  };

  onStoreDelete = (e) => {
    this.setState({
      storeDeleted: true,
    });
    var apiPath = new Uri(HOST + `Store/delete?id=${this.state.deleteStoreId}`);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {},
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  onStoreTriggerDelete = (e) => {
    this.setState({
      deleteStoreId: e,
    });
  };

  onUpdateStore = (e) => {
    let updatedStoreName;
    let updatedStoreAddress;
    let updatedStoreManagerName;
    let updatedStorePhoneNumber;
    let updatedStorePhoneNumber_One;
    let updatedStoreEmail;
    let updatedStoreRadius;
    let updatedStoreTiming;
    let updatedStoreLongitude;
    let updatedStoreLatitude;
    let updatedService_status;
    let updatedService_reason;
    let updatedStore_Gst;

    if (this.state.storeName === "") {
      updatedStoreName = this.state.storeDefaultDetails.name;
    } else {
      updatedStoreName = this.state.storeName;
    }
    if (this.state.storeAddress === "") {
      updatedStoreAddress = this.state.storeDefaultDetails.address;
    } else {
      updatedStoreAddress = this.state.storeAddress;
    }
    if (this.state.storeManagerName === "") {
      updatedStoreManagerName = this.state.storeDefaultDetails.contact_name;
    } else {
      updatedStoreManagerName = this.state.storeManagerName;
    }
    if (this.state.storePhoneNumber === "") {
      updatedStorePhoneNumber = this.state.storeDefaultDetails.phone_one;
    } else {
      updatedStorePhoneNumber = this.state.storePhoneNumber;
    }
    if (this.state.storePhoneNumber_One === "") {
      updatedStorePhoneNumber_One = this.state.storeDefaultDetails.phone_two;
    } else {
      updatedStorePhoneNumber_One = this.state.storePhoneNumber_One;
    }
    if (this.state.storeEmail === "") {
      updatedStoreEmail = this.state.storeDefaultDetails.mail;
    } else {
      updatedStoreEmail = this.state.storeEmail;
    }
    if (this.state.storeRadius === "") {
      updatedStoreRadius = this.state.storeDefaultDetails.radius;
    } else {
      updatedStoreRadius = this.state.storeRadius;
    }
    if (this.state.storeTiming === "") {
      updatedStoreTiming = this.state.storeDefaultDetails.service_hours;
    } else {
      updatedStoreTiming = this.state.storeTiming;
    }
    if (this.state.storeLongitude === "") {
      updatedStoreLongitude = this.state.storeDefaultDetails.longitude;
    } else {
      updatedStoreLongitude = this.state.storeLongitude;
    }
    if (this.storeLatitude === "") {
      updatedStoreLatitude = this.state.storeDefaultDetails.latitute;
    } else {
      updatedStoreLatitude = this.state.storeLatitude;
    }
    if (this.state.service_status === "") {
      updatedService_status = this.state.storeDefaultDetails.service_status;
    } else {
      updatedService_status = this.state.service_status;
    }
    if (this.state.status_reason === "") {
      updatedService_reason = this.state.storeDefaultDetails.status_reason;
    } else {
      updatedService_reason = this.state.status_reason;
    }
    if (this.state.store_Gst === "") {
      updatedStore_Gst = this.state.storeDefaultDetails.gstn;
    } else {
      updatedStore_Gst = this.state.store_Gst;
    }
    if (
      (this.state.storeName === "" ||
        this.state.storeName === this.state.storeDefaultDetails.name) &&
      (this.state.storeAddress === "" ||
        this.state.storeAddress === this.state.storeDefaultDetails.address) &&
      (this.state.storeManagerName === "" ||
        this.state.storeManagerName ===
          this.state.storeDefaultDetails.contact_name) &&
      (this.state.storePhoneNumber === "" ||
        this.state.storePhoneNumber ===
          this.state.storeDefaultDetails.phone_one) &&
      (this.state.storePhoneNumber_One === "" ||
        this.state.storePhoneNumber_One ===
          this.state.storeDefaultDetails.phone_two) &&
      (this.state.storeEmail === "" ||
        this.state.storeEmail === this.state.storeDefaultDetails.mail) &&
      (this.state.storeRadius === "" ||
        this.state.storeRadius === this.state.storeDefaultDetails.radius) &&
      (this.state.storeTiming === "" ||
        this.state.storeTiming ===
          this.state.storeDefaultDetails.service_hours) &&
      (this.state.storeLongitude === "" ||
        this.state.storeLongitude ===
          this.state.storeDefaultDetails.longitude) &&
      (this.state.storeLatitude === "" ||
        this.state.storeLatitude === this.state.storeDefaultDetails.Latitute) &&
      (this.state.service_status === "" ||
        this.state.service_status ===
          this.state.storeDefaultDetails.service_status) &&
      (this.state.status_reason === "" ||
        this.state.status_reason ===
          this.state.storeDefaultDetails.status_reason) &&
      (this.state.store_Gst === "" ||
        this.state.store_Gst === this.state.storeDefaultDetails.gstn)
    ) {
      alert("no Changes are made");
    } else {
      // POST API WITH DATA
      const requestBody = {
        id: this.state.storeDefaultDetails.id,
        name: updatedStoreName,
        address: updatedStoreAddress,
        contact_name: updatedStoreManagerName,
        phone_one: updatedStorePhoneNumber,
        phone_two: updatedStorePhoneNumber_One,
        mail: updatedStoreEmail,
        radius: updatedStoreRadius,
        service_hours: updatedStoreTiming,
        longitude: updatedStoreLongitude,
        latitute: updatedStoreLatitude,
        service_status: updatedService_status,
        status_reason: updatedService_reason,
        gstn: updatedStore_Gst,
      };
      postClientDataResponse(
        HOST + UPDATE_STORE,
        KEY,
        requestBody,
        (onSuccessResponse) => {},
        (onError) => {
          //  alert(onError);
        }
      );
    }
  };

  onStoreTriggerEdit = (e) => {
    this.setState({
      storeDefaultDetails: e,
    });
  };
  // getStoreInfo = (storeHOST, storeKEY, store) => {
  //   var apiPath = new Uri(storeHOST + STORE_LIST).addQueryParam(
  //     "storeId",
  //     store
  //   );
  //   getResponseClient(
  //     apiPath,
  //     storeKEY,
  //     (onSuccessResponse) => {
  //       this.setState({
  //         stores: [...this.state.stores, onSuccessResponse.result],
  //       });
  //     },
  //     (onError) => {
  //       console.log(onError.message);
  //     }
  //   );
  // };
  onGetStoreList = () => {
    var apiPath = new Uri(HOST + STORE_LIST);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          reset: false,
        });
        onSuccessResponse.result.map((p) => {
          return this.setState({ stores: [...this.state.stores, p] });
          // this.getStoreInfo(p.host, p.x_api_key, p.store_id);
          // getStoreInfo = (storeHOST, storeKEY, store) => {
          // var apiPath = new Uri(
          //   p.host + `Store/listAllStoreWithStoreId`
          // ).addQueryParam("storeId", p.store_id);
          // return getResponseClient(
          //   apiPath,
          //   p.x_api_key,
          //   (onSuccessResponse2) => {},
          //   (onError) => {console.log(onError.message);});
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  onAddStore = (e) => {
    this.setState({
      addedStore: false,
    });
    e.preventDefault();
    let k = 0;

    if (storeError(this.state.newStoreName)) {
      k++;
      this.setState({
        newStoreNameerror: true,
      });
      // alert("Enter Name");
    }

    if (storeError(this.state.newStoreAddress)) {
      k++;
      this.setState({
        newStoreAddresserror: true,
      });
    }
    if (storeError(this.state.newStoreManagerName)) {
      k++;
      this.setState({
        newStoreManagerNameerror: true,
      });
    }
    if (storeError(this.state.newStorePhoneNumber)) {
      k++;
      this.setState({
        newStorePhoneNumbererror: true,
      });
    }
    if (storeError(this.state.newStorePhoneNumber_One)) {
      k++;
      this.setState({
        newStorePhoneNumber_Oneerror: true,
      });
    }

    if (storeError(this.state.newStoreEmail)) {
      k++;
      this.setState({
        newStoreEmailerror: true,
      });
    }
    if (storeError(this.state.newStoreRadius)) {
      k++;
      this.setState({
        newStoreRadiuserror: true,
      });
    }
    if (storeError(this.state.newStoreTiming)) {
      k++;
      this.setState({
        newStoreTimingerror: true,
      });
    }
    if (storeError(this.state.newStoreLongitude)) {
      k++;
      this.setState({
        newStoreLongitudeerror: true,
      });
    }
    if (storeError(this.state.newStoreLatitude)) {
      k++;
      this.setState({
        newStoreLatitudeerror: true,
      });
    }
    if (storeError(this.state.newService_status)) {
      k++;
      this.setState({
        newService_statuserror: true,
      });
    }
    if (storeError(this.state.newStatus_reason)) {
      k++;
      this.setState({
        newStatus_reasonerror: true,
      });
    }
    if (storeError(this.state.newStore_Gst)) {
      k++;
      this.setState({
        newStore_Gsterror: true,
      });
    }
    if (k === 0) {
      this.setState({
        addedStore: true,
      });
      const requestBody = {
        name: this.state.newStoreName,
        address: this.state.newStoreAddress,
        contact_name: this.state.newStoreManagerName,
        phone_one: this.state.newStorePhoneNumber,
        phone_two: this.state.newStorePhoneNumber_One,
        mail: this.state.newStoreEmail,
        radius: this.state.newStoreRadius,
        service_hours: this.state.newStoreTiming,
        longitude: this.state.newStoreLongitude,
        latitute: this.state.newStoreLatitude,
        service_status: this.state.newService_status,
        status_reason: this.state.newStatus_reason,
        gstn: this.state.newStore_Gst,
      };

      postClientDataResponse(
        HOST + ADD_STORE,
        KEY,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            reset: true,
          });

          this.onGetMallStoreList();
        },
        (onError) => {
          //    alert(onError);
        }
      );
    }
  };
  onGetMallStoreList = () => {
    var apiPath = new Uri(
      HOST + `Store/listStore`
      // STORE_LIST
    )
    //.addQueryParam("id", storeid);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          reset: false,
        });
        this.setState({
          stores: [
            ...this.state.stores,
            onSuccessResponse.result[onSuccessResponse.result.length - 1],
          ],
          // stores: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  // activate = (store) => {
  //   const requestBody = {
  //     status: "ACTIVE",
  //     client_id: BRANDID,
  //     mall_id: BRANDID,
  //     store_id: store.id,
  //     store_name: store.name,
  //   };
  //   postClientDataResponse(
  //     AD_HOST + "MallStore/updateMallStore",
  //     AD_KEY,
  //     requestBody,
  //     (onSuccessResponse) => {
  //       // this.onGetStoreList();
  //     },
  //     (onError) => {
  //       //    alert(onError);
  //     }
  //   );
  // };
  render() {
    return (
      <div>
        {/* {this.state.storeDetails ? (
          <div>
            <StoreDetails goToStores={this.goToStores} 
            sID={this.state.sid}
                       />
          </div>
        ) : ( */}
        <StoresComponent
          data={this.state.stores}
          sid={this.setSID}
          addStore={this.onAddStore}
          fieldChange={this.onFieldChange}
          newStoreNameerror={this.state.newStoreNameerror}
          newStoreAddresserror={this.state.newStoreAddresserror}
          newStoreManagerNameerror={this.state.newStoreManagerNameerror}
          newStorePhoneNumbererror={this.state.newStorePhoneNumbererror}
          newStorePhoneNumber_Oneerror={this.state.newStorePhoneNumber_Oneerror}
          newStoreEmailerror={this.state.newStoreEmailerror}
          newStoreRadiuserror={this.state.newStoreRadiuserror}
          newStoreTimingerror={this.state.newStoreTimingerror}
          newStoreLongitudeerror={this.state.newStoreLongitudeerror}
          newStoreLatitudeerror={this.state.newStoreLatitudeerror}
          newService_statuserror={this.state.newService_statuserror}
          newStatus_reasonerror={this.state.newStatus_reasonerror}
          newStore_Gsterror={this.state.newStore_Gsterror}
          addedStore={this.state.addedStore}
          updatedStore={this.state.updatedStore}
          storeDefaultDetails={this.state.storeDefaultDetails}
          onStoreTriggerDelete={this.onStoreTriggerDelete}
          onStoreDelete={this.onStoreDelete}
          storeDeleted={this.state.storeDeleted}
          onStoreTriggerEdit={this.onStoreTriggerEdit}
          onUpdateStore={this.onUpdateStore}
          activate={this.activate}
          reset={this.state.reset}
        />
      </div>
    );
  }
  async componentDidMount() {
    this.onGetStoreList();
  }
}
export default StoresContainer;
