import React from "react";
// import Poster from "../Poster";
import Cards from "../Cards";
const RootComponent = (props) => (
  <div>
    <header className="page-header">
      <div className="container-fluid">
        <h2 className="no-margin-bottom">Dashboard</h2>
      </div>
    </header>

    <Cards />
  </div>
);

export default RootComponent;
