import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

import Uri from "../../Network/Uri";
import getResponseClient from "../../Network/GetClientDataResponse";
let clientHost;
let storeFresh;
// let storeIdValue;
// let storeDet;
class Chart2 extends Component {
  constructor(props) {
    super(props);
    // const userCheck = window.localStorage.getItem("access");
    // storeDet = JSON.parse(userCheck);
    // if (storeDet !== null) {
    //   storeIdValue = storeDet.storeId;
    // } else {
    //   storeIdValue = "";
    // }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      // storeIdValue = storeFresh.storeId;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      // chartData: props.chartData,
      monthlyData: [],
    };
  }
  static defaultProps = {
    displayTitle: true,
    displayLegend: true,
    legendPosition: "right",
    location: "City",
  };
  render() {
    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient1 = ctx.createLinearGradient(150, 0, 150, 300);
      gradient1.addColorStop(0, "rgba(255, 119, 119, 0.94)");
      gradient1.addColorStop(1, "rgba(255, 119, 119, 0.94)");
      const gradient2 = ctx.createLinearGradient(146.0, 0.0, 154.0, 300.0);
      gradient2.addColorStop(0, "rgba(104, 179, 112, 0.85)");
      gradient2.addColorStop(1, "rgba(104, 179, 112, 0.85)");
      return {
        labels: this.state.monthlyData
          ? this.state.monthlyData.map((p) => p.month)
          : ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
          {
            label: "Orders",
            backgroundColor: [
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
            ],
            hoverBackgroundColor: [
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
            ],
            borderColor: [
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
            ],
            borderWidth: 1,
            data:
              ///////////////////////////////////////////////////////
              this.state.monthlyData
                ? this.state.monthlyData.map((p) => p.totalorders)
                : // [65, 59, 80, 81, 56, 55, 40]
                  [0, 0, 0, 0, 0, 0, 0],
          },
          // {
          //   label: "Sales / 100",
          //   backgroundColor: [
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //   ],
          //   hoverBackgroundColor: [
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //   ],
          //   borderColor: [
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //     gradient2,
          //   ],
          //   borderWidth: 1,
          //   data:
          //     //////////////////////////////////////////////
          //     this.state.monthlyData
          //       ? this.state.monthlyData.map(
          //           (p) => Math.round(p.totalAmount) / 100
          //         )
          //       : // [35, 40, 60, 47, 88, 27, 30]
          //         [0, 0, 0, 0, 0, 0, 0],
          // },
        ],
      };
    };
    return (
      <div className="chart">
        <Bar
          data={data}
          options={{
            legend: { labels: { fontColor: "#777", fontSize: 12 } },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
            },
          }}
        />
        {/* <Line
          data={data}
          options={{
            legend: { labels: { fontColor: "#777", fontSize: 12 } },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
            },
          }}
        /> */}
        {/* <Pie
          data={this.state.chartData}
          options={{
            title: {
              display: this.props.displayTitle,
              text: "Largest Cities In " + this.props.location,
              fontSize: 25,
            },
            legend: {
              display: this.props.displayLegend,
              position: this.props.legendPosition,
            },
          }}
        /> */}
      </div>
    );
  }

  async componentDidMount() {
    // this.getStoreDetails(storeIdValue, storeUserId);
    // this.getDate();
    var apiPath = new Uri(clientHost + `Reports/getMonthlyReport`)
      .addQueryParam("startDate", "2020-01-01")
      .addQueryParam("endDate", "2021-12-12")
      .addQueryParam("store_id", storeFresh.storeId);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          monthlyData: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  }
}

export default Chart2;
