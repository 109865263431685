import React from "react";
import RootComponent from "./RootComponent";
class RootContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      state: [],
    };
  }
  render() {
    return <RootComponent />;
  }
}
export default RootContainer;
// import { Chart } from "react-charts";
// export default function RootContainer() {
//   const data = React.useMemo(
//     () => [
//       {
//         type: "line",
//         options: {
//           legend: { labels: { fontColor: "#777", fontSize: 12 } },
//           scales: {
//             xAxes: [
//               {
//                 display: true,
//                 gridLines: {
//                   color: "#eee",
//                 },
//               },
//             ],
//             yAxes: [
//               {
//                 display: true,
//                 gridLines: {
//                   color: "#eee",
//                 },
//               },
//             ],
//           },
//         },
//         data: {
//           labels: [
//             "January",
//             "February",
//             "March",
//             "April",
//             "May",
//             "June",
//             "July",
//           ],
//           datasets: [
//             {
//               label: "Data Set One",
//               fill: true,
//               lineTension: 0.3,
//               // backgroundColor: gradient1,
//               // borderColor: gradient1,
//               borderCapStyle: "butt",
//               borderDash: [],
//               borderDashOffset: 0.0,
//               borderJoinStyle: "miter",
//               borderWidth: 1,
//               // pointBorderColor: gradient1,
//               pointBackgroundColor: "#fff",
//               pointBorderWidth: 1,
//               pointHoverRadius: 5,
//               // pointHoverBackgroundColor: gradient1,
//               pointHoverBorderColor: "rgba(220,220,220,1)",
//               pointHoverBorderWidth: 2,
//               pointRadius: 1,
//               pointHitRadius: 10,
//               data: [30, 50, 40, 91, 42, 35, 40],
//               spanGaps: false,
//             },
//             {
//               label: "Data Set Two",
//               fill: true,
//               lineTension: 0.3,
//               // backgroundColor: gradient2,
//               // borderColor: gradient2,
//               borderCapStyle: "butt",
//               borderDash: [],
//               borderDashOffset: 0.0,
//               borderJoinStyle: "miter",
//               borderWidth: 1,
//               // pointBorderColor: gradient2,
//               pointBackgroundColor: "#fff",
//               pointBorderWidth: 1,
//               pointHoverRadius: 5,
//               // pointHoverBackgroundColor: gradient2,
//               pointHoverBorderColor: "rgba(220,220,220,1)",
//               pointHoverBorderWidth: 2,
//               pointRadius: 1,
//               pointHitRadius: 10,
//               data: [50, 40, 50, 40, 45, 40, 30],
//               spanGaps: false,
//             },
//           ],
//         },
//       },
//     ]
//     // []
//   );

// const axes = React.useMemo(
//   () => [
//     { primary: true, type: "linear", position: "bottom" },
//     { type: "linear", position: "left" },
//   ],
//   []
// );

//   const lineChart = (
//     // A react-chart hyper-responsively and continuously fills the available
//     // space of its parent element automatically
//     <div
//       style={{
//         width: "400px",
//         height: "300px",
//       }}
//     >
//       <Chart data={data} />
//     </div>
//   );
//   return lineChart;
// }
