import React from "react";
import CardsComponent from "./CardsComponent";
import {
  // SALES_COD,
  // SALES_WALLET,
  // SALES_PAYTM,
  // SALES_MACHINE,
  // ORDERS_COUNT,
  // TOTAL_SALES_COUNT,
  // TOTAL_USER_COUNT,
  MISC,
  USERS,
} from "../../Network/ApiPaths";
// import getResponse from "../../Network/GetDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
import Uri from "../../Network/Uri";
let clientHost;
let storeFresh;
// let storeCode;
// let storeName;
// const userCheck = window.localStorage.getItem("access");
// let storeDet = JSON.parse(userCheck);
let storeDet;
let storeIdValue;
// if (storeDet !== null) {
//   storeIdValue = storeDet.storeId;
// } else {
//   storeIdValue = "";
// }
class CardsContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;

    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeIdValue = storeDet.storeId;
    } else {
      storeIdValue = "";
    }

    this.state = {
      cod: [],
      wallet: [],
      paytm: [],
      machine: [],
      completed: [],
      accepted: [],
      pending: [],
      inprogress: [],
      //
      sales: [],
      closedOrders: [],
      totalOrders: [],
      users: [],
      misc: [],
      todaystotalOrders: "",
      todaystotalSales: "",
      todaysnetSales: "",
      todaysaverage: "",
    };
  }
  
  render() {
    const { misc } = this.state;
    // console.log(misc);
    let TotalOrders = misc
      .filter((p) => p.range === "Orders")
      .map((q) => Math.round(Number(q.OrdersCount)));
    let gross = misc
      .filter((p) => p.range === "Orders")
      .map((q) => Math.round(Number(q.final_amount)));
    let cgst = misc
      .filter((p) => p.range === "Orders")
      .map((q) => Math.round(Number(q.vat)));
    let sgst = misc
      .filter((p) => p.range === "Orders")
      .map((q) => Math.round(Number(q.SGST)));
    let TotalSales = misc
      .filter((p) => p.range === "Orders")
      .map((q) => Math.round(Number(q.total)));
    let OrdersCompleted = misc
      .filter((p) => p.range === "Completed")
      .map((q) => Math.round(Number(q.OrdersCount)));
    let CODSales = misc
      .filter((p) => p.range === "COD Total")
      .map((q) => Math.round(Number(q.total)));
    let PayTmSales = misc
      .filter((p) => p.range === "PayTM Total")
      .map((q) => Math.round(Number(q.total)));
    let CardSales = misc
      .filter((p) => p.range === "CARD Total")
      .map((q) => Math.round(Number(q.total)));
    let CancelledSales = misc
      .filter((p) => p.range === "Cancelled")
      .map((q) => Math.round(Number(q.total)));
    // let SwipeSales = misc
    //   .filter((p) => p.range === "Swipe Total")
    //   .map((q) => Math.round(Number(q.total)));
    // let codP = Math.round((CODSales / TotalSales) * 100);
    // let paytmP = Math.round((PayTmSales / TotalSales) * 100);
    // let cardP = Math.round((CardSales / TotalSales) * 100);
    // let swipeP = Math.round((SwipeSales / TotalSales) * 100);
    // let RemainingP = 100 - (codP + paytmP + cardP + swipeP);
    return (
      <CardsComponent
        cod={CODSales}
        wallet={this.state.wallet}
        paytm={PayTmSales}
        machine={CardSales}
        completed={OrdersCompleted}
        accepted={this.state.accepted}
        pending={this.state.pending}
        inprogress={this.state.inprogress}
        //
        gross={gross}
        cgst={cgst}
        sgst={sgst}
        sales={TotalSales}
        closedOrders={OrdersCompleted}
        totalOrders={TotalOrders}
        users={this.state.users}
        cancelledSales={CancelledSales}
        todaystotalOrders={this.state.todaystotalOrders}
        todaystotalSales={this.state.todaystotalSales}
        todaysnetSales={this.state.todaysnetSales}
        todaysaverage={this.state.todaysaverage}
      />
    );
  }
  async componentDidMount() {
    var apiPath4 = new Uri(clientHost + MISC).addQueryParam(
      "store_id",
      storeIdValue
    );
    getResponseClient(
      apiPath4,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          misc: onSuccessResponse.response,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
    var apiPath3 = new Uri(clientHost + USERS)
      .addQueryParam("store_id", storeIdValue)
      .addQueryParam("sDate", "2020-04-01 00:00:00")
      .addQueryParam("eDate", "2021-03-31 23:59:59");
    getResponseClient(
      apiPath3,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          users: onSuccessResponse.response,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
    var apiPath = new Uri(
      clientHost + `Report/getTodaySalesCount`
    ).addQueryParam("storeId", storeIdValue);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        onSuccessResponse.response.map((p) =>
          this.setState({
            todaystotalOrders: p.OrdersCount,
            todaystotalSales: p.TotalAmount,
            todaysnetSales: p.NetSale,
            todaysaverage: p.Average,
          })
        );
      },
      (onError) => {
        // alert(onError);
      }
    );
  }
}
export default CardsContainer;
