import React from "react";

const PincodesComponent = (props) => (
  <section className="feeds no-padding-top">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="checklist card">
            <div className="card-close">
              <div className="dropdown">
                <button
                  type="button"
                  id="closeCard5"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  aria-labelledby="closeCard5"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <a href="#profile" className="dropdown-item remove">
                    <i className="fa fa-times"></i>Close
                  </a>
                  <a href="#profile" className="dropdown-item edit">
                    <i className="fa fa-gear"></i>Edit
                  </a>
                </div>
              </div>
            </div>
            <div className="card-header d-flex align-items-center">
              <h2 className="h3">PIN Codes</h2>
            </div>
            <div className="card-body no-padding">
              {props.data.map((p, index) => (
                <div key={index} className="item d-flex">
                  <label htmlFor="input-1">{p.name} </label>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span> {p.status}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PincodesComponent;
