import React from "react";

import ExecutiveReportsComponent from "./ExecutiveReportsComponent";
import getResponseClient from "../../Network/GetClientDataResponse";
// import dateFormat from "dateformat";
// import postClientDataResponse from "../../Network/PostClientDataResponse";

import Uri from "../../Network/Uri";
let storeFresh;
// let storeUserId;
// let storeIdValue;
let clientHost;
// let storeDet;
// let storeAddress;
class ExecutiveReportsContainer extends React.Component {
  constructor(props) {
    super();
    // const userCheck = window.localStorage.getItem("access");
    // storeDet = JSON.parse(userCheck);
    // if (storeDet !== null) {
    //   storeAddress = storeDet.address;
    // } else {
    //   storeAddress = "";
    // }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      ordersList: [],
      salesReport: [],
      catReport: [],
      executiveReport: [],
      paymentReport: [],
      date: "",
      salesDate: "",
      reportsDate: "",
      reportsDate2: "",
      ExecutiveReportsDate: "",
      ExecutiveReportsDate2: "",
      reportsCatDate: "",
      reportsCatDate2: "",
      salesDate2: "",

      // OrderDate: dateFormat("paddingShortDate")
    };
  }
  getExecutiveSalesReport = () => {
    let todaysExecutiveReportsDate = new Date().toISOString().slice(0, 10);
    this.setState({ date: todaysExecutiveReportsDate });
    let ExecutiveReportsDate = this.state.ExecutiveReportsDate;
    if (ExecutiveReportsDate !== "") {
      todaysExecutiveReportsDate = ExecutiveReportsDate;
    }
    var apiPath = new Uri(
      clientHost +
        `Reports/getExecutiveSalesReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysExecutiveReportsDate)
      .addQueryParam("endDate", todaysExecutiveReportsDate);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.getItemwiseList();
        this.setState({
          executiveReport: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  getExecutiveReportsByDate = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let ExecutiveReportsDate = new Date(e.target.value)
      .toISOString()
      .slice(0, 10);
    this.setState({ reportsDate: ExecutiveReportsDate });
    var apiPath = new Uri(
      clientHost +
        `Reports/getExecutiveSalesReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", ExecutiveReportsDate)
      .addQueryParam(
        "endDate",
        this.state.ExecutiveReportsDate2 === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.ExecutiveReportsDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ executiveReport: onSuccessResponse.response });
        this.paymentReport(
          ExecutiveReportsDate,
          this.state.ExecutiveReportsDate2 === ""
            ? new Date().toISOString().slice(0, 10)
            : this.state.ExecutiveReportsDate2
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getExecutiveReportsByDate2 = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let ExecutiveReportsDate2 = new Date(e.target.value)
      .toISOString()
      .slice(0, 10);
    this.setState({ reportsDate2: ExecutiveReportsDate2 });
    var apiPath = new Uri(
      clientHost +
        `Reports/getExecutiveSalesReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam(
        "startDate",
        this.state.ExecutiveReportsDate === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.ExecutiveReportsDate
      )
      .addQueryParam("endDate", ExecutiveReportsDate2);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ executiveReport: onSuccessResponse.response });
        this.paymentReport(
          this.state.ExecutiveReportsDate === ""
            ? new Date().toISOString().slice(0, 10)
            : this.state.ExecutiveReportsDate,
          ExecutiveReportsDate2
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  paymentReport = (sD, eD) => {
    var apiPath2 = new Uri(
      clientHost + `Reports/getPaymentModeReport?store_id=${storeFresh.storeId}`
      // &startDate=2021-03-14&endDate=2021-03-14`
    )
      .addQueryParam(
        "startDate",
        sD
        // this.state.ExecutiveReportsDate === ""
        //   ? new Date().toISOString().slice(0, 10)
        //   : this.state.ExecutiveReportsDate
      )
      .addQueryParam(
        "endDate",
        eD
        // this.state.ExecutiveReportsDate2 === ""
        //   ? new Date().toISOString().slice(0, 10)
        //   : this.state.ExecutiveReportsDate2
      );
    getResponseClient(
      apiPath2,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ paymentReport: onSuccessResponse.response });
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  render() {
    return (
      <div>
        <ExecutiveReportsComponent
          // getItemwiseList={this.getItemwiseList}
          getSalesByDate={this.getSalesByDate}
          getSalesReportList={this.getSalesReportList}
          getCategoryReportList={this.getCategoryReportList}
          getCatReportsByDate={this.getCatReportsByDate}
          getCatReportsByDate2={this.getCatReportsByDate2}
          getExecutiveSalesReport={this.getExecutiveSalesReport}
          getExecutiveReportsByDate={this.getExecutiveReportsByDate}
          getExecutiveReportsByDate2={this.getExecutiveReportsByDate2}
          ordersList={this.state.ordersList}
          salesReport={this.state.salesReport}
          executiveReport={this.state.executiveReport}
          paymentReport={this.state.paymentReport}
          catReport={this.state.catReport}
          getReportsByDate={this.getReportsByDate}
          getReportsByDate2={this.getReportsByDate2}
          reportsDate={this.state.reportsDate}
          ExecutiveReportsDate={this.state.ExecutiveReportsDate}
          ExecutiveReportsDate2={this.state.ExecutiveReportsDate2}
          reportsDate2={this.state.reportsDate2}
          reportsCatDate={this.state.reportsCatDate}
          reportsCatDate2={this.state.reportsCatDate2}
          salesDate={this.state.salesDate}
          salesDate2={this.state.salesDate2}
          date={this.state.date}
          //salesReport={this.state.salesReport}
          getSalesByDate2={this.getSalesByDate2}
        />
      </div>
    );
  }
  // http://garudadev.treosoftit.com/backend/api/Reports/getPaymentModeReport?
  // store_id=34&startDate=2021-03-14&endDate=2021-03-14
  async componentDidMount() {
    // this.getItemwiseList();
    // this.getSalesReportList();
    // this.getCategoryReportList();
    this.getExecutiveSalesReport();
    this.paymentReport(
      new Date().toISOString().slice(0, 10),
      new Date().toISOString().slice(0, 10)
    );
  }
}
export default ExecutiveReportsContainer;
