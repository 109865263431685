import React from "react";

const StoreDetailsComponent = (props) => (
  <div>
    {/* <div className="content-inner"> */}
    {/* <header className="page-header">
      <div className="container-fluid">
        <h2 className="no-margin-bottom">Store Details Report</h2>
      </div>
    </header> */}
    <div className="breadcrumb-holder container-fluid">
      <ul className="breadcrumb">
        <li onClick={props.goToStores} className="breadcrumb-item">
          Go Back To Stores
        </li>
        {/* <li className="breadcrumb-item active">Store id:{props.SID}</li> */}
      </ul>
    </div>
    <section className="tables">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <button
                    type="button"
                    id="closeCard2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    aria-labelledby="closeCard2"
                    className="dropdown-menu dropdown-menu-right has-shadow"
                  >
                    <a href="#profile" className="dropdown-item remove">
                      <i className="fa fa-times"></i>Close
                    </a>
                    <a href="#profile" className="dropdown-item edit">
                      <i className="fa fa-gear"></i>Edit
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Store ID: {props.SID}</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th>Store Name</th>
                        <th>Order Status</th>
                        <th>Order Items Count</th>
                        <th>Payment Mode</th>
                        <th>Item Total</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.StoreDetails.map((p, index) => (
                        <tr key={index}>
                          <th scope="row">{index}</th>
                          <td>{p.storeName}</td>
                          <td>{p.order_status}</td>
                          <td>{p.orderItemsCount}</td>
                          <td>{p.payment_mode}</td>
                          <td>{p.itemTotal}</td>
                          <td>{p.dated}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default StoreDetailsComponent;
