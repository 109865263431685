import React from "react";
import TodaysReportComponent from "./TodaysReportComponent";
import getResponseClient from "../../Network/GetClientDataResponse";
import Uri from "../../Network/Uri";
// import { scaleService } from "chart.js";
let storeIdValue;
// let storeDet;
let clientHost;
let storeFresh;
class TodaysReportsContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
    } else {
      storeIdValue = "";
    }
    this.state = {
      HourlyReport: [],
      date: "",
      HourlySalesDate: "",
    };
  }
  getHourlySalesByDate = (e) =>{
    // let date = new Date().toISOString().slice(0, 10)
    let HourlySalesDate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ HourlySalesDate });
    var apiPath4 = new Uri(clientHost + `Reports/getHourlySales`)
      .addQueryParam("date", HourlySalesDate)
      .addQueryParam("store_id", storeIdValue);
    getResponseClient(
      apiPath4,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          HourlyReport:
            onSuccessResponse.response !== null
              ? onSuccessResponse.response
              : [],
          // date: HourlySalesDate
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  render() {
    return (
      <div>
        <TodaysReportComponent
          misc={this.state.misc}
          date={this.state.date}
          HourlyReport={this.state.HourlyReport}
          HourlySalesDate={this.state.HourlySalesDate}
          getHourlySalesByDate={this.getHourlySalesByDate}
        />
      </div>
    );
  }
  async componentDidMount() {
    let date = new Date().toISOString().slice(0, 10);
    var apiPath4 = new Uri(clientHost + `Reports/getHourlySales`)
      .addQueryParam("date", date)
      .addQueryParam("store_id", storeIdValue);

    getResponseClient(
      apiPath4,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          HourlyReport:
            onSuccessResponse.response !== null
              ? onSuccessResponse.response
              : [],
          HourlySalesDate: date,
          date: date,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}
export default TodaysReportsContainer;
