import React from "react";
import DeliveryComponent from "./DeliveryComponent";
import { DELIVERYBOYS_LIST } from "../../Network/ApiPaths";
import getResponse from "../../Network/GetDataResponse";
import Uri from "../../Network/Uri";
class DeliveryContainer extends React.Component {
  constructor(props) {
    super();
    this.state = { deliveryBoys: [] };
  }
  render() {
    return (
      <div>
        <DeliveryComponent data={this.state.deliveryBoys} />
      </div>
    );
  }
  async componentDidMount() {
    var apiPath = new Uri(DELIVERYBOYS_LIST);
    getResponse(
      apiPath,
      (onSuccessResponse) => {
        this.setState({
          deliveryBoys: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}
export default DeliveryContainer;
