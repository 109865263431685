import React from "react";
import PromosComponent from "./PromosComponent";

class PromosContainer extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <PromosComponent />
      </div>
    );
  }
}
export default PromosContainer;
