import React from "react"; // , { Component }
import CategoryReportsComponent from "./CategoryReportsComponent";
import getResponseClient from "../../Network/GetClientDataResponse";
// import dateFormat from "dateformat";
// import postClientDataResponse from "../../Network/PostClientDataResponse";

import Uri from "../../Network/Uri";
let storeFresh;
// let storeUserId;
// let storeIdValue;
let clientHost;
// let storeDet;
// let storeAddress;
class CategoryReportsContainer extends React.Component {
  constructor(props) {
    super();
    // const userCheck = window.localStorage.getItem("access");
    // storeDet = JSON.parse(userCheck);
    // if (storeDet !== null) {
    //   storeAddress = storeDet.address;
    // } else {
    //   storeAddress = "";
    // }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      ordersList: [],
      salesReport: [],
      catReport: [],
      executiveReport: [],
      date: "",
      salesDate: "",
      reportsDate: "",
      reportsDate2: "",
      ExecutiveReportsDate: "",
      ExecutiveReportsDate2: "",
      reportsCatDate: "",
      reportsCatDate2: "",
      salesDate2: "",

      // OrderDate: dateFormat("paddingShortDate")
    };
  }
  getItemwiseList = () => {
    let todaysOrdersDate = new Date().toISOString().slice(0, 10);
    let salesDate = this.state.salesDate;
    if (salesDate !== "") {
      todaysOrdersDate = salesDate;
    }
    var apiPath = new Uri(
      clientHost +
        `Reports/getCategoryProductwiseSale?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysOrdersDate)
      .addQueryParam("endDate", todaysOrdersDate);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        console.log(onSuccessResponse.response);
        this.setState({
          ordersList: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  getSalesReportList = () => {
    // let todaysReportsDate = new Date().toISOString().slice(0, 10);
    // let reportsDate = this.state.reportsDate;
    // this.setState({ date:todaysReportsDate, reportsDate:todaysReportsDate});
    // if (reportsDate !== "") {
    let todaysReportsDate = new Date().toISOString().slice(0, 10);
    this.setState({ date: todaysReportsDate });
    let reportsDate = this.state.reportsDate;
    if (reportsDate !== "") {
      todaysReportsDate = reportsDate;
    }
    var apiPath = new Uri(
      clientHost + `Reports/getBillReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysReportsDate)
      .addQueryParam("endDate", todaysReportsDate);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.getItemwiseList();
        this.setState({
          salesReport: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  getExecutiveSalesReport = () => {
    // let todaysReportsDate = new Date().toISOString().slice(0, 10);
    // let reportsDate = this.state.reportsDate;
    // this.setState({ date:todaysReportsDate, reportsDate:todaysReportsDate});
    // if (reportsDate !== "") {
    let todaysExecutiveReportsDate = new Date().toISOString().slice(0, 10);
    this.setState({ date: todaysExecutiveReportsDate });
    let ExecutiveReportsDate = this.state.ExecutiveReportsDate;
    if (ExecutiveReportsDate !== "") {
      todaysExecutiveReportsDate = ExecutiveReportsDate;
    }
    var apiPath = new Uri(
      clientHost +
        `Reports/getExecutiveSalesReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysExecutiveReportsDate)
      .addQueryParam("endDate", todaysExecutiveReportsDate);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.getItemwiseList();
        this.setState({
          executiveReport: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  getCategoryReportList = () => {
    // let todaysReportsDate = new Date().toISOString().slice(0, 10);
    // let reportsDate = this.state.reportsDate;
    // this.setState({ date:todaysReportsDate, reportsDate:todaysReportsDate});
    // if (reportsDate !== "") {
    let todaysCatReportsDate = new Date().toISOString().slice(0, 10);
    this.setState({ date: todaysCatReportsDate });
    let reportsCatDate = this.state.reportsCatDate;
    if (reportsCatDate !== "") {
      todaysCatReportsDate = reportsCatDate;
    }
    var apiPath = new Uri(
      clientHost + `Reports/getCategoryReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysCatReportsDate)
      .addQueryParam("endDate", todaysCatReportsDate);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.getItemwiseList();
        this.setState({
          catReport: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  getSalesByDate = (e) => {
    // let date = new Date(e.target.value).toISOString().slice(0, 10);
    // console.log("0",e.target.value,"1",date,"2",new Date(e.target.value))
    let salesDate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ salesDate });

    var apiPath = new Uri(
      clientHost +
        `Reports/getCategoryProductwiseSale?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", salesDate)
      .addQueryParam(
        "endDate",
        this.state.salesDate2 === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.salesDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            ordersList: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getItemwiseList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getSalesByDate2 = (e) => {
    let salesDate2 = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ salesDate2 });

    var apiPath = new Uri(
      clientHost +
        `Reports/getCategoryProductwiseSale?store_id=${storeFresh.storeId}`
    )
      .addQueryParam(
        "startDate",
        this.state.salesDate === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.salesDate
      )
      .addQueryParam(
        "endDate",

        salesDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            ordersList: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getItemwiseList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getReportsByDate = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let reportsDate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ reportsDate });
    var apiPath = new Uri(
      clientHost + `Reports/getBillReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", reportsDate)
      .addQueryParam(
        "endDate",
        this.state.reportsDate2 === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.reportsDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            salesReport: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getSalesReportList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getReportsByDate2 = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let reportsDate2 = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ reportsDate2 });
    var apiPath = new Uri(
      clientHost + `Reports/getBillReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam(
        "startDate",
        this.state.reportsDate === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.reportsDate
      )
      .addQueryParam("endDate", reportsDate2);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            salesReport: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getSalesReportList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getExecutiveReportsByDate = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let ExecutiveReportsDate = new Date(e.target.value)
      .toISOString()
      .slice(0, 10);
    this.setState({ ExecutiveReportsDate });
    var apiPath = new Uri(
      clientHost +
        `Reports/getExecutiveSalesReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", ExecutiveReportsDate)
      .addQueryParam(
        "endDate",
        this.state.ExecutiveReportsDate2 === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.ExecutiveReportsDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            executiveReport: onSuccessResponse.response,
            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getSalesReportList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getExecutiveReportsByDate2 = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let ExecutiveReportsDate2 = new Date(e.target.value)
      .toISOString()
      .slice(0, 10);
    this.setState({ ExecutiveReportsDate2 });
    var apiPath = new Uri(
      clientHost +
        `Reports/getExecutiveSalesReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam(
        "startDate",
        this.state.ExecutiveReportsDate === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.ExecutiveReportsDate
      )
      .addQueryParam("endDate", ExecutiveReportsDate2);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            executiveReport: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getSalesReportList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getCatReportsByDate = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let reportsCatDate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ reportsCatDate });
    var apiPath = new Uri(
      clientHost + `Reports/getCategoryReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", reportsCatDate)
      .addQueryParam(
        "endDate",
        this.state.reportsCatDate2 === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.reportsCatDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            catReport: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getSalesReportList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getCatReportsByDate2 = (e) => {
    //  let date = new Date(e.target.value).toISOString().slice(0, 10);
    let reportsCatDate2 = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ reportsCatDate2 });
    var apiPath = new Uri(
      clientHost + `Reports/getCategoryReport?store_id=${storeFresh.storeId}`
    )
      .addQueryParam(
        "startDate",
        this.state.reportsCatDate === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.reportsCatDate
      )
      .addQueryParam("endDate", reportsCatDate2);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            catReport: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getSalesReportList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };

  render() {
    return (
      <div>
        <CategoryReportsComponent
          // getItemwiseList={this.getItemwiseList}
          getSalesByDate={this.getSalesByDate}
          getSalesReportList={this.getSalesReportList}
          getCategoryReportList={this.getCategoryReportList}
          getCatReportsByDate={this.getCatReportsByDate}
          getCatReportsByDate2={this.getCatReportsByDate2}
          getExecutiveSalesReport={this.getExecutiveSalesReport}
          getExecutiveReportsByDate={this.getExecutiveReportsByDate}
          getExecutiveReportsByDate2={this.getExecutiveReportsByDate2}
          ordersList={this.state.ordersList}
          salesReport={this.state.salesReport}
          executiveReport={this.state.executiveReport}
          catReport={this.state.catReport}
          getReportsByDate={this.getReportsByDate}
          getReportsByDate2={this.getReportsByDate2}
          reportsDate={this.state.reportsDate}
          ExecutiveReportsDate={this.state.ExecutiveReportsDate}
          ExecutiveReportsDate2={this.state.ExecutiveReportsDate2}
          reportsDate2={this.state.reportsDate2}
          reportsCatDate={this.state.reportsCatDate}
          reportsCatDate2={this.state.reportsCatDate2}
          salesDate={this.state.salesDate}
          salesDate2={this.state.salesDate2}
          date={this.state.date}
          //salesReport={this.state.salesReport}
          getSalesByDate2={this.getSalesByDate2}
        />
      </div>
    );
  }
  async componentDidMount() {
    this.getItemwiseList();
    this.getSalesReportList();
    this.getCategoryReportList();
    this.getExecutiveSalesReport();
  }
}
export default CategoryReportsContainer;
