import React from "react";
import LoginComponent from "./LoginComponent";
import {
  HOST,
  KEY,
  CHECK_USER,
  STORE_LIST,
  // BRANDID,
  // AD_HOST,
  // AD_KEY,
} from "../../Network/ApiPaths";
// import postDataResponse from "../../Network/PostDataResponse";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
// import getResponse from "../../Network/GetDataResponse";
import Uri from "../../Network/Uri";
// import Uri2 from "../../Network2/Uri2";
// let storeFresh;
class LoginContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      userName: "",
      password: "",
      loginType: "employee",
      stores: [],
      // mallId: BRANDID,
      storeId: "",
    };
  }
  storeSelect = (s) => {
    // let d = s.target.getAttribute("data-value");
    // alert(d);
    let storeId = s.target.value;
    this.setState({ storeId });
    // console.log(typeof store);
    var selected = this.state.stores.find((d) => d.store.id === storeId);
    console.log(selected.store.host); // Update state with object this.setState({selected});
    const clientAccess = {
      host: selected.host,
      key: selected.x_api_key,
      storeId: selected.store.id,
      // storeCode: selected.store_code,
      storeName: selected.store.name,
    };
    localStorage.setItem("clientAccess", JSON.stringify(clientAccess));
    // this.getStoreDetails(clientAccess.host, clientAccess.key);
  };
  loginType = (e) => {
    this.setState({ loginType: e.target.value });
    if (e.target.value === "employee") {
      const clientAccess = {
        host: HOST,
        key: KEY,
      };
      localStorage.setItem("clientAccess", JSON.stringify(clientAccess));
    }
  };
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onLogin = () => {
    this.setState({
      userName: this.state.userName,
      password: this.state.password,
    });
    if (this.state.loginType === "store" && this.state.storeId === "") {
      alert("select store");
    } else {
      this.validate();
    }
  };
  validate = () => {
    if (
      this.state.userName === null ||
      this.state.userName === "" ||
      this.state.userName === undefined
    ) {
      // alert('User Name Error');
    } else if (
      this.state.password === null ||
      this.state.password === "" ||
      this.state.password === undefined
    ) {
      // alert('Password Error');
    } else {
      this.check();
    }
  };
  check = () => {
    const requestBody = {
      username: this.state.userName,
      password: this.state.password,
    };
    postClientDataResponse(
      JSON.parse(window.localStorage.getItem("clientAccess")).host + CHECK_USER,
      JSON.parse(window.localStorage.getItem("clientAccess")).key,
      requestBody,
      (onSuccessResponse) => {
        if (
          onSuccessResponse.data.status === true &&
          (JSON.parse(window.localStorage.getItem("clientAccess")).storeId ===
            onSuccessResponse.data.result.store_id ||
            onSuccessResponse.data.result.role === "SUPER_ADMIN")
        ) {
          var apiPath = new Uri(
            JSON.parse(window.localStorage.getItem("clientAccess")).host +
              STORE_LIST
          ).addQueryParam("storeid", onSuccessResponse.data.result.store_id);
          getResponseClient(
            apiPath,
            JSON.parse(window.localStorage.getItem("clientAccess")).key,
            (recieved) => {
              if (
                (this.state.loginType === "employee" &&
                  onSuccessResponse.data.result.role !== "SUPER_ADMIN") ||
                (this.state.loginType === "store" &&
                  onSuccessResponse.data.result.role === "SUPER_ADMIN")
                //  || onSuccessResponse.data.result.store_id !=="sg"
              ) {
                alert("Unauthorised user");
              } else {
                // let storeAddress = recieved.result.map((q) => q.address);
                // let gst = recieved.result.map((q) => q.gstn);
                let finalDetails = recieved.result
                  .filter(
                    (x) => x.id === onSuccessResponse.data.result.store_id
                  )
                  .map((y) => y);
                let storeAddress = finalDetails.map((z) => z.address);
                let gst = finalDetails.map((z) => z.gstn);
                console.log(storeAddress, gst, finalDetails);
                let access = {
                  name: onSuccessResponse.data.result.name,
                  mail: onSuccessResponse.data.result.mail,
                  phone: onSuccessResponse.data.result.phone,
                  role: onSuccessResponse.data.result.role,
                  userId: onSuccessResponse.data.result.id,
                  storeId: onSuccessResponse.data.result.store_id,
                  // imgOne: onSuccessResponse.data.result.kyc_one_image,
                  // imgTwo: onSuccessResponse.data.result.kyc_two_image,
                  accImage: onSuccessResponse.data.result.account_image,
                  // // // storeName: onSuccessResponse.data.result.storeName,
                  // // // longitude: onSuccessResponse.data.result.longitude,
                  // // // latitute: onSuccessResponse.data.result.latitute,
                  address: onSuccessResponse.data.result.storeAddress,
                  gst: onSuccessResponse.data.result.gst_number,
                  // city: onSuccessResponse.data.result.city,
                };
                localStorage.setItem("access", JSON.stringify(access));
                this.props.freshAccess(access);
              }
            },
            (onError) => {
              console.log(onError.message);
            }
          );
        } else {
          alert("Invalid User");
        }
      },
      (onError) => {
        // alert(onError);
      }
    );
  };

  render() {
    return (
      <LoginComponent
        onFieldChange={this.onFieldChange}
        onLogin={this.onLogin}
        loginType={this.loginType}
        typeOfLogin={this.state.loginType}
        stores={this.state.stores}
        storeSelect={this.storeSelect}
      />
    );
  }
  async componentDidMount() {
    const clientAccess = {
      host: HOST,
      key: KEY,
    };
    localStorage.setItem("clientAccess", JSON.stringify(clientAccess));
    var apiPath = new Uri(HOST + `Store/listAllStoresReports`);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          stores: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}
export default LoginContainer;
