import React from "react";
import EmployeesComponent from "./EmployeesComponent";
import {
  ADD_USER_DETAILS,
  EMPLOYEES_LIST,
  UPDATE_USER_DETAILS,
  // HOST,
} from "../../Network/ApiPaths";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
// import getResponse from "../../Network/GetDataResponse";
// import postDataResponse from "../../Network/PostDataResponse";
import Uri from "../../Network/Uri";

const employeeError = (employeeFeildName) => {
  if (
    employeeFeildName === null ||
    employeeFeildName === "" ||
    employeeFeildName === undefined
  ) {
    return true;
  } else {
    return false;
  }
};
let clientHost;
let storeFresh;
class EmployeesContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      reset: false,
      storeID: "",
      stores: [],
      employees: [],
      deletedEmployeeId: "",
      EmployeeDefaultDetails: "",
      employeeName: "",
      employeeEmail: "",
      employeePassword: "",
      employeePhoneNumber: "",
      employeeRole: "",
      employeeAddress: "",
      employeeCity: "",
      employeeZipCode: "",
      newEmployeeName: "",
      newEmployeeEmail: "",
      newEmployeePassword: "",
      newEmployeePhoneNumber: "",
      newEmployeeRole: "",
      newEmployeeAddress: "",
      newEmployeeCity: "",
      newEmployeeZipCode: "",
      newEmployeeNameerror: false,
      newEmployeeEmailerror: false,
      newEmployeePassworderror: false,
      newEmployeePhoneNumbererror: false,
      newEmployeeAddresserror: false,
      newEmployeeCityerror: false,
      newEmployeeRoleerror: false,
      newEmployeeZipCodeerror: false,
      addedEmployee: false,
      EmployeeDeleted: false,
      updatedEmployee: false,
      setsubmitting: false,
    };
  }
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}error`]: false,
      // newEmployeeRoleerror: false,
    });
  };
  onEmployeeDelete = () => {
    this.setState({
      EmployeeDeleted: true,
    });
    var apiPath = new Uri(
      clientHost + `User/delete?id=${this.state.deletedEmployeeId}`
    ).addQueryParam("store_id", storeFresh.storeId);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.onGetEmployeeList();
      },
      (onError) => {
        // console.log(onError.message);
      }
    );
  };

  onEmployeeTriggerDelete = (e) => {
    this.setState({
      deletedEmployeeId: e,
    });
  };
  onEmployeeTriggerEdit = (e) => {
    this.setState({
      EmployeeDefaultDetails: e,
    });
  };
  onUpdateEmployee = (e) => {
    this.setState({
      updatedEmployee: true,
    });
    let updatedEmployeeName;
    let updatedEmployeeEmail;
    let updatedEmployeePassword;
    let updatedEmployeeAddress;
    let updatedEmployeePhoneNumber;
    let updatedEmployeeRole;
    let updatedEmployeeCity;
    let updatedEmployeeZipCode;

    if (this.state.employeeName === "") {
      updatedEmployeeName = this.state.EmployeeDefaultDetails.name;
    } else {
      updatedEmployeeName = this.state.employeeName;
    }
    if (this.state.employeeEmail === "") {
      updatedEmployeeEmail = this.state.EmployeeDefaultDetails.mail;
    } else {
      updatedEmployeeEmail = this.state.employeeEmail;
    }
    if (this.state.employeePassword === "") {
      updatedEmployeePassword = this.state.EmployeeDefaultDetails.mail;
    } else {
      updatedEmployeePassword = this.state.employeePassword;
    }
    if (this.state.employeeAddress === "") {
      updatedEmployeeAddress = this.state.EmployeeDefaultDetails.address;
    } else {
      updatedEmployeeAddress = this.state.employeeAddress;
    }
    if (this.state.employeePhoneNumber === "") {
      updatedEmployeePhoneNumber = this.state.EmployeeDefaultDetails.phone;
    } else {
      updatedEmployeePhoneNumber = this.state.employeePhoneNumber;
    }
    if (this.state.employeeRole === "") {
      updatedEmployeeRole = this.state.EmployeeDefaultDetails.role;
    } else {
      updatedEmployeeRole = this.state.employeeRole;
    }
    if (this.state.employeeCity === "") {
      updatedEmployeeCity = this.state.EmployeeDefaultDetails.city;
    } else {
      updatedEmployeeCity = this.state.employeeCity;
    }
    if (this.state.employeeZipCode === "") {
      updatedEmployeeZipCode = this.state.EmployeeDefaultDetails.zipcode;
    } else {
      updatedEmployeeZipCode = this.state.employeeZipCode;
    }
    if (
      (this.state.employeeName === "" ||
        this.state.employeeName === this.state.EmployeeDefaultDetails.name) &&
      (this.state.employeeEmail === "" ||
        this.state.employeeEmail === this.state.EmployeeDefaultDetails.mail) &&
      (this.state.employeePassword === "" ||
        this.state.employeePassword ===
          this.state.EmployeeDefaultDetails.mail) &&
      (this.state.employeeAddress === "" ||
        this.state.employeeAddress ===
          this.state.EmployeeDefaultDetails.address) &&
      (this.state.employeePhoneNumber === "" ||
        this.state.employeePhoneNumber ===
          this.state.EmployeeDefaultDetails.phone) &&
      (this.state.employeeRole === "" ||
        this.state.employeeRole === this.state.EmployeeDefaultDetails.role) &&
      (this.state.employeeCity === "" ||
        this.state.employeeCity === this.state.EmployeeDefaultDetails.city) &&
      (this.state.employeeZipCode === "" ||
        this.state.employeeZipCode ===
          this.state.EmployeeDefaultDetails.zipcode)
    )
     {
      alert("no details are changed");
    }
     else {
      const requestBody = {
        id: this.state.EmployeeDefaultDetails.id,
        name: updatedEmployeeName,
        mail: updatedEmployeeEmail,
        phone: updatedEmployeePhoneNumber,
        address: updatedEmployeeAddress,
        city: updatedEmployeeCity,
        zipcode: updatedEmployeeZipCode,
        refferalcode: "refferelcode",
        firebase_token: "firebase_token",
        role: updatedEmployeeRole,
        store_id: storeFresh.storeId,
        updated_date: new Date(),
        password: updatedEmployeePassword,
        status: "ACTIVE",
      };
      postClientDataResponse(
        clientHost + UPDATE_USER_DETAILS,
        storeFresh.key,
        requestBody,
        storeFresh.key,
        (onSuccessResponse) => {
          this.setState({
            reset: true,
          });

          this.onGetEmployeeList();
        }
      );
    }
  };
  //  onSubmit = (values, onSubmitProps) =>{
  //   values.preventDefault();
  //    console.log('From data', values);
  //    console.log('submit props',onSubmitProps);
  //    onSubmitProps.setsubmitting(false)
  //    onSubmitProps.resetForm()
  //  }
  refresh = (e) => {
    e.preventDefault();
    this.setState({
      reset: true,
    });
  };
  refresh2 = (e) => {
    e.preventDefault();
    this.setState({
      reset: false,
    });
  };
  
  onAddEmployee = (e, values, onSubmitProps) => {
    this.setState({
      addedEmployee: true,
    });
    e.preventDefault();
    let k = 0;
    if (employeeError(this.state.newEmployeeName)) {
      k++;
      this.setState({
        newEmployeeNameerror: true,
      });
    }
    if (employeeError(this.state.newEmployeeEmail)) {
      k++;
      this.setState({
        newEmployeeEmailerror: true,
      });
    }
    if (employeeError(this.state.newEmployeePassword)) {
      k++;
      this.setState({
        newEmployeePassworderror: true,
      });
    }
    if (employeeError(this.state.newEmployeePhoneNumber)) {
      k++;
      this.setState({
        newEmployeePhoneNumbererror: true,
      });
    }
    if (employeeError(this.state.newEmployeeAddress)) {
      k++;
      this.setState({
        newEmployeeAddresserror: true,
      });
    }
    if (employeeError(this.state.newEmployeeRole)) {
      k++;
      this.setState({
        newEmployeeRoleerror: true,
      });
    }
    if (employeeError(this.state.newEmployeeCity)) {
      k++;
      this.setState({
        newEmployeeCityerror: true,
      });
    }
    if (employeeError(this.state.newEmployeeZipCode)) {
      k++;
      this.setState({
        newEmployeeZipCodeerror: true,
      });
    }
    if (k === 0) {
      const requestBody = {
        name: this.state.newEmployeeName,
        mail: this.state.newEmployeeEmail,
        address: this.state.newEmployeeAddress,
        phone: this.state.newEmployeePhoneNumber,
        city: this.state.newEmployeeCity,
        zipcode: this.state.newEmployeeZipCode,
        refferalcode: "refferelcode",
        firebase_token: "firebase_token",
        role: this.state.newEmployeeRole,
        store_id: storeFresh.storeId,
        created_date: new Date(),
        password: this.state.newEmployeePassword,
        status: "ACTIVE",
      };
      postClientDataResponse(
        clientHost + ADD_USER_DETAILS,
        storeFresh.key,
        requestBody,
        storeFresh.key,
        (onSuccessResponse) => {
          this.setState({
            reset: true,
            //  name:"",
            //  mail:"",
            //  address:"",
            //  phone:"",
            //  city:"",
            //  zipcode:"",
            //  refferalcode:"referalcode",
            //  firebase_token:"firebase_token",
            //  role:"",
            //  store_id:"",
            //  created_date:"",
            //  password:"",
            //  status:"ACTIVE"
          });
          this.onGetEmployeeList();
        },
        (onError) => {
          alert(onError);
        }
      );
    }
  };
  onGetEmployeeList = () => {
    var apiPath = new Uri(clientHost + EMPLOYEES_LIST).addQueryParam(
      "store_id",
      storeFresh.storeId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          employees: onSuccessResponse.result,
          reset: false,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };

  render() {
    return (
      <div>
        <EmployeesComponent
          stores={this.state.stores}
          s={this.setsid}
          data={this.state.employees}
          addEmployee={this.onAddEmployee}
          fieldChange={this.onFieldChange}
          addedEmployee={this.state.addedEmployee}
          newEmployeeNameerror={this.state.newEmployeeNameerror}
          newEmployeeEmailerror={this.state.newEmployeeEmailerror}
          newEmployeePassworderror={this.state.newEmployeePassworderror}
          newEmployeeAddresserror={this.state.newEmployeeAddresserror}
          newEmployeePhoneNumbererror={this.state.newEmployeePhoneNumbererror}
          newEmployeeRoleerror={this.state.newEmployeeRoleerror}
          newEmployeeCityerror={this.state.newEmployeeCityerror}
          newEmployeeZipCodeerror={this.state.newEmployeeZipCodeerror}
          onEmployeeTriggerDelete={this.onEmployeeTriggerDelete}
          EmployeeDeleted={this.state.EmployeeDeleted}
          onEmployeeDelete={this.onEmployeeDelete}
          EmployeeDefaultDetails={this.state.EmployeeDefaultDetails}
          onEmployeeTriggerEdit={this.onEmployeeTriggerEdit}
          onUpdateEmployee={this.onUpdateEmployee}
          refresh={this.refresh}
          refresh2={this.refresh2}
          // onSubmit={this.onSubmit}
          updatedEmployee={this.state.updatedEmployee}
          setsubmitting={this.state.setsubmitting}
          reset={this.state.reset}
        />
      </div>
    );
  }
  async componentDidMount() {
    this.onGetEmployeeList();
  }
}
export default EmployeesContainer;
