import React from "react";
import UserReportsComponent from "./UserReportsComponent";

class UserReportsContainer extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <UserReportsComponent />
      </div>
    );
  }
}
export default UserReportsContainer;
