import React from "react";

const PromosComponent = (props) => (
  <div>
    {props.date}
    <section className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          <div className="statistics col-lg-12 col-12">
            <div className="statistic d-flex align-items-center bg-white has-shadow">
              <div className="icon bg-red">
                <i className="fa fa-tasks"></i>
              </div>
              <div className="text">
                <strong>Festival Offer</strong>
                <br />
                <small>From Date to To date</small>
              </div>
            </div>
            <div className="statistic d-flex align-items-center bg-white has-shadow">
              <div className="icon bg-green">
                <i className="fa fa-calendar-o"></i>
              </div>
              <div className="text">
                <strong>Anniversiry Offer</strong>
                <br />
                <small>From Date to To date</small>
              </div>
            </div>
            <div className="statistic d-flex align-items-center bg-white has-shadow">
              <div className="icon bg-orange">
                <i className="fa fa-paper-plane-o"></i>
              </div>
              <div className="text">
                <strong>Opening Offer</strong>
                <br />
                <small>From Date to To date</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default PromosComponent;
