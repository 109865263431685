import React from "react";
// const formstatus = () => {
//   alert("clear now");
// };
// console.log(formstatus, "jhvjc");
// const EmployeesComponent = (this.props) => (
class EmployeesComponent extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    // this.myFormRef.reset();
    return (
      <section className="client no-padding-top">
        {/* {this.props.reset === true ? this.myFormRef.reset() : null} */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <br />
              <div className="card">
                <div className="card-body">
                  <div className="form-horizontal">
                    {/* <br /> */}
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        <h5>Add Employee</h5>
                      </label>
                      <div className="card-body text-center">
                        <div
                          id="myModal1"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                          className="modal fade text-left"
                        >
                          <div role="document" className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4
                                  id="exampleModalLabel"
                                  className="modal-title"
                                >
                                  Add Employee
                                </h4>
                                <button
                                  type="button"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  className="close"
                                  onClick={this.props.refresh}
                                  // ref={(el) =>
                                  //   this.props.reset === false
                                  //     ? (this.myFormRef1 = el)
                                  //     : this.myFormRef1.reset()
                                  // }
                                >
                                  <span aria-hidden="true">x</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <form
                                // ref={(el) =>
                                //   this.props.reset === false
                                //     ? (this.myFormRef1 = el)
                                //     : this.myFormRef1.reset()
                                // }

                                // this.props.reset === true? :null
                                >
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props.newEmployeeNameerror === true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      Name
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        style={
                                          this.props.newEmployeeNameerror ===
                                          true
                                            ? { color: "red" }
                                            : null
                                        }
                                        onChange={this.props.fieldChange}
                                        type="name"
                                        name="newEmployeeName"
                                        placeholder="Enter name"
                                        value={this.props.name}
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props.newEmployeeEmailerror ===
                                        true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      eMail
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        style={
                                          this.props.newEmployeeEmailerror ===
                                          true
                                            ? { color: "red" }
                                            : null
                                        }
                                        onChange={this.props.fieldChange}
                                        type="email"
                                        name="newEmployeeEmail"
                                        placeholder="Enter eMail"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props.newEmployeePassworderror ===
                                        true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      Password
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        style={
                                          this.props
                                            .newEmployeePassworderror === true
                                            ? { color: "red" }
                                            : null
                                        }
                                        onChange={this.props.fieldChange}
                                        type="password"
                                        name="newEmployeePassword"
                                        placeholder="Enter Password"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props
                                          .newEmployeePhoneNumbererror === true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      Phone
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        style={
                                          this.props
                                            .newEmployeePhoneNumbererror ===
                                          true
                                            ? { color: "red" }
                                            : null
                                        }
                                        onChange={this.props.fieldChange}
                                        type="number"
                                        name="newEmployeePhoneNumber"
                                        placeholder="Enter Phone Number"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props.newEmployeeAddresserror ===
                                        true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      Address
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        style={
                                          this.props.newEmployeeAddresserror ===
                                          true
                                            ? { color: "red" }
                                            : null
                                        }
                                        onChange={this.props.fieldChange}
                                        type="string"
                                        name="newEmployeeAddress"
                                        placeholder="Enter Address"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="line"></div>
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props.newEmployeeRoleerror === true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      Role
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        style={
                                          this.props.newEmployeeRoleerror ===
                                          true
                                            ? { color: "red" }
                                            : null
                                        }
                                        className="form-control sm-3 "
                                        name="newEmployeeRole"
                                        onChange={this.props.fieldChange}
                                        defaultValue="none"
                                      >
                                        <option value="none" disabled hidden>
                                          select role
                                        </option>
                                        <option value="SHOP">Cashier</option>
                                        <option value="ADMIN">Manager</option>
                                        <option value="NewAdmin">Admin</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props.newEmployeeCityerror === true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      City
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        style={
                                          this.props.newEmployeeCityerror ===
                                          true
                                            ? { color: "red" }
                                            : null
                                        }
                                        onChange={this.props.fieldChange}
                                        type="string"
                                        name="newEmployeeCity"
                                        placeholder="Enter city"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-sm-3"
                                      style={
                                        this.props.newEmployeeZipCodeerror ===
                                        true
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      Zip Code
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        style={
                                          this.props.newEmployeeZipCodeerror ===
                                          true
                                            ? { color: "red" }
                                            : null
                                        }
                                        onChange={this.props.fieldChange}
                                        type="number"
                                        name="newEmployeeZipCode"
                                        placeholder="Enter Zip Code"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <button
                                      type="submit"
                                      value="ADD"
                                      data-dismiss={
                                        this.props.addedEmployee === true
                                          ? "modal"
                                          : null
                                      }
                                      style={{
                                        position: "relative",
                                        float: "right",
                                      }}
                                      className="btn btn-primary"
                                      onClick={this.props.addEmployee}
                                    >
                                      ADD
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body text-center">
                        <div
                          id="myModal6"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                          className="modal fade text-left"
                        >
                          <div role="document" className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4
                                  id="exampleModalLabel"
                                  className="modal-title"
                                >
                                  Edit Employee
                                </h4>
                                <button
                                  type="button"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  className="close"
                                  // ref={(el) =>
                                  //   this.props.reset === false
                                  //     ? (this.myFormRef1 = el)
                                  //     : this.myFormRef1.reset()
                                  // }
                                >
                                  <span aria-hidden="true">x</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                {this.props.EmployeeDefaultDetails !== "" ? (
                                  <form
                                  // ref={(el) =>
                                  //   this.props.reset === false
                                  //     ? (this.myFormRef1 = el)
                                  //     : this.myFormRef1.reset()
                                  // }
                                  >
                                    <div className="form-group row">
                                      <label className="col-sm-3">Name</label>
                                      <div className="col-sm-9">
                                        <input
                                          onChange={this.props.fieldChange}
                                          type="name"
                                          name="employeeName"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .name
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-3">eMail</label>
                                      <div className="col-sm-9">
                                        <input
                                          onChange={this.props.fieldChange}
                                          name="employeeEmail"
                                          type="email"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .mail
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-3">
                                        Password
                                      </label>
                                      <div className="col-sm-9">
                                        <input
                                          onChange={this.props.fieldChange}
                                          name="employeePassword"
                                          type="password"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .password
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-3">Phone</label>
                                      <div className="col-sm-9">
                                        <input
                                          onChange={this.props.fieldChange}
                                          name="employeePhoneNumber"
                                          type="number"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .phone
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-3">
                                        Address
                                      </label>
                                      <div className="col-sm-9">
                                        <input
                                          onChange={this.props.fieldChange}
                                          name="employeeAddress"
                                          type="string"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .address
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="line"></div>
                                    <div className="form-group row">
                                      <label className="col-sm-3">Role</label>
                                      <div className="col-sm-9">
                                        <select
                                          className="form-control sm-3 "
                                          name="employeeRole"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .role
                                          }
                                          onChange={this.props.fieldChange}
                                          //defaultValue="SHOP"
                                        >
                                          <option value="SHOP">Casier</option>
                                          <option value="ADMIN">Manager</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-3">city</label>
                                      <div className="col-sm-9">
                                        <input
                                          onChange={this.props.fieldChange}
                                          name="employeeCity"
                                          type="string"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .city
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-3">
                                        Zip Code
                                      </label>
                                      <div className="col-sm-9">
                                        <input
                                          onChange={this.props.fieldChange}
                                          name="employeeZipCode"
                                          type="number"
                                          placeholder={
                                            this.props.EmployeeDefaultDetails
                                              .zipcode
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                  </form>
                                ) : null}
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss={
                                    this.props.updatedEmployee === true
                                      ? "modal"
                                      : null
                                  }
                                  onClick={this.props.onUpdateEmployee}
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <div
                          id="myModal5"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                          className="modal fade text-left"
                        >
                          <div role="document" className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4
                                  id="exampleModalLabel"
                                  className="modal-title"
                                >
                                  Do you Want to Delete This Employee
                                </h4>
                                <button
                                  type="button"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  className="close"
                                >
                                  <span aria-hidden="true">x</span>
                                </button>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss={
                                    this.props.EmployeeDeleted === true
                                      ? "modal"
                                      : null
                                  }
                                  onClick={this.props.onEmployeeDelete}
                                >
                                  Yes
                                </button>
                                <button
                                  type="button"
                                  data-dismiss="modal"
                                  className="btn btn-secondary"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-12"> */}
                      {/* <div className="card"> */}
                      {/* <div className="card-close"> */}
                      <div className="dropdown">
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#myModal1"
                          className="btn btn-primary"
                          onClick={this.props.refresh2}
                        >
                          Add Employee
                        </button>
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <div className="col-sm-10"> */}
                      {/* <select
                      name="account"
                      onClick={() => this.props.s()}
                      className="form-control mb-3"
                    >
                      {this.props.stores.map((p, index) => (
                        <option key={index}>{p.name}</option>
                      ))}
                    </select> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.data ? (
              this.props.data.map((p, index) => (
                <div key={index} className="col-lg-3">
                  <div className="client card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle"
                        >
                          <i className="fa fa-ellipsis-v"></i>
                        </button>
                        <div
                          aria-labelledby="closeCard2"
                          className="dropdown-menu dropdown`-menu-right has-shadow"
                        >
                          <a
                            href="/#"
                            className="dropdown-item remove"
                            data-toggle="modal"
                            data-target="#myModal5"
                            //  className="fa fa-trash-o fa-lg"
                            //  style={{
                            //    color: "red",
                            //    position: "absolute",
                            //    right: 0,
                            //  }}
                            onClick={() =>
                              this.props.onEmployeeTriggerDelete(p.id)
                            }
                          >
                            <i className="fa fa-times"></i>Delete
                          </a>
                          <a
                            href="/#"
                            className="dropdown-item edit"
                            data-toggle="modal"
                            data-target="#myModal6"
                            onClick={() => {
                              this.props.onEmployeeTriggerEdit(p);
                            }}
                          >
                            <i className="fa fa-gear"></i>Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body text-center">
                      <div className="client-avatar">
                        <img
                          src="img/avatar-2.jpg"
                          alt="..."
                          className="img-fluid rounded-circle"
                        />
                        <div className="status bg-blue"></div>
                      </div>
                      <div className="client-title">
                        <h3>{p.name}</h3>
                        <span>{p.role}</span>
                        <a href="/#" className="status bg-blue">
                          {p.phone}
                        </a>
                      </div>
                      <div className="client-info">
                        <div className="row">
                          <div className="col-6">
                            {/* <strong>{p.store_id}</strong>
                        <br /> */}
                            <small>{p.status}</small>
                          </div>
                        </div>
                      </div>
                      {/* style={{backgroundColor: "black"}} */}
                      <div className="client-social d-flex justify-content-between">
                        <a
                          href="/#"
                          target="_blank"
                          style={{ fontSize: "0.9vw",color:"black"}}
                        >
                          {/* <i></i> */}
                          {p.mail}
                        </a>
                      </div>
                      <div className="client-social d-flex justify-content-between">
                        <a href="/#" target="_blank" style={{color:"black"}}>
                          <i></i>
                          {p.city}
                        </a>
                      </div>
                      
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12">
                <div className="overdue card">
                  <div className="card-body">
                    <div className="number text-center">No Data</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default EmployeesComponent;
