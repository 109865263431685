import axios from "axios";

function getResponseClient(url, key, onSuccess, onError) {
  axios
    .create({
      responseType: "json",
      headers: {
        "x-api-key": key,
      },
    })
    .get(url)
    .then((res) => {
      if (onSuccess != null) {
        onSuccess(res.data);
      }
    })
    .catch((err) => {
      if (onError != null) {
        onError(err);
      }
    });
}
// "content-type": "application/x-www-form-urlencoded",
export default getResponseClient;
