import React from "react";

const StoresComponent = (props) => (
  <section className="projects no-padding-top">
    <div className="container-fluid">
      <br />
      {props.data.map((p, index) => (
        <div key={index} className="project">
          <div className="row bg-white has-shadow">
            <div className="left-col col-lg-6 d-flex align-items-center justify-content-between">
              <div
                onClick={() => props.sid(p.id)}
                className="project-title d-flex align-items-center"
              >
                <div className="image has-shadow">
                  <img
                    src="img/project-1.jpg"
                    alt="..."
                    className="img-fluid"
                  />
                </div>
                <div className="text">
                  <h3 className="h4">{p.name}</h3>
                  <small>{p.address}</small>
                </div>
              </div>
              <div className="project-date">
                <span className="hidden-sm-down">{p.contact_name}</span>
                <br />
                <span className="hidden-sm-down">{p.phone_one}</span>
                <br />
                <span className="hidden-sm-down">{p.phone_two}</span>
                <br />
                <span className="hidden-sm-down">{p.mail}</span>
                <br />
                <span className="hidden-sm-down">{p.radius}</span>
              </div>
            </div>
            <div className="right-col col-lg-6 d-flex align-items-center">
              <div className="time">
                <i className="fa fa-clock-o"></i>
                {p.service_hours}
                <br />
                <i></i>
                {p.service_status}
                <br />
                <i></i>
                {p.status_reason}
              </div>
              <div className="comments">
                <b>Total Orders: </b>
                <i></i>
                {p.report.total}
                <br />
                <b>Pending: </b>
                <i></i>
                {p.report.pending}
                <br />
                <b>Completed: </b>
                <i></i>
                {p.report.completed}
                <br />
                <b>In-Progress: </b>
                <i></i>
                {p.report.inprogress}
                <br />
                <b>Cancelled: </b>
                <i></i>
                {p.report.cancelled}
                <br />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default StoresComponent;
