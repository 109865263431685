import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
// import getResponseClient from "../../Network/GetClientDataResponse";
// import Uri from "../../Network/Uri";
// import { STORE_LIST } from "../../Network/ApiPaths";
class Chart2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: props.chartData,
    };
  }
  // static defaultProps = {
  //   displayTitle: true,
  //   displayLegend: true,
  //   legendPosition: "right",
  //   location: "City",
  // };
  // getStoreDetails = (h, sid, k) => {
  //   var apiPath3 = new Uri(h + STORE_LIST).addQueryParam("store_id", sid);
  //   getResponseClient(
  //     apiPath3,
  //     k,
  //     (onSuccessResponse) => {
  //       console.log(onSuccessResponse.result);
  //     },
  //     (onError) => {
  //       console.log(onError.message);
  //     }
  //   );
  // };
  render() {
    let ordersCount =
      this.props.chartData !== []
        ? this.props.chartData
            .filter((q) => q.range === "Today")
            .map((p) => p.OrdersCount)
        : // p.final_amount)
          null;
    // let finalAmount =
    //   this.props.chartData !== []
    //     ? this.props.chartData
    //         .filter((q) => q.range === "Today")
    //         .map((p) => Math.round(Number(p.final_amount)))
    //     : null;
    let storeName =
      this.props.chartData !== []
        ? this.props.chartData
            .filter((q) => q.range === "Today")
            .map((p) => p.StoreName)
        : null;
    // let stores =
    //   this.props.chartData !== []
    //     ? this.props.chartData
    //         .filter((q) => q.range === "Today")
    //         .map((p) => this.getStoreDetails(p.host, p.store_id, p.x_api_key))
    //     : null;
    // console.log(stores);
    const data1 = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient1 = ctx.createLinearGradient(150, 0, 150, 300);
      gradient1.addColorStop(0, "rgba(133, 180, 242, 0.91)");
      gradient1.addColorStop(1, "rgba(133, 180, 242, 0.91)");
      // const gradient2 = ctx.createLinearGradient(146.0, 0.0, 154.0, 300.0);
      // gradient2.addColorStop(0, "rgba(104, 179, 112, 0.85)");
      // gradient2.addColorStop(1, "rgba(76, 162, 205, 0.85)");
      return {
        labels: storeName
          ? storeName
          : ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
          {
            label: "Orders",
            backgroundColor: [
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
            ],
            hoverBackgroundColor: [
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
            ],
            borderColor: [
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
              gradient1,
            ],
            borderWidth: 1,
            data: ordersCount ? ordersCount : [65, 59, 80, 81, 56, 55, 40],
          },
        ],
      };
    };
    // const data2 = (canvas) => {
    //   const ctx = canvas.getContext("2d");
    //   // const gradient1 = ctx.createLinearGradient(150, 0, 150, 300);
    //   // gradient1.addColorStop(0, "rgba(133, 180, 242, 0.91)");
    //   // gradient1.addColorStop(1, "rgba(255, 119, 119, 0.94)");
    //   const gradient2 = ctx.createLinearGradient(146.0, 0.0, 154.0, 300.0);
    //   gradient2.addColorStop(0, "rgba(104, 179, 112, 0.85)");
    //   gradient2.addColorStop(1, "rgba(104, 179, 112, 0.85");
    //   return {
    //     labels: storeName
    //       ? storeName
    //       : ["January", "February", "March", "April", "May", "June", "July"],
    //     datasets: [
    //       {
    //         label: "Sales in Rupees",
    //         backgroundColor: [
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //         ],
    //         hoverBackgroundColor: [
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //         ],
    //         borderColor: [
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //           gradient2,
    //         ],
    //         borderWidth: 1,
    //         data: finalAmount ? finalAmount : [35, 40, 60, 47, 88, 27, 30],
    //       },
    //     ],
    //   };
    // };
    return (
      <div className="chart">
        <Bar
          data={data1}
          options={{
            legend: { labels: { fontColor: "#777", fontSize: 12 } },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
            },
          }}
        />
        {/* <br />
        <Bar
          data={data2}
          options={{
            legend: { labels: { fontColor: "#777", fontSize: 12 } },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
            },
          }}
        /> */}
        {/* <Line
          data={data}
          options={{
            legend: { labels: { fontColor: "#777", fontSize: 12 } },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
            },
          }}
        /> */}
        {/* <Pie
          data={this.state.chartData}
          options={{
            title: {
              display: this.props.displayTitle,
              text: "Largest Cities In " + this.props.location,
              fontSize: 25,
            },
            legend: {
              display: this.props.displayLegend,
              position: this.props.legendPosition,
            },
          }}
        /> */}
      </div>
    );
  }
  //   async componentDidMount() {
  //     let x =
  //       this.props.chartData !== []
  //         ? this.props.chartData
  //             // .filter((q) => q.range === "Today" && q.store_id === "1")
  //             .map((p) => p)
  //         : null;
  //     console.log(x);
  //   }
}

export default Chart2;
