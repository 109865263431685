import React from "react";
import BestReportsComponent from "./BestReportsComponent";

class BestReportsContainer extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <BestReportsComponent />
      </div>
    );
  }
}
export default BestReportsContainer;
